.studio-page-container {
  table {
    td,
    th {
      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        width: 10%;
        text-align: center;
      }
      &:nth-child(3) {
        width: 45%;
      }
      @media screen and (max-width: 900px) {
        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2) {
          width: 15%;
        }
        &:nth-child(3) {
          width: 30%;
        }
      }
    }
  }
}

// -- edit/add  studio scss -- //
.edit-studio-container,
.add-studio-container {
  .form-group {
    width: 40%;
  }

  .add-studio-info {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .toggle {
      text-transform: uppercase;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 50px;
    }
  }
}

.edit-studio-container {
  @media screen and (max-width: 600px) {
    .details-container {
      div {
        .form-group {
          width: 100%;
        }
      }
    }
  }
}
