.membership-container {
  .actions-row {
    @media screen and (max-width: 1100px) {
      flex-direction: column;
      gap: 24px;
    }

    @media screen and (max-width: 1100px) {
      .left-elements {
        flex-direction: column;
        gap: 24px;
        width: 100%;
        .search-and-button-row {
          width: 100%;
          .search-container {
            width: 100%;
            form {
              width: 100%;
            }
            .search-bar {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .landing-table.memberships {
    .table {
      table {
        td,
        th {
          &:nth-child(1),
          &:nth-child(8),
          &:nth-child(7) {
            text-align: center;
          }
          &:nth-child(2) {
            div {
              justify-content: left;
            }
          }

          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 10%;
          }
          &:nth-child(5) {
            width: 8%;
          }
        }
      }

      td {
        &:nth-child(9) {
          text-align: right;
        }
      }
    }
  }
  .membership-landing-content {
    .search-and-button-row {
      display: flex;
      justify-content: space-between;
      width: fit-content;
      // margin-top: 32px;
      div {
        align-self: flex-end;
        p {
          padding-bottom: 2px;
          padding-right: 100px;
          border-bottom: 1px solid var(--color-gray-400);
        }
      }
    }

    .landing-table {
      // margin-top: 36px;
      //styling for location memberships table
      &.location-memberships {
        .table {
          .table-container {
            table {
              th,
              td {
                &:nth-child(8) {
                  text-align: center;
                  padding-right: 18px;
                }
              }

              td {
                &:nth-child(9) {
                  padding-right: 0;
                  text-align: right;
                }
              }
            }
          }
        }
      }
      .membership-options {
        display: flex;
        flex-direction: column;
        // gap: 10px;
        background: var(--color-gray-300);
        min-width: 180px;
        width: fit-content;
        height: fit-content;
        border-radius: 5px;
        // padding: 6px 10px;
        margin-left: auto;
        padding: 8px 0px 8px 0px;
        border: 1px solid var(--color-gray-100);
        li {
          cursor: pointer;
          padding: 5px 9px;
          list-style-type: none;
          width: 100%;
          &:hover {
            background: var(--color-gray-400);
          }
          .button {
            display: flex;
            justify-content: right;
            button {
              font-size: 12px;
              padding: 0;
              border: none;
              text-align: right;
              transition: all 0.2s linear;

              &:hover {
                background: none;
                color: rgba(255, 255, 255, 0.7);
                transition: all 0.2s linear;
              }
            }
          }
        }
      }

      table {
        th {
          padding: 14px 18px;
        }

        tbody {
          td {
            padding: 0px 18px 0px 18px;

            &:nth-child(6) {
              text-align: center;
            }
            &:nth-child(8) {
              padding-right: 0;
            }
          }
        }
        td,
        th {
          &:nth-child(1) {
            width: 8%;
            text-align: center;
          }
          &:nth-child(2) {
            width: 30%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 15%;
          }

          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            width: 5%;
            text-align: center;
          }

          &:nth-child(8) {
            text-align: right;
          }
        }
      }
    }
  }

  //Modal Design
  .membership-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 976px;
    width: 100%;
    background: var(--color-black);
    border: 1px solid var(--color-gray-400);
    border-radius: 5px;
    padding: 30px 23px 40px 20px;

    .title-bar {
      padding-bottom: 0px;
    }

    .details-container {
      // margin-top: -20px;
      display: flex;
      justify-content: space-between;
      gap: 43px;
      width: 100%;

      &.second {
        .detail {
          align-self: flex-start;
        }
      }

      &.third {
        margin-bottom: 10px;
      }

      .detail {
        width: 100%;
        align-self: flex-end;
        h3 {
          color: var(--color-gray-400);
        }
        p {
          border-bottom: 1px solid var(--color-gray-400);
        }
        .toggle {
          align-self: flex-end;
          margin-bottom: 0;
        }
      }
    }

    .msg-container {
      margin-bottom: 20px;
      p {
        color: var(--color-gray-400);
        padding-bottom: 5px;
      }
    }
  }
}

.add-membership-btn {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.dropdown-btn {
  cursor: pointer;
}
