.location {
  .location-container {
    position: relative;

    .location-info {
      // margin-top: 1.8rem;
      .info {
        padding-bottom: 12px;
      }
      .row2 {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1100px) {
          flex-direction: column;
          gap: 24px;
        }

        @media screen and (max-width: 1100px) {
          .left-elements {
            flex-direction: column;
            gap: 24px;
            width: 100%;

            .search-container {
              width: 100%;
              form {
                width: 100%;
              }
              .search-bar {
                width: 100%;
              }
            }
          }
        }

        .btn-group {
          margin-bottom: 0;
          button {
            padding: 4px 10px;
            font-size: 8px;
            background: var(--color-gray-300);
          }
          .icon {
            width: 0.8rem;
          }
        }
        .min-width {
          min-width: 155px;
        }
        .flex {
          display: flex;
          gap: 2rem;
          justify-content: flex-end;
        }
      }
    }
    .member-filter {
      position: absolute;
      top: 230px;
      left: 175px;
    }
  }
  .table {
    table-layout: fixed;

    th,
    td {
      text-transform: uppercase;

      // Brand Column
      &:nth-child(1) {
        width: 5%;
        text-align: center;
      }
      // Location Column
      &:nth-child(2) {
        width: 25%;
      }
      // 24H Column
      &:nth-child(3) {
        width: 10%;
        padding-left: 0;
        text-align: center;

        div {
          justify-content: center;
        }
      }
      // Club Manager and Area Manager Columns
      &:nth-child(4),
      &:nth-child(5) {
        width: 22.5%;
      }
      &:nth-child(6) {
        width: 10%;
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .member {
    .member-container {
    }
  }
}

@media screen and (max-width: 1024px) {
  .member {
    .member-container {
      .member-info {
        margin-top: 1.8rem;
      }
    }
  }
}
