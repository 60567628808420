.club-image-container {
  display: flex;
  flex-direction: column;
  height: 306px;
  width: 100%;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;

  .club-image {
    display: flex;
    flex-direction: column;
    height: 100%;
    .club-img-input {
      margin-bottom: 10px;
      display: block;
    }

    .img-container {
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 100%;
      position: relative;

      img {
        align-self: stretch;
        max-width: 100%;
        display: block;
        margin-right: 0;
        width: 100%;
        // max-height: 100%;
        max-height: 189px;
        // object-fit: contain;
        object-fit: cover;
      }

      .club-image-title {
        text-transform: capitalize;
        padding-bottom: 10px;
        color: var(--color-gray-400);
      }

      .img-wrap {
        // height: 196px;
        width: 100%;
      }
    }
  }
}
