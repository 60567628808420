.report {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  .starButton {
    background: none;
    color: var(--color-gray-200);
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  .highlightStar {
    color: rgb(225, 135, 0);
  }
}
.links-container {
  gap: 24px;
  margin-top: 3.2rem;
  .link-title {
    font-weight: 700;
  }
}
