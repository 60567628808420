.page-error-container {
  display: flex;
  gap: 24px;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  .message-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    background: var(--color-gray-300);
    border-radius: 5px;
    border: 1px solid var(--color-gray-100);
    margin: auto;
    width: fit-content;
    .error-message,
    a,
    button {
      align-self: center;
    }
    button {
      width: fit-content;
    }
  }
}
