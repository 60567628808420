.medical-suspension-content {
  .suspension-details-box {
    div {
      .add-certificate {
        display: flex;
        align-items: center;
      }
    }
  }
  .certificate-records {
    .record-row {
      table {
        gap: 20px;
        text-align: left;
        margin-top: 0;
        td {
          padding-top: 6px;
          width: 250px;
          text-align: left;
        }
      }
    }
  }
}
