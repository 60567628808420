.class-message-form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;

  .title-bar {
    span {
      font-size: 10px;
    }
  }

  .message-content {
    display: flex;
    flex-direction: column;

    p {
      color: var(--color-gray-100);
      padding-bottom: 10px;
    }
  }
}
