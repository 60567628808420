.filter {
  display: flex;
  flex-direction: column;
  width: 110%;
  height: 100%;
  border: 1px solid var(--color-gray-200);
  border-radius: 3px;
  padding: 13px 0 23px 23px;
  background-color: var(--color-gray-300);

  div {
    flex: 1;
  }

  .first-row {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .sort {
    margin: 8px 0;
  }
  .btn-group {
    margin-top: 20px;
    width: 50%;
    display: flex;
    justify-content: flex-start;

    .btn {
      width: 70px;
      padding: 4px 0px;
      margin-right: 10px;
    }
  }
}
.status-modal {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

div[role="group"] {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  label {
    text-align: left;
  }
}
label[for="selectAll"] {
  text-align: left;
}

label input {
  margin-right: 2rem;
}
