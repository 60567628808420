.btn-group {
  margin: 22px 0 17px 0;
  display: flex;
  gap: 12px;
}
.line {
  display: block;
  border-bottom: 3px solid var(--color-white);
  width: 100%;
}
.table {
  overflow-x: auto;
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray-200);
    height: 0.25rem;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 0.5rem;
    display: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    text-transform: uppercase;
  }

  th {
    padding: 14px 18px 14px 18px;
    color: var(--color-gray-100);
    text-align: left;
    border-bottom: 1px solid var(--gray-3);
    background: var(--color-gray-300);
    font-size: 13px;

    .invisible {
      visibility: hidden;
    }

    .header-cell {
      display: flex;

      cursor: pointer;

      svg {
        height: 13px;
        width: 17px;
        transform: scale(1.4);
        align-self: center;
        margin-right: 4px;
      }
    }
  }
  .custom-actions-column {
    text-align: right;
    padding-right: 30px;
  }
  td {
    padding: 14px 18px 14px 18px;
    color: var(--color-white);
    text-align: left;
    border-bottom: 1px solid var(--gray-3);

    &:last-child {
      padding: 14px 18px;
    }
    .grayed-icon {
      color: var(--color-gray-100);
      svg {
        transition: none;
        &:hover {
          cursor: not-allowed;
          outline: none;
          background: none;
        }
      }
    }
    svg {
      border-radius: 1px;
      outline: 5px solid transparent;
      background: transparent;
      transition: all 0.2s linear;
      &:hover {
        border-radius: 1px;
        outline: 5px solid var(--color-persianblue);
        background: var(--color-persianblue);
        transition: all 0.2s linear;
      }

      &.delete-icon {
        &:hover {
          border-radius: 1px;
          outline: 5px solid var(--color-red);
          background: var(--color-red);
          transition: all 0.2s linear;
        }
      }
      &.no-effect-icon {
        outline: none;
        &:hover {
          background: transparent;
          outline: none;
          border-radius: 0px;
        }
      }
    }
  }

  tr:hover {
    background-color: var(--gray-5);
  }

  .center {
    // padding-right: 30px;
  }
  .align-right {
    text-align: right;
  }
  .table-bottom-opts {
    justify-content: space-between;
    margin-bottom: 5rem;
    margin-top: 1rem;
  }
  .pagination {
    display: flex;
    align-items: center;
    column-gap: 0.3rem;
    font-size: 10px;
    .pagination-container {
      height: 18px;
      margin: 18px;
      margin-left: 0;
      display: flex;
      row-gap: 4px;
      column-gap: 4px;
      align-items: center;
      justify-content: center;
      list-style: none;
      display: flex;

      .page-nav {
        gap: 8px;
      }

      .page {
        padding: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-gray-400);
        border-radius: 3px;
        font-size: 10px;
        width: 18px;
        height: 18px;
        &:disabled {
          color: var(--color-gray-100);
          background-color: transparent;
        }
      }
      .page-info-container {
        display: flex;
        margin: 0 5px;
        gap: 15px;
        color: var(--color-gray-100);

        .results-drop.flex-row {
          gap: 8px;
        }
      }
      .page-info-input {
        width: 40px;
        background-color: transparent;
        color: var(--color-gray-100);
        margin-left: 3px;
      }
      .page-info-dropdown {
        width: 60px;
        background-color: transparent;
        color: var(--color-gray-100);
      }
      .page-info-text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
      }
      .page-info {
        display: flex;
        gap: 2px;
        align-items: center;
        color: var(--color-gray-100);
      }
      .btn-load-more {
        color: var(--color-gray-100);
        padding: 2px 9px;
        &:disabled {
          background-color: transparent;
        }
      }

      .pagination-active {
        background-color: var(--color-gray-400);
      }

      .pagination-disabled-link {
        color: var(--color-gray-400);
        font-size: 10px;
      }

      @media screen and (max-width: 960px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .page-info-container {
          margin: 0px 0px;

          .results-drop {
            gap: 8px;
            .page-info-text {
              padding-left: 0;
            }
          }
        }
      }

      // @media screen and (max-width: 1100px) {
      //   margin-top: 0px !important;
      // }
    }
  }
  .table-btn-container {
    .twobtn-container {
      display: flex;
    }
  }
  .right-btn {
    .btn-disabled {
      &:disabled {
        background-color: var(--color-gray-100);
        color: var(--color-black);
        cursor: not-allowed;
      }
    }
  }
  #embedded-search {
    text-align: right;
    background: transparent;
    border-bottom: 0;
    padding: 20px 0px 0px 0px;

    .embedded-search-container {
      padding-left: 20px;
      font-size: 10px;
      position: relative;
      margin-left: 50%;
      input {
        max-width: 360px;
        height: unset;
        padding-bottom: 2px;
        padding-right: 30px;
        margin-left: 10px;
        width: 75%;
        background-color: transparent;
        color: var(--color-white-opaque);
        border: none;
        border-bottom: 1px solid var(--color-gray-400);
        &::placeholder {
          text-transform: uppercase;
          font-family: "gotham", Arial, Helvetica, sans-serif;
          color: var(--color-white-opaque);
        }

        &:focus {
          outline: none;
        }
      }
      button {
        background: transparent;
        color: var(--color-white-opaque);
        border: 0;
        font-size: 2rem;
        position: absolute;
        right: 4px;
        top: -7px;
      }
    }
  }
}
