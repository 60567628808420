.report-options-container {
  display: flex;
  padding: 20px 24px 17px 14px;
  height: max-content;
  width: 100%;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  margin-top: 30px;
  justify-content: space-between;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
    gap: 18px;
  }
}
.date-input-container {
  display: flex;
  gap: 1rem;

  .show-subtitle {
    align-self: center;
    margin-right: 20px;
  }
}
.date-input {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.flex-row.drop-down-container {
  gap: 18px;
  @media screen and (max-width: 1350px) {
    flex-direction: column;
    gap: 18px;
  }
}
.drop-down {
  display: flex;
  align-items: center;

  p {
    align-self: center;
    margin-right: 30px;
  }

  .select-element {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    gap: 1rem;
  }
}
