.staffed-table {
  .table {
    padding-bottom: 20px;

    tr {
      height: min-content;
      border-bottom: 1px solid var(--color-gray-400);

      &:first-of-type {
        border-top: 1px solid var(--color-gray-400);
      }

      table .time-display-table {
        .time-select {
          padding: 4px 8px;
          text-align: center;
          width: 100%;
          min-width: 109px;
          max-width: 109px;
          min-height: 22px;
          border-radius: 5px;
          white-space: nowrap;
          border: 1px solid var(--color-gray-400);
        }

        td:nth-child(3) {
          padding-left: 50px;
        }
      }

      th {
        padding-left: 10px;
        border: none;
        width: 15%;
        background: transparent;
        color: var(--color-white);
      }

      td {
        border: none;
        vertical-align: middle;
        padding-top: 8px;
        padding-bottom: 8px;

        .error {
          padding-left: 17px;
        }
      }
    }

    .main-row {
      &.header {
        th {
          width: 40%;
        }
      }
    }

    .add-slot {
      text-align: right;
      width: 100%;

      button {
        display: flex;
        margin-left: auto;

        img {
          margin-right: 10px;
        }

        background: transparent;
        color: var(--color-white);
        letter-spacing: 3px;
        border: none;
      }
    }

    .staffed-btn {
      text-align: right;
      padding-right: 16px;

      button {
        margin-bottom: 12px;
        padding: 5px 10px;
      }
    }

    .add-time-row.loc-hours {
      div {
        right: -200px;
      }
    }
  }
}

.hours-options {
  display: flex;
  flex-direction: row;
  gap: 0px 10px;
  justify-content: flex-end;

  button {
    &.blue {
      background: var(--color-gray-100);
    }
  }
}

.add-time-row {
  display: flex;
  gap: 0px 16px;
  padding: 8px 0px 8px 17px;
  width: 100%;
  position: relative;

  div {
    display: flex;
    gap: 10px;
    position: absolute;
    right: -105px;
  }

  input {
    max-width: 109px;
    min-width: 109px;
    min-height: 22px;
    max-height: 22px;
    background: transparent;
    padding: 0px 10px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid var(--color-gray-400);

    &::-webkit-datetime-edit-ampm-field,
    &::-webkit-datetime-edit-hour-field,
    &::-webkit-datetime-edit-minute-field,
    &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-fields-wrapper,
    &::-webkit-inner-spin-button {
      color: white;
      font-size: 12px;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    &:first-of-type {
      margin-right: 2px;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    &:last-child {
      margin-right: 16px;
    }
  }
}
