.add-class-template-container,
.edit-class-template-container {
  margin-bottom: 12px;
  .content-container {
    @media screen and (max-width: 1280px) {
      .input-row.add-class-template {
        gap: 18px;
        .detail {
          flex: 45%;
        }
      }
    }
  }
  .content-container {
    width: 100%;
    height: max-content;
    border: 1px solid var(--color-gray-400);
    border-radius: 5px;

    .first-toggle-container {
      display: flex;

      justify-content: space-between;

      .left-toggles,
      .right-toggles {
        display: flex;
        gap: 0px 32px;
      }

      .right-toggles {
        .toggle {
          padding-right: 20px;
        }
      }
    }

    .desc-row {
      h3 {
        padding-bottom: 10px;
      }

      textarea {
        max-height: 80px;
      }
    }
  }
  .input-row.add-class-template {
    display: flex;
    width: 100%;
    gap: 0px 24px;
    justify-content: space-between;

    .detail {
      &:nth-child(1) {
        width: 25%;
        // max-width: 119px;
      }
      &:nth-child(2) {
        width: 25%;
        // max-width: 454px;
      }
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 17%;
        // max-width: 95px;
      }

      .brand-name {
        display: flex;
        flex-direction: column;
        h5 {
          margin-bottom: 5px;
        }
        p {
          border-bottom: 1px solid var(--color-gray-100);
          padding-bottom: 2px;
          color: var(--color-white);
        }
      }
    }
  }
}

.edit-class-template-container {
  .top-bar {
    flex-direction: column;
    gap: 0px;
    .top-sub-bar {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .template-class-name {
        display: flex;
        gap: 0px 8px;
        align-self: flex-end;
      }
      h3 {
        text-transform: uppercase;
      }
    }
  }
}
