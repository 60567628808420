.apply-membership-container {
  .membership-landing-content {
    .search-and-button-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      div {
        align-self: flex-end;
        p {
          padding-bottom: 2px;
          padding-right: 100px;
          border-bottom: 1px solid var(--color-gray-400);
        }
      }
    }
    .landing-table {
      margin-top: 20px;
      margin-bottom: 33px;

      .table {
        margin-top: 10px;
        .right-btn {
          button {
            padding: 14px 24px;
            text-transform: uppercase;
            border-radius: 5px;
            background: var(--color-persianblue);
            color: var(--color-white);
            transition: all 0.2s linear;
            border: none;
            margin-top: 10px;

            &:hover {
              background: var(--color-persianblue-dark);
              transition: all 0.2s linear;
            }
          }
        }
        // bulk-price btn styling
        .btn-disabled {
          margin-top: 15px;
          &:disabled {
            background: var(--color-gray-100);
          }
        }

        // .pagination {
        //   margin-top: -60px;
        // }
        .active {
          color: var(--color-green);
        }

        .inactive {
          color: var(--color-red);
        }
        .table-container {
          table {
            .button-td {
              display: flex;
              gap: 8px;
              justify-content: flex-end;
              padding-right: 0;
            }

            td,
            th {
              padding: 10px;
              &:nth-child(1) {
                width: 5%;
                white-space: nowrap;
                text-align: center;
                padding-left: 15px;
              }

              &:nth-child(2) {
                width: 8%;
                text-align: center;
              }
              &:nth-child(3) {
                width: 15%;
              }
              &:nth-child(4) {
                width: 5%;
                text-align: center;
              }
              &:nth-child(5) {
                width: 10%;
                text-align: center;
              }
              &:nth-child(6) {
                width: 15%;
                text-align: right;
                padding-right: 50px;
              }
            }
            th {
              text-align: left;
            }

            td {
              &:nth-child(1) {
                width: 10%;
                text-align: left;
              }
              &:nth-child(3) {
                text-align: left;
                // padding-left: 20px;
              }
            }
          }
        }
      }
    }
  }

  //Modal Design

  .change-price {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    // height: min-content;
    max-width: 272px;
    max-height: 371px;
    background: var(--color-black);
    border: 1px solid var(--color-gray-400);
    border-radius: 5px;
    padding: 32px;

    .title {
      text-align: center;
    }

    .details-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;

      .detail {
        width: 50%;
        gap: 1.8rem 1.4rem;

        h3 {
          color: var(--color-gray-400);
        }
        p {
          border-bottom: 1px solid var(--color-gray-400);
        }
        .toggle {
          align-self: flex-end;
          margin-bottom: 0;
        }
        .checkbox {
          margin-bottom: 0;
        }
      }
    }

    .btn-container {
      align-self: center;
      display: flex;
      justify-content: center;
      gap: 0px 9px;
    }
  }
}

.head-checkbox-container {
  display: flex;
}

.save-btn-disabled {
  &:disabled {
    background-color: var(--color-gray-100);
    cursor: not-allowed;
  }
}

.bulk-modal-form {
  width: fit-content;
}
.modal-btn-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.bulk-change {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: fit-content;
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  .warning-text {
    display: flex;
    justify-content: center;
    color: red;
    font-size: 13px;
  }

  .title {
    text-align: center;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .detail {
      margin-right: auto;
      margin-left: auto;
      width: 60%;
      gap: 2.4rem 1.8rem;
      display: grid;
      grid-template: 1fr / 1fr 2fr;
      h3 {
        color: var(--color-gray-400);
      }
      p {
        border-bottom: 1px solid var(--color-gray-400);
      }
      .toggle {
        align-self: flex-end;
        margin-bottom: 0;
      }

      .checkbox {
        display: flex;
      }

      .form-group1 {
        border-bottom: 1px solid var(--color-gray-400);
        label {
          display: none;
        }
      }
      .checkbox {
        margin-bottom: 0;
      }
    }
  }
}
