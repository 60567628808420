.modal {
  .status-modal-otp {
    min-width: 600px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: fit-content;
    height: fit-content;
    background: var(--color-gray-300);
    border: 1px solid var(--color-gray-200);
    border-radius: 5px;
    padding: 32px;
    text-align: center;
    ß .text-textError {
      color: red;
    }
    > div {
      .resendLabel {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .message {
      color: var(--color-gray-100);
      font-size: 16px;
      line-height: 160%;
    }
  }
}
.container {
  position: absolute;
}
