.manual-container {
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-400);
  border-radius: 8px;

  .entry-info-container {
    display: flex;
    flex-direction: column;

    .entry-title {
      text-align: center;
    }

    .entry-info {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr !important;
      grid-template-rows: 1fr;
      gap: 0px 20px;
      grid-template-areas: "date   location" !important;

      .date,
      .location {
        flex: 1;

        label {
          font-size: 12px;
        }

        label,
        .location-heading {
          color: var(--color-gray-100);
          margin-bottom: 5px;
        }

        p {
          border-bottom: 1px solid var(--color-gray-100);
          padding-bottom: 2px;
        }
      }
    }

    .date {
      grid-area: date;
    }
    .location {
      grid-area: location;
    }
  }

  .guest-info-container {
    .guest-title {
      text-align: center;
      h3 {
        text-transform: uppercase;
      }
    }

    .guest-details {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 0px 24px;
      grid-template-areas: "fn  ln mobile ";
    }
    .fn {
      grid-area: fn;
    }
    .ln {
      grid-area: ln;
    }
    .mobile {
      grid-area: mobile;
    }
  }

  .reason-container {
    display: flex;
    gap: 20px;
    flex-direction: row;

    .reason-message {
      display: flex;
      flex-direction: column;
      flex: 50%;

      textarea {
        border-style: none;
        overflow: auto;
        outline: none;
        resize: none;

        &:active {
          border-style: none;
          border-color: red;
          overflow: auto;
        }
      }

      .reason-box {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 10px 8px;
        background-color: transparent;
        color: var(--color-white);
        border: 1px solid var(--color-gray-400);
      }
    }

    .entry-view {
      display: flex;
      flex-direction: column;
      flex: 50%;

      img {
        border: 1px solid var(--color-gray-400);
        border-radius: 5px;
        transition: all 0.2s linear;
      }
    }
  }

  .entry-btn-group {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin-top: 34px;
  }
}
