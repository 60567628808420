.datepicker-container {
  position: relative;
  input {
    outline: none;
    padding: 4px 8px 4px 9px;
    color: var(--color-gray-400);
    border: 1px solid var(--color-gray-400);
    background-color: transparent;
    border-radius: 5px;
    &::-webkit-calendar-picker-indicator {
      background-image: url(../../../assets/images/icons/calendar.png);
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}
