.modalTermsAndCondition {
  background: #000;
  max-height: 75vh;
  overflow-y: auto;
  padding: 24px;
  max-width: 650px;
  position: relative;
  .closeButton {
    /* margin-left: auto; */
    cursor: pointer;
    position: sticky;
    display: flex;
    justify-content: end;
    right: 24px;
    top: 0;
    z-index: 10;
  }
  .heading {
    font-family: "Bicyclette";
    font-style: normal;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
  }

  .heading-underline {
    position: relative;
    padding: 20px 40px;
    margin-bottom: 24px;
  }

  .heading-underline::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    background-color: rgb(37, 51, 213);
    width: 70px;
    transform: unset;
    margin-left: -35px;
    height: 4px;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }
  /* ---------- REACT QUILL ----------*/
  .quill * {
    color: #ffffff;
    font-size: 16px;
    /* font-family: "Bicyclette", sans-serif; */
    line-height: 1.5;
    font-weight: normal;
  }
  .quill .ql-container {
    padding: 24px;
    font-size: 16px;
  }
  .quill .ql-editor {
    padding: 0;
  }
  /* Text */
  .quill .ql-editor h1,
  .quill .ql-editor h1 * {
    font-size: 1.875rem;
    margin-top: 16px;
    margin-bottom: 1.5rem;
    font-weight: 700;
    color: rgb(37, 51, 213);
  }

  .quill .ql-editor h2,
  .quill .ql-editor h2 * {
    font-size: 1.75rem;
    line-height: 1.1;
    font-weight: 700;
  }
  /* Normal */
  .quill .ql-editor p {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 1.5;
  }
  /* Large */
  .quill .ql-editor p span.ql-size-large {
    display: block;
    margin-bottom: 1.5rem;
    font-size: 1.375rem;
    line-height: 1.5;
  }
  .quill .ql-editor p strong,
  .quill .ql-editor p b {
    font-weight: bolder;
  }
  .quill .ql-editor p a,
  .quill .ql-editor p a * {
    color: rgb(37, 51, 213);
  }

  .quill .ql-editor ol,
  .quill .ql-editor ul {
    padding-left: 0;
  }
  .quill .ql-editor iframe {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .quill .ql-tooltip {
    display: none;
  }

  @media screen and (max-width: 768px) {
    /* Text */
    .quill .ql-editor h2,
    .quill .ql-editor h2 * {
      font-size: 1.375rem;
    }
    /* Normal */
    .quill .ql-editor p {
      margin-bottom: 16px;
      font-size: 1.125rem;
      line-height: 1.5;
    }
    /* Large */
    .quill .ql-editor p span .ql-size-large {
      font-size: 1.125rem;
    }
  }
  /* ----------- END REACT QUILL --------- */
  .exercise-item {
    font-size: 15px;
  }
}
.modalTermsAndCondition.pre_exercise > ul {
  margin: 0 10px;
  > li::before {
    color: white;

    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
