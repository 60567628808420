.summary-container {
  display: flex;
  flex-direction: column;
  height: 306px;
  width: 100%;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  margin-bottom: 24px;

  .summary {
    display: flex;
    height: 100%;

    .summary-message {
      display: flex;
      flex-direction: column;
      flex: 70%;

      textarea {
        border-style: none;
        overflow: auto;
        outline: none;
        resize: none;
        max-height: 100%;

        &:active {
          border-style: none;
          overflow: auto;
        }
      }

      .summary-title {
        text-transform: capitalize;
        color: var(--color-gray-400);
      }

      .summary-box {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        padding: 10px 8px;
        background-color: transparent;
        color: var(--color-white);
        border: 1px solid var(--color-gray-400);
      }
    }
  }
}
