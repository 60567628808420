.pin-card {
  height: max-content;
  width: 100%;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  transition: all 0.2s linear;
  &:hover {
    background-color: var(--color-gray-300);
    transition: all 0.2s linear;
  }

  .details-container.pin-codes {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .pin-info {
      display: flex;
      gap: 0px 70px;
      width: 100%;
      @media screen and (max-width: 600px) {
        input {
          margin-bottom: 24px;
        }
      }
      div {
        flex: 1;
      }
    }
  }
}
