.switch-container {
  display: flex;
  border: 0.5px solid white;
  height: fit-content;
  border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  > div {
    width: 45px;
    border: 0.5px solid white;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > div:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  > div:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  >div.active{
    background-color: var(--color-persianblue);
  }
}
