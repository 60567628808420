.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 339px);
  height: 100vh;
  max-height: 1080px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-left: 339px;
  max-width: calc(1600px - 339px);
}
.modal {
  position: absolute;
  // left: 30%;
  // top: 25%;
  // width: 450px;
  // height: 257px;
  padding: 1.4rem 2.3rem;
  // max-width: 500px;
  display: flex;
  align-self: center;
  background: transparent;
  // background: var( --color-gray-300);
  // border: 1px solid var( --color-gray-200);
  border-radius: 10px;
}
.iframe-vivapay {
  margin-bottom: 20px;
  width: 400px;
  height: 550px;
  border: none;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
}
.future-payment {
  text-align: start;
  width: 500px;
  .future-title {
    padding: 20px 0;
  }
  label {
    padding: 10px 0;
    display: block;
  }
}
.bank-account {
  margin-bottom: 30px;
}
label input {
  margin-right: 6px !important;
}

.btn-disabled {
  background-color: var(--color-gray-200);
  border-color: var(--color-gray-200);
  &:hover {
    background: var(--color-gray-200);
    cursor: not-allowed;
  }
}
.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .amount-input {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
  }
  .reason-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    label {
      color: var(--color-gray);
      margin-bottom: 5px;
      transition: all 0.2s linear;
    }
  }
}
