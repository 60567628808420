.class-schedule-template-form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;

  .title-bar {
    span {
      font-size: 10px;
    }
  }

  .template-inputs {
    display: flex;
    gap: 12px 26px;

    .select-element,
    div {
      flex: 20%;
    }

    .form-group1 {
      flex: 15%;
    }
  }

  .form-group1 {
    width: 100%;
    input {
      padding-bottom: 3px;
      border-bottom: 1px solid var(--color-gray-400);

      &.blue {
        border-bottom: 1px solid var(--color-gray-100);
      }
    }
  }

  .schedule-template-toggles {
    display: flex;
    gap: 0px 5px;
    .toggle {
      width: 15%;
      min-width: 100px;
    }
  }
}
