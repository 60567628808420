:root {
  --color-black: #1f2937;
  --color-white: #ffffff;
  --color-white-opaque: rgba(255, 255, 255, 0.4);
  --color-persianblue: #3b82f6;
  --color-persianblue-dark: #60a5fa;
  --color-gray-100: #bfbfbf;
  --color-gray-200: #9ca3af;
  --color-gray-300: #283443;
  --color-gray-400: #5f5d5d;
  --color-gray-900: #111827;
  --color-darkgreen: #4d6c00;
  --color-green: #b5ff00;
  --color-red: #ff0000;
  --color-mediumred: #c30404;
  --color-yellow: #fbff4b;
  --transition: all 0.2s linear;
  --color-borders-and-lines: #282828;
  // --gray-1: rgba(255, 255, 255, 0.4);
  --gray-2: rgba(255, 255, 255, 0.3);
  --gray-3: rgba(255, 255, 255, 0.2);
  --gray-4: rgba(255, 255, 255, 0.1);
  --gray-5: rgba(255, 255, 255, 0.05);
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "gotham";
  src: local("MyFont"),
    url(./assets/fonts/gothamfont/Gotham-Book.otf) format("opentype");
}

.App {
  position: relative; //need this to position items outside of page containers e.g. chat window
}

html {
  font-size: 62.5%;
  max-width: 1600px !important;
}

body {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  background-color: var(--color-black);
  color: var(--color-white);
  // margin-bottom: 5rem;
}
.sidebar-bg-header-color-dev {
  background-color: #1d1d1d;
}
.sidebar-bg-header-color-prod {
  background-color: #1f2937;
}

.outlet-container {
  height: 100%;
}

//Standardized Styles

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.row1-infocards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;

  .col1 {
    grid-column: 1/2;
  }

  .col2 {
    grid-column: 2/3;
  }

  .col3 {
    grid-column: 3/4;
  }

  .col4 {
    grid-column: 4/5;
  }

  @media screen and (max-width: 1280px) {
    .col1 {
      grid-column: 1/3;
    }

    .col2 {
      grid-column: 3/5;
    }

    .col3 {
      grid-column: 1/3;
    }

    .col4 {
      grid-column: 3/5;
    }

    .col5 {
      grid-column: 1/5;

      &.next {
        grid-column: 1/5;
      }
    }

    .col6 {
      grid-column: 1/5;

      &.flex {
        flex-direction: column !important;
      }
    }
  }
}

// --- Padding & Margins --- //
.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.pd-12 {
  padding: 12px !important;
}

.pd-18 {
  padding: 18px !important;
}

.pd-24 {
  padding: 24px !important;
}

.pd-32 {
  padding: 32px !important;
}
.pt-2 {
  padding-top: 2px;
}

.pt-8 {
  padding-top: 8px;
}

.pt-12 {
  padding-top: 12px;
}

.pt-18 {
  padding-top: 18px;
}

.pt-24 {
  padding-top: 24px;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pr-12 {
  padding-right: 12px;
}
.pl-42 {
  padding-left: 42px;
}

// -- Input Styling -- //

.search-container {
  //search bars
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: self-end;
  justify-content: self-end;
  margin: 10px 0 0px 0;

  .norecords-text {
    top: 20px;
    position: absolute;
    color: red;
  }
}

.centered-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  .loading {
    margin: auto;
  }
}

.action-container {
  position: relative;
  .action-options-menu {
    position: absolute;
    z-index: 20;
    top: -14px;
    right: -18px;

    ul {
      li {
        white-space: nowrap;
      }
    }

    &.groups {
      right: -20px;
      top: -21px;
    }

    &.memberships {
      right: 0px;
      top: 0px;
    }

    display: flex;
    flex-direction: column;
    // gap: 10px;
    background: var(--color-gray-300);
    min-width: 180px;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    // padding: 6px 10px;
    margin-left: auto;

    li {
      cursor: pointer;
      padding: 5px 9px;
      list-style-type: none;
      width: 100%;

      &:hover {
        background: var(--color-gray-400);
      }
      .button {
        display: flex;
        justify-content: right;

        button {
          cursor: pointer;
          font-size: 12px;
          padding: 0;
          border: none;
          text-align: right;
          transition: all 0.2s linear;

          &:hover {
            background: none;
            color: rgba(255, 255, 255, 0.7);
            transition: all 0.2s linear;
          }
        }
      }
    }
    .disabled {
      pointer-events: none;
      color: var(--color-gray-200);
      background: transparent;
      button {
        color: var(--color-gray-100);
      }
    }
    .option {
      pointer-events: none;
      .btn-disabled {
        color: var(--color-gray-200);
      }
    }
  }
}
.action-option-right {
  text-align: right;
}
.border-bottom-one {
  border-bottom: 1px solid var(--color-gray-100);
}
// -- Data Display Styling -- //

.flex-wrap {
  @media screen and (max-width: 1280px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 740px) {
    flex-wrap: wrap;
  }
}

.info-card {
  display: flex;
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  padding: 18px;
}

.changes-container,
.profile-views-container,
.sms-container,
.web-app-logins-container {
  .detail {
    p {
      margin-top: auto;
    }
  }
}

// -- Table styling -- //

.no-hover-style {
  &:hover {
    background-color: transparent !important;
  }
}

.table {
  margin-top: 32px;
}

table {
  td {
    svg {
      height: 20px;
      width: 20px;
    }

    .button-td {
      display: flex;
      justify-content: flex-end;
      gap: 40px;
    }
  }
}

.add-time-row {
  display: flex;
  gap: 0px 16px;
  padding: 8px 0px 8px 17px;
  // margin-top: 5px;
  // justify-content: space-between;
  width: 100%;
  position: relative;

  div {
    display: flex;
    gap: 10px;
    position: absolute;
    right: -116px;
  }

  input {
    max-width: 109px;
    min-width: 109px;
    min-height: 22px;
    max-height: 22px;
    background: transparent;
    padding: 0px 10px;
    text-align: center;
    width: 100%;
    border-radius: 5px;
    white-space: nowrap;
    border: 1px solid var(--color-gray-400);

    &::-webkit-datetime-edit-ampm-field,
    &::-webkit-datetime-edit-hour-field,
    &::-webkit-datetime-edit-minute-field,
    &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-fields-wrapper,
    &::-webkit-inner-spin-button {
      color: white;
      font-size: 12px;
    }

    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }

    &:first-of-type {
      margin-right: 2px;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    &:last-child {
      margin-right: 16px;
    }
  }
}

// -- Error & Data Messages -- //

.no-data-holder {
  display: flex;
  justify-content: center;
  height: 50vh;

  &.in-table {
    display: table-cell;
    border-bottom: none;
  }

  .no-data {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    padding: 32px;
    width: fit-content;
    height: fit-content;
    background: var(--color-gray-300);
    border: 1px solid var(--color-white-opaque);

    p {
      color: var(--color-gray-100);
    }
  }
}

// -- Modal Styling -- //

.location-status-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 20px 0;
  h3 {
    background: var(--color-gray-300);
    border: 1px solid var(--color-mediumgray);
    padding: 30px;
    font-size: 32px;
    text-transform: capitalize;
  }
}

.status-modal {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: fit-content;
  height: fit-content;
  background: var(--color-gray-300);
  border: 1px solid var(--color-mediumgray);
  border-radius: 5px;
  padding: 32px;
  text-align: center;

  h3 {
    line-height: 0.85em;
  }

  .btn-group {
    margin: 0;
    justify-content: center;
  }

  .className-span {
    display: flex;
    gap: 12px;
    justify-content: center;

    img {
      align-self: center;
      height: 15px;
      width: 20px;
    }
  }
}

.member-changes-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 200;
  width: 780px;
  height: fit-content;
  background: #1e2a3a;
  border: 2px solid white;
  border-radius: 8px;
  padding: 32px;
  gap: 24px;
  max-height: 80vh;
  overflow-y: auto;
  .modal-title {
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    padding-bottom: 7px;
    text-transform: uppercase;
    color: var(--color-gray-100);
  }

  button {
    margin-right: auto;
    margin-left: auto;
  }

  .details-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 24px;

    .detail-entire-row {
      display: flex;
      flex: 25%;
    }

    .detail {
      display: flex;
      flex: 25%;
      flex-direction: column;
      border-bottom: 1px solid var(--color-gray-100);
      height: fit-content;

      &.full-width {
        width: 100%;
        flex: 100%;
        margin-right: calc(50% + 10px);
      }

      span {
        margin-right: 5px;
      }

      p {
        text-transform: uppercase;
      }
    }
  }

  .msg-container {
    width: 100%;
    height: 50%;

    &.small-input {
      display: flex;
      flex-direction: column;
      flex: 25%;

      textarea {
        margin-bottom: auto;
        padding: 0;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid var(--color-gray-100);
        min-height: unset;
        height: min-content;
      }
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill,
input:-webkit-autofill::first-line,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-size: 12px !important;
  -webkit-text-fill-color: var(--color-gray-100) !important;
}

.input-standard {
  position: relative;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding-bottom: 2px;
  font-family: "gotham";
  border-bottom: 1px solid var(--color-gray-100);
  color: var(--color-white);
  transition: all 0.2s linear;

  &.no-border {
    border: none;
  }
}

// -- Logo Styles -- //

.logo-img {
  width: 173px;
  height: 47px;
}

// -- Text Styles -- //

.item-centered {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.item-right {
  text-align: right;
  align-items: right;
  justify-content: right;
}

.item-left {
  text-align: left;
  align-items: left;
  justify-content: left;
}

.red {
  color: var(--color-red) !important;
}

.persianblue {
  color: var(--color-persianblue) !important;
}

.mediumred {
  color: var(--color-mediumred) !important;
}

.recharts-xAxis {
  transform: translate(0, 5%);
}

.text-black {
  color: var(--color-black);
}

.text-red {
  color: var(--color-red);
}

.text-green {
  color: var(--color-green);
}

.text-gray {
  color: var(--color-gray-100) !important;
}

// End of Standard Styles //

.icon-standard {
  height: 20px;
  width: 20px;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
}

h1 {
  font-size: 5.4rem;
}

h3,
h4 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
}

h3 {
  font-size: 1.4rem;
  font-weight: 500;
}

.fs-54 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 5.4rem;
  font-weight: 400;
}

.fs-35 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 3.5rem;
  font-weight: 400;
}

.fs-25 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
}

.fs-20 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.fs-18 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.fs-14 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 1px;
}

.fs-12 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.fs-10 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.fs-8 {
  font-family: "gotham", Arial, Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: white;
}

.right-btn {
  display: flex;
  justify-content: flex-end !important;
  gap: 0px 12px;
}

.btn {
  cursor: pointer;
  padding: 8px 10px;
  text-transform: uppercase;
  border-radius: 5px;
}

.btn-confirm {
  background: var(--color-persianblue);
  color: var(--color-white);
  font-weight: bold;
  letter-spacing: 1px;
  border: 1px solid var(--color-persianblue);
  transition: all 0.2s linear;

  &:hover {
    background: var(--color-persianblue-dark);
    border: 1px solid var(--color-persianblue-dark);
    transition: all 0.2s linear;
  }
}

.btn-deny {
  background: none;
  color: var(--color-white-opaque);
  border: 1px solid transparent;
  transition: all 0.2s linear;

  &:hover {
    color: var(--color-white);
    transition: all 0.2s linear;
  }
}

.btn-primary {
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid var(--color-persianblue);
  background: var(--color-persianblue);
  color: var(--color-white);
  letter-spacing: 1px;
  transition: all 0.2s linear;
  cursor: pointer;
  font-family: "gotham", Arial, Helvetica, sans-serif;
  &:hover {
    background: var(--color-persianblue-dark);
    transition: all 0.2s linear;
  }

  &.gray {
    background: var(--gray-4);
    border: 1px solid var(--gray-4);
    transition: all 0.2s linear;

    &:hover {
      background: var(--gray-3);
      border: 1px solid var(--gray-3);
      transition: all 0.2s linear;
    }
  }

  &.transparent {
    background: transparent;
    border: 1px solid var(--color-mediumgray);
    transition: all 0.2s linear;

    &:hover {
      border: 1px solid var(--color-gray-100);
      transition: all 0.2s linear;
    }
  }
}

.btn-rounded {
  border-radius: 16px;
  border: 0px;
}

.btn-primary-gray {
  padding: 14px 24px;
  text-transform: uppercase;
  border-radius: 5px;
  color: var(--color-white);
  background: var(--color-gray-200);
  border: none;
  transition: all 0.2s linear;

  &:hover {
    background: var(--color-persianblue);
    transition: all 0.2s linear;
  }
}

.btn-xsmall-wide {
  padding: 3px 10px;
}

.btn-xsmall {
  padding: 4px 12px;
}

.btn-small {
  padding: 8px 28px;
  border-radius: 5px;
}

.btn-lg {
  padding: 10px 20px;
  border-radius: 5px;
}

.btn-lg-wide {
  padding: 10px 30px;
}

.btn-lg-xwide {
  padding: 10px 50px;
}

.btn-green-standard {
  color: var(--color-darkgreen);
  border: 1px solid var(--color-darkgreen);
  background-color: transparent;
  transition: all 0.2s linear;

  &:hover {
    background-color: var(--color-darkgreen);
    border: 1px solid var(--color-darkgreen);
    color: var(--color-white);
    transition: all 0.2s linear;
  }
}

.btn-green {
  color: var(--color-black);
  background-color: var(--color-green);
  border: 1px solid var(--color-green);
}

.btn-yellow {
  color: var(--color-black);
  background-color: var(--color-yellow);
}

.btn-darkgreen {
  color: var(--color-white);
  background-color: var(--color-darkgreen);
}

.btn-transparent-red {
  border: 1px solid var(--color-red);
  background-color: transparent;
  color: var(--color-red);
  transition: all 0.2s linear;

  &:hover {
    background-color: var(--color-mediumred);
    border: 1px solid var(--color-red);
    color: var(--color-white);
    transition: all 0.2s linear;
  }
}

.btn-red {
  border: 1px solid var(--color-mediumred);
  background-color: var(--color-mediumred);
  color: var(--color-white);
  transition: all 0.2s linear;

  &:hover {
    background-color: var(--color-red);
    border: 1px solid var(--color-red);
    transition: all 0.2s linear;
  }
}

.btn-transparent-green {
  border: 1px solid var(--color-darkgreen);
  background-color: transparent;
  color: var(--color-darkgreen);
  transition: all 0.2s linear;

  &:hover {
    background-color: var(--color-green);
    border: 1px solid var(--color-green);
    color: var(--color-black);
    transition: all 0.2s linear;
  }
}

.btn-darkgray {
  border: 1px solid var(--color-gray-200);
  background-color: var(--color-gray-300);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-persianblue);
  }
}

.btn-lightgray {
  border: 1px solid var(--color-gray-100);
  background-color: var(--color-gray-200);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-persianblue);
  }
}

.btn-transparent {
  border: 1px solid var(--color-gray-100);
  background-color: transparent;
  color: var(--color-white);

  &:hover {
    background-color: var(--color-persianblue);
  }
}

.btn-icon {
  background: transparent;
  display: flex;
  flex-direction: row;
  border: none;
  padding: 0 0;
  gap: 4px;
}

.btn-edit {
  color: var(--color-persianblue);
  background: transparent;
  display: flex;
  flex-direction: row;
  border: none;
  padding: 0 0;
  gap: 4px;

  &:hover {
    border: none;
    color: var(--color-white);
  }

  &.save {
    color: var(--color-white);
  }

  &.cancel {
    color: var(--color-red);
    transition: 0.2s linear;

    &:hover {
      color: var(--color-mediumred);
      transition: 0.2s linear;
    }
  }
}

.react-datalist-input__listbox-option:focus {
  background-color: gray;
  color: var(--color-black);
}

.tabs {
  .tabs-wrapper {
    display: flex;
    // align-items: start;
    align-items: flex-start;

    .prev-icon {
      cursor: pointer;
      padding: 6px;
      display: block;
    }

    .next-icon {
      cursor: pointer;
      padding: 6px;
      display: block;
    }
  }
  .tabs-list {
    display: flex;
    border-bottom: 3px solid var(--color-persianblue);
    padding-bottom: 0;
    margin-bottom: 12px;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px var(--color-gray-300);
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--color-gray-300);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--color-gray-300);
    }

    &.button-type {
      border-bottom: 0;

      .tab-head {
        border-radius: 5px;
        height: 40px;
        width: fit-content;
        padding: 0 20px;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background: var(--color-gray-300);
        }
      }

      .back-transparent {
        background: transparent;
        border: 1px solid var(--color-gray-100);
      }
    }

    .tab-head {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px !important;
      // font-weight: 500;
      margin-right: 8px;
      width: 180px;
      height: 34px;
      border-radius: 5px 5px 0 0;
      padding: 0 20px;
      text-align: center;
      transition: all 0.2s linear;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1300px) {
        // font-size: 11px !important;
        // font-weight: 600;
      }

      // @media screen and (max-width: 1100px) {
      //   font-size: 8px !important;
      // }

      // @media screen and (max-width: 800px) {
      //   font-size: 5px !important;
      // }

      &:hover {
        background: var(--color-persianblue-dark);
        cursor: pointer;
        transition: all 0.2s linear;
      }
    }

    .back-mediumgray {
      background-color: var(--color-gray-300);
    }

    .back-persianblue {
      background-color: var(--color-persianblue);
    }
  }
}

textarea {
  height: max-content;
  min-height: 102px;
  width: 100%;
  color: white;
  border: 1px solid var(--color-gray-100);
  border-radius: 5px;
  padding: 5px 10px;
  background: transparent;
  overflow: auto;
  outline: none;
  resize: none;
  transition: all 0.2s linear;

  &:active {
    overflow: auto;
  }

  &.blue {
    border: 1px solid var(--color-gray-100) !important;
    transition: all 0.2s linear;
  }
}

.page-info-dropdown {
  width: 60px;
  background-color: transparent;
  color: var(--color-gray-100);
  border-radius: 5px;
  outline: none;

  option {
    outline: none;
    border-bottom: none;
    background: var(--color-black);
  }
}

//css to remove unwanted highlighting on page elements
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.icon-container {
  &.edit:hover {
    cursor: pointer;
  }
}

.title-bar {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 18px;
  width: 100%;

  .icon-container,
  .sub-containers {
    cursor: pointer;
    display: flex;
    gap: 0px 5px;

    .save,
    .cancel {
      display: flex;
      justify-content: center;

      svg {
        align-self: center;
        width: 16px;
        height: 16px;
      }
    }

    .cancel {
      svg {
        color: var(--color-gray-100);
        background: transparent;
        outline: 4px solid transparent;
        border-radius: 1px;
        transition: all 0.2s linear;

        &:hover {
          color: var(--color-white);
          background: red;
          outline: 4px solid red;
          border-radius: 1px;
          transition: all 0.2s linear;
        }
      }
    }

    .save {
      svg {
        margin-right: 16px;
        background: var(--color-persianblue-dark);
        outline: 4px solid var(--color-persianblue-dark);
        border-radius: 1px;
        transition: all 0.2s linear;

        &:hover {
          background: var(--color-persianblue);
          outline: 4px solid var(--color-persianblue);
          transition: all 0.2s linear;
        }
      }
    }

    svg {
      cursor: pointer;
      width: 15px;
      height: 15px;
    }
  }
}

.actions-row {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  max-width: 100%; //this is to trigger flex-wrap at smaller screen sizes
  &.no-space {
    justify-content: unset;
  }

  &.justify-end {
    justify-content: right;
  }
  .left-elements,
  .right-elements {
    display: flex;
    gap: 32px;

    div,
    a,
    form {
      align-self: flex-end;
    }
  }

  form {
    max-height: 24px;
    align-self: center;
  }

  .btn-group {
    align-self: flex-end;
    margin: 0;

    button {
      padding: 4px 10px;
      font-size: 8px;
      background: var(--color-gray-300);
    }
  }
}

.error {
  margin-top: 5px;
  margin-bottom: 5px;
  color: var(--color-red);
  font-size: 14px;
  text-align: center;
  // white-space: nowrap;
  white-space: pre-line;
  word-wrap: normal;

  &:empty {
    display: none;
  }
}

.nowrap {
  white-space: nowrap;
}

.chat_icon {
  display: none;
  position: absolute;
  bottom: 16px;
  z-index: 10;
  right: 70px;
  // opacity: 0.8;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  .chat_window {
    position: relative;
    z-index: 20;
  }
}

.form-group {
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  label {
    text-transform: capitalize;
  }
}

input[type="file"] {
  display: none;
}

.iframe-btn {
  width: 306px;
  height: 61px;
  border: none;
  outline: none;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid red;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
#swal2-title {
  font-size: 30px;
}
.swal2-popup.swal2-modal.swal2-show {
  width: 60em;
}
#swal2-html-container {
  font-size: 18px;
}
.swal2-footer {
  font-size: 18px;
}
.swal2-actions {
  width: 120px;
  height: 36px;
  button.swal2-confirm {
    width: 100%;
    height: 100%;
    font-size: 16px;
  }
}
.w-650 {
  width: 650px;
}
