.button {
  cursor: pointer;
  // display: flex;
  // justify-content: flex-end;
  .icon-btn-group {
    display: flex;
    align-items: center;
    column-gap: 10px;
    .icon {
      width: 1.2rem;
      margin-right: -0.5rem;
    }
  }
}
