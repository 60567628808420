.generate-box {
  display: flex;
  padding: 24px 16px 29px 19px;
  height: max-content;
  width: 100%;
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  margin-top: 30px;
  justify-content: space-between;

  h5,
  div,
  button {
    align-self: center;
  }
  .date-select-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .left-content {
    display: flex;
    gap: 0px 30px;
    .report-betwwen {
      margin: auto;
    }
  }
}
