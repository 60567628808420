.clubsetting-container {
  .detail-grid {
    min-height: 50px;
  }

  .mid-row {
    min-height: 120px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .details-container.phone-details {
    .detail.number {
      label {
        margin-bottom: 0;
      }
    }
  }
  .toggle {
    font-size: 1.2rem;
    margin-bottom: 4px;

    .switch {
      position: relative;
      display: inline-block;
      width: 28px;
      height: 15px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      border: 1px solid var(--color-gray-400);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 11px;
      width: 11px;
      left: 1px;
      bottom: 1px;
      background-color: var(--color-gray-400);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked {
      + .slider:before {
        background: var(--color-white);
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }

      + .slider {
        background-color: var(--color-persianblue);
        box-shadow: 0 0 1px var(--color-persianblue);
        border: 1px solid var(--color-persianblue);
      }
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    input {
      margin-right: 6px;
      opacity: 0.4;
      border: 1px solid var(--color-white);
    }

    label {
      padding-left: 4px;
    }
  }
  .debt-collection {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
}
