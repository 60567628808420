.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100vw !important;
  // background-image: url("../../assets/images/login_BG.png");

  .logo {
    width: 370px;
    padding: 23px 0px 24px 29px;
    text-align: center;
    .logo-img {
      width: 265px;
      height: 72px;
      padding-right: 24px;
    }
    .sub-logo {
      display: flex;
      justify-content: flex-end;
      padding-right: 53px;
      img {
        width: 91px;
        height: 16px;
        object-fit: contain;
      }
    }
  }
  .login-container {
    margin-bottom: 10px;
    min-width: 350px;
    // border: 1px solid var(--color-gray-100);
    padding: 24px;
    border-radius: 5px;
    background: var(--color-gray-300);
    .form-group {
      margin: 24px 0;
    }
    .frm-group {
      row-gap: 24px;
      display: flex;
      flex-direction: column;
    }
    p:first-child {
      font-size: 12px;
      line-height: 160%;
      color: white;
      text-align: center;
    }
    p:nth-child(2) {
      font-size: 12px;
      width: 450.55px;
      line-height: 160%;
      color: #bfbfbf;
      text-align: center;
    }
    p.error-text {
      font-size: 12px;
      width: 450.55px;
      line-height: 160%;
      color: #bfbfbf;
      text-align: center;
      color: red;
    }
    h1 {
      text-align: center;
      line-height: 90%;
    }
    .button {
      text-align: center;
      button {
        border: none;
      }
    }
    .expired-Otp {
      width: 450.55px;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .link-forgot-password {
    width: 370px;
    padding: 21px 33px 12px 35px;
    color: var(--color-gray-100);
    text-decoration: underline;
    text-align: center;
  }
  .otp-option-container {
    width: 500px;
    margin: 24px 0 5px 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    > span {
      font-size: 12px;
      cursor: pointer;
      color: #bfbfbf;
      letter-spacing: 1.2px;
      text-decoration: underline;
    }
  }
}
