.payment-log {
  max-width: fit-content;
  .first-row-container,
  .second-row-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: left;
    gap: 24px;
    width: 100%;
    div {
      flex: 1;
      input {
        margin-top: 5px;
        width: 100%;
      }
    }
  }
  .textarea-details {
    width: 100%;
    text-align: left;
  }
}
.display-payments-options {
  > div:first-child {
    margin-right: auto;
  }
  .select-element {
    align-self: flex-end;
    padding-right: 22px;
    label,
    select {
      font-size: 14px;
    }
  }
}
button {
  &.disabled {
    background: transparent;
    color: var(--color-mediumgray);
    cursor: not-allowed;
  }
}
.table {
  .table-container {
    width: 100%;
    overflow-x: scroll;
    scrollbar-color: var(--color-white);
    scrollbar-width: thin;
    border-bottom: var(--color-grey);
    &::-webkit-scrollbar {
      display: inline-flex;
    }
  }
}
