.btn-disabled {
  background: transparent;
  pointer-events: none;
  color: var(--color-gray-200);
  cursor: default;
}
.member-name {
  text-align: left;
}

.landing-table.u18-approve {
  th,
  td {
    &:nth-child(2) {
      div {
        justify-content: left;
      }
    }
  }
  td {
    &:nth-child(4) {
      text-align: center;
    }
  }
}
