.feature-tile {
  // max-width: 233px;
  width: 100%;
  min-height: min-content;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;

  img {
    height: 100%;
    width: 100%;
    max-height: 149px;
    // max-width: 205px;
  }
}
