.groups-container {
  .groups-landing-content {
    .groups-table {
      table {
        th,
        td {
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(4) {
            text-align: right;
            padding-right: 20px;
            img {
              position: absolute;
              top: -25px;
              right: -35px;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 20%;
            text-align: center;
          }
        }
      }
    }
  }
}

// ------ Add & Edit Group ------ //

.add-group-container,
.edit-group-container {
  display: flex;
  flex-direction: column;

  input {
    width: 50%;
    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }
}

// ------ Group Location Access ------ //

.apply-group-to-loc {
  .apply-group-to-loc-content {
    .actions-row {
      .group-name {
        margin-top: auto;
      }
    }

    .table {
      table {
        td,
        th {
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 30%;
            padding-right: 50px;
            text-align: center;

            .icon {
              position: relative;
              img {
                // position: absolute;
                margin-right: 5px;
                // top: 2px;
                // left: 2px;
              }
            }

            div .filter-td {
              position: absolute;
            }
          }

          &:nth-child(3) {
            text-align: right;
            padding-right: 50px;
            div {
              justify-content: right;
            }
          }
          @media screen and (max-width: 1100px) {
            &:nth-child(1) {
              width: 30%;
            }
            &:nth-child(2) {
              width: 40%;
            }
          }
          @media screen and (max-width: 910px) {
            &:nth-child(1) {
              width: 40%;
            }
            &:nth-child(2) {
              width: 60%;
            }
          }
        }
      }
      .right-btn {
        button {
          text-transform: uppercase;
          border: none;
          margin-top: 10px;

          &:disabled {
            background: var(--color-gray-100);
          }
        }
      }

      // .pagination {
      //   margin-top: -50px;
      // }
    }
  }
}

// ------ Group View Details ------ //

.group-details-container {
  .group-details-content {
    position: relative;

    .group-name {
      position: absolute;
    }

    @media screen and (max-width: 1000px) {
      .group-name {
        position: unset;
        padding-bottom: 12px;
      }

      .search-container {
        form {
          width: 100%;
          .search-bar {
            width: 100%;
          }
        }
      }
    }
  }
}

// ------ Add Staff Access ------ //

.staff-access-container {
  .staff-access-content {
    .table {
      td,
      th {
        &:nth-child(1),
        &:nth-child(2) {
          width: 10%;
          text-align: center;
          padding-right: 50px;
          div {
            justify-content: center;
          }
        }
      }

      td {
        &:nth-child(1) {
        }
      }
      .right-btn {
        button {
          text-transform: uppercase;
          border: none;
          margin-top: 10px;

          &:disabled {
            background: var(--color-gray-100);
          }
        }
      }

      // .pagination {
      //   margin-top: -50px;
      // }
    }
  }
}
