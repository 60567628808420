// .checkbox {
//   // margin-bottom: 4px;
//   max-height: 13.8px;

//   input {
//     margin-right: 6px;
//     opacity: 1;
//     border: 1px solid var(--color-white);
//     border-radius: 5px;

//     &[type="checkbox"] {
//       // display: none;
//       border-radius: 5px;
//       position: relative;
//       background-color: transparent;
//       &:checked {
//         accent-color: #2533d5;
//       }
//       &:disabled {
//         accent-color: red;
//       }
//     }
//   }
// }

.checkbox {
  input {
    display: none;

    &:checked + span,
    &:disabled + span {
      background-color: var(--color-persianblue);
      border: 1px solid var(--color-persianblue);
      svg {
        visibility: visible;
      }
    }

    &:disabled + span {
      background-color: var(--color-gray-400);
      border: 1px solid var(--color-gray-400);
      opacity: 0.9;

      svg {
        color: var(--color-white);
      }
    }
  }
  span {
    max-height: 13.8px;
    // margin-bottom: 4px;
    width: 13px;
    height: 13px;
    display: inline-block;
    background-color: transparent;
    margin-bottom: -2px;
    margin-right: 4px;
    opacity: 1;
    border-radius: 2px;
    border: 2px solid var(--color-gray-200);
    position: relative;
    svg {
      visibility: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      max-height: 11px;
      width: 11px;
      height: 11px;
      pointer-events: none;
    }
  }
}
