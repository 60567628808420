.suspension-container {
  .medical-suspension-content,
  .standard-suspension-content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .suspension-dialog-box {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    .suspension-details-box {
      flex-direction: column;
      gap: 18px;

      .input-details-row {
        display: grid;
        gap: 18px;
        grid-template-columns: repeat(3, 1fr);
        div {
          flex: 1;
        }
        &.column {
          flex-direction: column;
        }
        p {
          flex: 1;
          border-bottom: 1px solid var(--color-gray-100);
        }
        .certificate-records {
          display: flex;
          flex-direction: column;
          gap: 18px;
        }
      }
      .add-certificate {
        display: flex;
        gap: 10px;
        p,
        svg {
          align-self: center;
        }
      }
    }

    .status-modal.overwrite-suspension-modal {
      .warning-text {
        border-bottom: 0px;
      }
    }
  }
}

.suspension {
  .suspension-table {
    .table-container {
      table {
        th {
          &:nth-child(1),
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 50%;
          }
        }
      }
    }
  }
}

.suspension-cost-container {
  svg,
  .button {
    cursor: pointer;
  }
  .editable-input {
    display: flex;
    position: relative;
    svg {
      color: var(--color-gray-100);
      background: transparent;
      outline: 2px solid transparent;
      border-radius: 1px;
      position: absolute;
      top: 20px;
      right: 0;
      height: 14px;
      width: 14px;
    }
    &.edit {
      svg {
        &:hover {
          color: var(--color-white);
          background: var(--color-persianblue);
          outline: 2px solid var(--color-persianblue);
          border-radius: 1px;
          transition: all 0.2s linear;
        }
      }
    }
  }

  .cancel-cost-container {
    .sub-container {
      display: flex;
      position: relative;

      .button {
        position: absolute;
        top: 7px;
        right: 0;
      }
      svg {
        color: var(--color-gray-100);
        background: transparent;
        outline: 2px solid transparent;
        border-radius: 1px;
        position: absolute;
        top: 14px;
        right: 0;
        height: 14px;
        width: 14px;
      }
      &.cancel {
        svg {
          &:hover {
            color: var(--color-white);
            background: var(--color-red);
            outline: 2px solid var(--color-red);
            border-radius: 1px;
            transition: all 0.2s linear;
          }
        }
      }
    }
  }
}

.edit-suspension-modal-container {
  .edit-suspension-amount {
    .input-container {
      .input-and-dropdown {
        .amount-container {
          display: flex;
          flex-direction: column;
          gap: 24px;
          text-align: left;
          .form-group,
          .datepicker-container {
            gap: 8px;
          }
          .datepicker-container {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .desc-row {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .input-details-row {
    row-gap: 18px;
    grid-template-columns: repeat(2, 1fr) !important ;
  }
}
@media screen and (max-width: 560px) {
  .input-details-row {
    row-gap: 18px;
    grid-template-columns: repeat(1, 1fr) !important ;
  }
}
