.report-options-month-container {
  padding: 20px 24px 17px 14px;
  height: max-content;
  width: 100%;
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  margin-top: 30px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: nowrap;
  > div:first-child {
    width: 100%;
  }
  .date-range-container {
    display: flex;
    align-items: start;
    gap: 1rem;
  }
  .submit-container {
    margin: auto 0 0 0;
    width: 13%;
    min-width: fit-content;
  }
  @media screen and (max-width: 1150px) {
    gap: 18px;
  }

  .report-options-inputs {
    justify-content: start;
    > div {
      width: 13%;
      min-width: max-content;
    }
    > div.select-element {
      min-width: 20%;
      min-width: max-content;
      justify-content: space-between;
      gap: 11px;
    }
    @media screen and (max-width: 1150px) {
      flex-wrap: wrap;
      gap: 18px;
      > div {
        width: fit-content;
      }
    }
    display: flex;
    flex-wrap: wrap;
    //   grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: start;
    gap: 24px;
  }
  .submit-container {
    display: flex;
    justify-content: flex-end;
    margin: auto 0;
    margin-left: auto;
  }
  .date-input-container {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: fit-content;
    .show-subtitle {
      font-size: 12px;
      margin-right: 0px;
      align-self: flex-start;
    }
    input {
      color: var(--color-white);
      border: 1px solid var(--color-white);
    }
  }
  .submit-container {
    margin-left: auto;
  }
  .date-input,
  .date-input-container {
    flex-direction: column;
    display: flex;
    // align-items: center;
    gap: 1rem;
    input {
      color: var(--color-white);
      border: 1px solid var(--color-white);
    }
  }
  .flex-row.drop-down-container {
    gap: 18px;
    @media screen and (max-width: 1350px) {
      flex-direction: column;
      gap: 18px;
    }
  }
  .drop-down {
    display: flex;
    align-items: center;

    p {
      align-self: center;
      margin-right: 30px;
    }

    .select-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0;
      gap: 1rem;
    }
  }
}
