.member-details {
  .gray-disabled {
    background-color: var(--color-gray-200);
    color: var(--color-white);
  }
  .member-details-container {
    .member-details-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      padding-top: 20px;
      padding-bottom: 15px;

      .name {
        font-family: "Bebas Neue", sans-serif;
        font-size: 40px;
      }
      @media screen and (max-width: 740px) {
        flex-direction: column;
        align-items: flex-start;

        .name {
          margin-left: 24px;
        }
      }

      .right {
        margin-left: 24px;
        display: flex;
        gap: 8px;
        align-items: center;
        div {
          margin-bottom: 4px;
        }
        .heading {
          color: var(--color-white);
          margin-bottom: 5px;
          align-self: flex-end;
        }
        .member-id {
          display: flex;
          flex-direction: column;

          .member-id-container {
            display: flex;
            gap: 10px;
          }
          .member-card {
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 2px;
            background-color: var(--color-persianblue);
            text-transform: uppercase;
            white-space: nowrap;
            padding: 6px 6px;
          }
        }

        .member-type {
          margin-right: 10px;
          .icons {
            display: flex;
            gap: 5px;
            min-height: 25px;

            .value {
              min-width: max-content;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 6px 6px;

              border-radius: 2px;
              color: var(--color-white) !important;

              //Rename these when we know what they all stand for
              &.green {
                background-color: var(--color-darkgreen);
              }
              &.red {
                background-color: var(--color-mediumred);
              }
              &.blue {
                background-color: var(--color-persianblue-dark);
              }
            }
          }
        }

        .allowed-btn {
          .btn-disabled {
            cursor: not-allowed;
            background-color: var(--color-darkgreen);
            color: white;
          }
          button {
            // border: 1px solid var(--color-green);
            // transition: all 0.2s linear;

            &:hover {
              filter: brightness(65%);
              transition: all 0.2s linear;
              -webkit-filter: brightness(65%);
              -webkit-transition: all 0.2s linear;
              -moz-transition: all 0.2s linear;
              -ms-transition: all 0.2s linear;
              -o-transition: all 0.2s linear;
            }
          }
        }
      }
    }
  }
}

.member-info-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 12px;
  // min-height: 231px;
  margin-bottom: 12px;

  .hide-on-shrink {
    display: inline-flex;
    flex: 30% 1;
  }

  //this div is hidden until the media query
  //below is triggered
  &.show-on-shrink {
    display: none;
  }

  @media screen and (max-width: 1425px) {
    // flex-direction: column;
    flex-wrap: wrap;

    .hide-on-shrink {
      display: none;
    }
    &.show-on-shrink {
      display: flex;
      .interaction-container,
      .entry-container {
        width: 50%;
      }
    }
  }
}

.spacer {
  flex: 30%;
}

tr .right {
  text-align: right;

  .button:nth-child(1) {
    button {
      padding: 8px 24px;
    }
  }

  .button:nth-child(2) {
    button {
      padding: 8px 5px;
    }
  }
}

tr .left {
  text-align: left;
}
.red-banner {
  background-color: var(--color-red);
  color: var(--color-white);
  text-align: center;
  padding: 8px 0;
  font-size: 14px;
  margin: 0 0 10px 0;
  border-radius: 5px solid var(--color-red);
}
// MEMBERSHIPS TAB CONTENT CSS

.member-details-container {
  .add-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  .add-btn-tooltip {
    position: relative;
    display: inline-block;
  }

  .add-btn-tooltip .add-btn-tooltip-text {
    width: 170px;
    height: 36px;
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    line-height: 36px;
    // padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 0%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .add-btn-tooltip:hover .add-btn-tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  .memberships-table {
    table {
      thead {
        text-transform: uppercase;
      }

      td {
        &:nth-child(9) {
          text-align: right !important;
          padding: 0;
          .action-container {
            .action-options-menu {
              top: -4px;

              ul {
                li {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }

      th {
        &:nth-child(9) {
          padding-left: 15px;
          padding-right: 10px;
        }
      }

      td,
      th {
        text-align: left !important;

        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          width: 14%;
        }

        &:nth-child(6) {
          width: 10%;
        }

        &:nth-child(7) {
          width: 14%;
        }
        &:nth-child(8) {
          width: 5%;
        }
        &:nth-child(9) {
          width: 5%;
        }
      }
    }
  }
}

.btn-cell {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: flex-end;
}

.make-a-payment-modal {
  gap: 0 !important;
  width: 572px;
  .member-details-modal-container,
  .outstanding-payments-container,
  .payment-details-modal-container,
  .new-payment-details {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 24px;
    .member-details-inputs {
      display: flex;
      gap: 24px;
    }
  }
  .outstanding-payments-container {
    .form-group1 {
      width: 50%;
    }
  }
  .payment-options-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    flex-direction: row;
    label {
      display: flex;
    }
  }
  .payment-options-container-1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    flex-direction: row;
    label {
      display: flex;
    }
  }
  .payment-options-singup {
    align-self: self-start;
    width: 100%;
    label {
      cursor: pointer;
    }
    .direct-options {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: center;
      flex-direction: row;
      label {
        display: flex;
      }
    }
  }
  .payment-details-modal-container {
    margin-bottom: 15px;
    flex-direction: row;
  }
  .new-payment-details {
    display: flex;
  }
  .make-payment-btn-container {
    display: flex;
    gap: 24px;
    justify-content: center;
  }
  .credit-description {
    line-height: 20px;
  }
  .future-payment-container {
    width: 100%;
    h3 {
      margin: 20px 0;
    }
    p {
      color: var(--color-gray-100);
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  .payment-types-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .payment-type {
      font-size: 15px;
      color: var(--color-gray-100);
      display: flex;
      label {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}
.viva-pay {
  width: auto;
}
.signup-modal {
  width: 570px;
  align-items: normal;
}

// PAYMENTS TAB CONTENT CSS

.payments-main-container {
  display: flex;
  margin-bottom: 50px;
  flex-direction: column;

  .billing-info-container {
    width: 100%;
    // height: 184px;
    // border: 1px solid var( --color-gray-400);
    border-radius: 5px;
    margin-top: 30px;
    .tabContent {
      border: 2px solid var(--gray-4);
      padding: 20px;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
    h3 {
      letter-spacing: 0.1em;
    }

    span {
      display: flex;
      gap: 13px;

      img {
        align-self: flex-start;
      }
    }

    .first-row,
    .sec-row {
      flex: 0 1 auto;
      gap: 10px 0px;
      display: flex;

      @media (max-width: 1450px) {
        flex-wrap: wrap;
      }
    }

    // .first-row {
    //   padding-bottom: 21px;
    // }

    .sec-row {
      .names {
        margin-right: 9px;
      }

      .bank {
        width: 100%;
        max-width: 135px;
        margin-right: 12px;
      }

      .acc-no {
        width: 100%;
        max-width: 69px;
        margin-right: 12px;
      }

      .bsb {
        width: 100%;
        max-width: 58px;
        margin-right: 12px;
      }
    }
  }

  .payment-table-container {
    .options-bar {
      display: flex;
      justify-content: space-between;

      .btn-container {
        display: flex;
        gap: 0px 8px;
      }

      .fitlers-container {
        padding-bottom: 15px;
        .pickers {
          display: flex;
          gap: 15px;
          justify-content: flex-end;
        }
      }
    }
    .payments-table {
      td,
      th {
        padding: 20px;
        padding-left: 18px;
      }
    }
  }
}

// INTERACTIONS TAB CONTENT CSS

.table-header {
  &.interactions {
    text-transform: uppercase;
  }
}
.filter-grid {
  .filter-cell-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 20px;
    .filter-cell {
      display: flex;
      flex: 0 0 calc(33.33333% - 20px);
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;
      padding: 15px 16px 7px 21px;
      text-transform: uppercase;
      white-space: nowrap;
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        background: var(--gray-4);
        transition: all 0.2s linear;
      }

      &.web {
        .radio-container {
          padding-right: 15px;
        }
      }

      p {
        align-self: flex-start;
      }

      .checkbox {
        align-self: center;
      }

      .icon {
        font-weight: 300;
        margin-left: auto;

        &.flip {
          transform: scaleX(-1);
        }
      }
    }
  }
}

.dates {
  display: grid;
  grid-column: 1/6;
  grid-template-rows: 1fr;
  grid-auto-columns: min-content;
  gap: 0px 0px;
  grid-template-areas: "from to deselect select";

  .select,
  .deselect {
    padding: 14px 5px;
  }
  .from {
    grid-area: from;
    display: flex;
    padding: 6px 8px 5px 0px;
    color: var(--color-gray-400);

    align-self: start;
  }
  .to {
    grid-area: to;

    display: flex;
    padding: 6px 8px 5px 9px;
    color: var(--color-gray-400);
    align-self: start;
  }
  .deselect {
    grid-area: deselect;
  }
  .select {
    grid-area: select;
  }

  .select,
  .deselect {
    font-weight: 400;
    font-size: 10px;
    line-height: 160%;
    text-transform: uppercase;
  }
}
.selection {
  grid-area: selection;
}
.time {
  grid-area: time;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .filter-cell-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (max-width: 560px) {
  .filter-cell-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
.outstanding-container {
  input {
    width: 100%;
  }
  .outstanding-amount {
    position: relative;
  }
  .edit-icon {
    position: absolute;
    right: 50%;
    top: 40%;
  }
}
