.info-container {
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  transition: all 0.2s linear;
  flex: 70%;
  height: initial;
  // max-width: 653px;
  @media screen and (max-width: 768px) {
    min-height: 380px;
  }
  .centered-loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    .loading {
      margin: auto;
    }
  }
  .form-group,
  .select-element {
    margin-top: 0;
  }
  &:hover {
    // background: var(--color-gray-300);
    // transition: all 0.2s linear;
  }
  .photo-container {
    flex: 20%;
    height: 100%;
    z-index: 0;
    // outline: 1px solid red;
    transition: all 0.2s linear;
    @media screen and (max-width: 1280px) {
      flex: unset;
      margin: auto;
    }

    img {
      height: 195px;
      width: 144px;
    }
  }
  .personal-info {
    display: flex;
    gap: 18px;
    flex: 80%;

    .input-container {
      width: 100%;
      .info-body {
        .content-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto 1fr;
          gap: 14px 18px;

          padding-bottom: 20px;

          //CSS for information fields
          .info-row {
            padding-bottom: 15px;
          }

          @media screen and (max-width: 1280px) {
            grid-template-columns: auto;
          }
        }
      }
    }

    .button-container {
      display: flex;
      gap: 10px;
      flex-direction: row;

      button {
        font-size: 12px;
        padding: 6px 30px;
        font-weight: normal;
      }
    }
  }
}

// #dob-display::-webkit-calendar-picker-indicator {
//   display: block;
//   top: 0;
//   right: 0;
//   background: transparent;
//   position: absolute;
//   transform: scale(20);
//   z-index: 100;
//   cursor: pointer;
// }
