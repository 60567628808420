.staff {
  .staff-container {
    position: relative;

    .member-filter {
      position: absolute;
      top: 230px;
      left: 175px;
    }
    .inline {
      display: flex;
      gap: 10px;
      position: absolute;
      right: 20px;
      z-index: 1;
    }

    .table {
      table {
        td,
        th {
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(7) {
            text-align: center;
          }
        }
        td {
          &:last-child {
            div {
              text-align: right;
              align-items: right;
              justify-content: right;
            }
          }
        }
      }
    }
  }

  .table {
    table {
      th,
      td {
        &:nth-child(1),
        &:nth-child(6) {
          width: 15%;
        }

        // &:nth-child(3) {
        //   width: 15%;
        // }
      }
    }
  }
}

.staff-report-table {
  .table {
    table {
      td,
      th {
        width: 20%;
      }
    }
  }
}

.email-btn-container {
  display: flex;
  gap: 10px;
  flex-direction: row;

  .disabled {
    pointer-events: none;
  }
}
.beat-or-send {
  width: 25px;
}
@media screen and (max-width: 1280px) {
  .member {
    .member-container {
    }
  }
}

@media screen and (max-width: 1024px) {
  .member {
    .member-container {
      .member-info {
        margin-top: 1.8rem;
      }
    }
  }
}
