.view-suspension-container {
  .view-suspension-content-container {
    display: flex;
    gap: 18px;
    flex-direction: column;
    border: 1px solid var(--color-gray-100);
    border-radius: 5px;
    .view-suspension-input {
      gap: 18px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      input {
        width: 100%;
      }
    }
    .form-group1 {
      width: 100%;
    }
    table {
      tr {
        th,
        td {
          text-align: left;
          width: 33%;
          > .button-td {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          svg:hover {
            border-radius: 1px;
            outline: 5px solid var(--color-persianblue);
            background: var(--color-persianblue);
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
          }
        }
      }
      // column-gap: 40px;
      border-spacing: 10px 2rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .view-suspension-content-container {
    .view-suspension-input {
      row-gap: 18px;
      grid-template-columns: repeat(2, 1fr) !important ;
    }
  }
}
@media screen and (max-width: 560px) {
  .view-suspension-content-container {
    .view-suspension-input {
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
}
