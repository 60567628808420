.management-staff {
  width: 100%;

  #management-staff-form {
    display: flex;
    flex-direction: column;
  }

  .details-container {
    display: flex;
    flex-wrap: wrap;
  }
}
