.main-location-container {
  .no-underline {
    border: none !important;
  }

  .underline {
    border-bottom: 1px solid var(--color-gray-400);
  }

  .location-details-header {
    width: 100%;
    display: flex;
    flex-direction: column;

    .title-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      h3 {
        text-transform: uppercase;
      }

      .info-icons {
        position: relative;

        img:first-of-type {
          margin-right: 11px;
        }
      }
    }

    .addition-info-container {
      display: flex;
      flex-direction: column;
      padding-top: 4px;
      gap: 4px;

      .loc-info {
        display: flex;
        gap: 0px 8px;
        align-items: center;

        svg {
          color: var(--color-persianblue);
          width: 14px;
        }

        p {
          text-transform: uppercase;
          text-wrap: wrap;
        }
      }

      .contact-info {
        display: flex;
        gap: 0px 16px;

        p {
          color: var(--color-gray-400);
          letter-spacing: 0.1em;
          font-weight: 400;
        }
      }
    }
  }

  // tab content CSS below - club info
  .club-info-container {
    display: flex;
    gap: 14px;
    flex-direction: column;

    h3 {
      text-transform: uppercase;
    }

    .first-card,
    .sec-card,
    .third-card,
    .fourth-card {
      .select-element {
        margin-top: 0;
      }

      transition: all 0.2s linear;

      // &:hover {
      //   background-color: var(--color-gray-300);
      //   transition: all 0.2s linear;
      // }

      .title-bar {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      .details-container {
        display: flex;
        flex-direction: row;
        gap: 0px 18px;

        .detail {
          display: flex;
          flex-direction: column;
          flex: 1;
          // border-bottom: 1px solid var( --color-gray-400);

          &.name {
            flex: 50%;
          }
          &.location-type {
            flex: 40%;
          }
          &.short-name {
            flex: 15%;
          }

          h3 {
            color: var(--color-gray-400);
            padding-bottom: 5px;
          }

          p {
            text-transform: capitalize;
          }
        }
      }
    }

    .clubsetting-container {
      @media screen and (max-width: 1100px) {
        .third-card {
          .details-container {
            flex-wrap: wrap;

            .detail {
              padding-bottom: 15px;
              max-width: 215px;

              .form-group {
                label {
                  white-space: nowrap;
                }
              }
            }

            .toggle-container {
              padding-bottom: 15px;
              gap: 0px 34px;

              .toggle-flexbox {
                align-self: unset;
              }
            }
          }
        }
      }
    }

    .first-card,
    .third-card {
      height: fit-content;
      width: 100%;
      border: 2px solid var(--gray-4);
      border-radius: 5px;
      padding: 23px 22px 38px 16px;
    }

    .fourth-card {
      height: max-content;
      width: 100%;
      padding: 23px 22px 33px 18px;
      border-radius: 5px;
      border: 2px solid var(--gray-4);
    }

    .sec-card {
      display: flex;
      flex-direction: column;
      gap: 18px;
      height: max-content;
      width: 100%;
      padding: 23px 22px 33px 18px;
      border-radius: 5px;
      border: 2px solid var(--gray-4);

      .title-bar {
        padding-bottom: 0px;
      }

      .details-container {
        &.address {
          display: flex;
          gap: 0px 50px;
          justify-content: space-between;

          .address-first-col {
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex: 1;

            .address {
              flex: 40%;
              overflow: hidden;
              white-space: nowrap;
            }

            .address-sec-row {
              display: flex;
              gap: 20px;

              .suburb {
                flex: 45%;
              }

              .state,
              .post {
                flex: 5%;
                width: 25%;
              }
            }
          }

          .address-sec-col {
            display: flex;
            flex-direction: column;
            gap: 28px;
            flex: 1;

            .first-row {
              display: flex;
              gap: 20px;
            }
          }

          @media screen and (max-width: 1500px) {
            flex-direction: column;

            .address-sec-col {
              .name,
              .short-name {
                margin-top: 10px;
              }
            }
          }
        }

        &.phone-details {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;

          .detail {
            &.number {
              min-width: 246px;
              width: max-content;
              flex: unset;
            }
          }
        }
      }

      .container {
        display: grid;
        width: 85%;
        grid-template-columns: 1fr;
        gap: 10px 30px;
        grid-template-areas:
          "time open openTime"
          "groupFitness crache classBookings";

        div {
          display: flex;
        }

        .detail {
          display: flex;
          flex: 1;
          flex-direction: column;

          h3 {
            color: var(--color-gray-400);
            padding-bottom: 5px;
          }

          p {
            padding-bottom: 5px;
          }
        }

        &.toggles {
          width: fit-content;
        }
      }

      .time {
        grid-area: time;
      }

      .open {
        grid-area: open;
      }

      .openTime {
        grid-area: openTime;
      }

      .groupFitness {
        grid-area: groupFitness;
      }

      .crache {
        grid-area: crache;
      }

      .classBookings {
        grid-area: classBookings;
      }

      .detail-checkbox {
        display: flex;
        justify-content: flex-end;
        gap: 3px;

        .toggle,
        p {
          margin-top: auto;
        }

        p {
          padding-bottom: 6px;
        }
      }

      .mid-row {
        .container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;
          gap: 26px 15px;
          grid-auto-flow: row;
          // grid-template-areas:
          //   "prim-email pt-email price-email"
          //   "club-url threed-url override-url";

          @media screen and (max-width: 1100px) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            // grid-template-areas:
            //   "prim-email pt-email"
            //   "price-email club-url"
            //   "threed-url override-url";
          }
          .expandedContainer {
            grid-column: span 4 / span 4;
            gap: 26px 15px;
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }
        }

        // .prim-email {
        //   grid-area: prim-email;
        // }

        // .pt-email {
        //   grid-area: pt-email;
        // }

        // .price-email {
        //   grid-area: price-email;
        // }

        // .club-url {
        //   grid-area: club-url;
        // }

        // .threed-url {
        //   grid-area: threed-url;
        // }

        // .override-url {
        //   grid-area: override-url;
        // }
      }

      .final-row {
        display: flex;
        flex-direction: column;
        row-gap: 18px;

        @media screen and (max-width: 1100px) {
          .details-container {
            flex-wrap: wrap;

            .detail {
              padding-bottom: 15px;
            }
          }
        }
      }
    }

    .third-card {
      .toggle-container {
        display: flex;
        gap: 0px 44px;

        .test {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .toggle-flexbox {
            align-self: flex-start;
          }
        }

        .toggle-flexbox {
          gap: 40px 7px;
          display: flex;
          align-self: flex-end;
        }
      }
    }

    .fourth-card {
      .first-col {
        display: flex;
        flex-direction: column;
        width: 35%;
        height: auto;

        .toggle-box {
          display: flex;
          padding-top: 8px;
          gap: 0px 8px;
        }

        .detail {
          padding-top: 26px;
        }
      }

      .sec-col {
        display: flex;
        flex-direction: column;
        gap: 26px;
        width: 65%;

        .detail {
          p {
            text-transform: uppercase;
          }
        }

        .leads-message {
          display: flex;
          flex-direction: column;
          flex: 70%;
        }
      }
    }
  }
}

.landing-table {
  &.location-refunds,
  &.location-vouchers,
  .location-sms {
    th {
      div {
        justify-content: left;
      }
    }
  }
}

.loading-center {
  // height: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 18px;
}
