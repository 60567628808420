.membership-list-container {
  .membership-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    > div {
      height: 100%;
      background-color: #1a2027;
      padding: 24px 54px;
      border: 1px solid #5f5d5d;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > .button {
        /* margin: auto auto; */
        display: flex;
        height: 100%;
        margin-top: auto;
        justify-content: center;
        align-items: end;
        > button {
          width: 100%;
        }
        > button.active {
          background-color: var(--color-persianblue);
        }
      }
      > div:first-child {
        p {
          text-align: center;
        }
        > p:first-child {
          text-transform: uppercase;
          font-size: 25px;
        }
        > p:nth-child(2) {
          margin: 14px 0;
          font-size: 35px;
          > span {
            font-size: 15px;
          }
        }
        > .price-sum {
          font-size: 12px;
          color: #797f87;
          padding-bottom: 14px;
          border-bottom: 1px solid #797f87;
        }
        .description {
          padding: 14px 12.5px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 24px;
          > div {
            gap: 12px;
            display: flex;
            align-items: center;
          }
          p {
            text-align: start;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .membership-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .voucher-card {
    position: relative;
    height: 100%;
    width: 350px;
    background-color: #1a2027;
    padding: 50px 54px 24px 54px;
    border: 1px solid #5f5d5d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .button {
      /* margin: auto auto; */
      display: flex;
      height: 100%;
      margin-top: auto;
      justify-content: center;
      align-items: end;
      > button {
        width: 100%;
      }
      > button.active {
        background-color: var(--color-persianblue);
      }
    }
    .membership-content {
      display: flex;
      flex-direction: column;

      p {
        text-align: center;
      }
      > p:first-child {
        text-transform: uppercase;
        font-size: 25px;
      }
      > p:nth-child(2) {
        margin: 14px 0;
        font-size: 35px;
        > span {
          font-size: 15px;
        }
      }
      .complimentary-header {
        border-bottom: 1px solid #797f87;
        padding-bottom: 14px;
        color: var(--color-persianblue);
      }
    }
    .price-sum {
      font-size: 12px;
      color: #797f87;
      padding-bottom: 14px;
      border-bottom: 1px solid #797f87;
    }
    .description {
      padding: 14px 12.5px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 24px;
      > div {
        gap: 12px;
        display: flex;
        align-items: center;
      }
      p {
        text-align: start;
      }
    }
    .code-applied {
      position: absolute;
      top: 0;
      height: 30px;
      width: 250px;
      background: var(--color-persianblue);
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 15px;
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    width: 100vh;
    margin-bottom: 30px;
    .membership-step-title::first-line {
      font-size: 18px;
    }
    .membership-step-title span {
      font-size: 24px;
      margin: 8px 0;
      display: inline-block;
      letter-spacing: 0.1em;
      font-weight: 700;
      color: var(--color-persianblue);
    }
    .membership-step-title span:last-child {
      font-size: 14px;
      margin: 0;
      line-height: 19px;
      letter-spacing: 0.1em;
      margin-bottom: 8px;
      color: white;
    }
    .membership-step-title {
      .location-maintenance {
        color: white;
        font-size: 14px;
        font-weight: 500;
        width: 600px;
      }
    }

    .promoCodeInputs {
      display: flex;
      gap: 8px;
      button {
        height: 42px;
        width: 120px;
      }
      .form-group {
        max-width: 200px;
      }
      label {
        display: none;
      }
      input {
        color: black;
        padding: 12px;
        font-size: 18px;
        width: 200px;
        height: 42px;
        background-color: white;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }
      .error {
        overflow-wrap: break-word;
      }
    }
    .membership-type-container {
      display: flex;
      border-radius: 5px;
      justify-content: end;
      margin-bottom: 15px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      button {
        color: white;
        background-color: transparent;
        padding: 13px 24px;
        border: white;
        font-size: 12px;
        border: 1px solid #5f5d5d;
      }

      .button:last-child .membership-type-button {
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 4px;
      }
      .button:first-child .membership-type-button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .membership-type-button {
        cursor: pointer;
      }
      .membership-type-button.active {
        background-color: var(--color-persianblue) !important;
      }
    }
  }
}
.membership-list-container {
  .membership-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    > div {
      height: 100%;
      background-color: #1a2027;
      padding: 24px 54px;
      border: 1px solid #5f5d5d;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > .button {
        /* margin: auto auto; */
        display: flex;
        height: 100%;
        margin-top: auto;
        justify-content: center;
        align-items: end;
        > button {
          width: 100%;
        }
        > button.active {
          background-color: var(--color-persianblue);
        }
      }
      > div:first-child {
        p {
          text-align: center;
        }
        > p:first-child {
          text-transform: uppercase;
          font-size: 25px;
        }
        > p:nth-child(2) {
          margin: 14px 0;
          font-size: 35px;
          > span {
            font-size: 15px;
          }
        }
        > .price-sum {
          font-size: 12px;
          color: #797f87;
          padding-bottom: 14px;
          border-bottom: 1px solid #797f87;
        }
        .description {
          padding: 14px 12.5px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 24px;
          > div {
            gap: 12px;
            display: flex;
            align-items: center;
          }
          p {
            text-align: start;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .membership-list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .voucher-card {
    position: relative;
    height: 100%;
    width: 350px;
    background-color: #1a2027;
    padding: 50px 54px 24px 54px;
    border: 1px solid #5f5d5d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .button {
      /* margin: auto auto; */
      display: flex;
      height: 100%;
      margin-top: auto;
      justify-content: center;
      align-items: end;
      > button {
        width: 100%;
      }
      > button.active {
        background-color: var(--color-persianblue);
      }
    }
    .membership-content {
      display: flex;
      flex-direction: column;

      p {
        text-align: center;
      }
      > p:first-child {
        text-transform: uppercase;
        font-size: 25px;
      }
      > p:nth-child(2) {
        margin: 14px 0;
        font-size: 35px;
        > span {
          font-size: 15px;
        }
      }
      .complimentary-header {
        border-bottom: 1px solid #797f87;
        padding-bottom: 14px;
        color: var(--color-persianblue);
      }
    }
    .price-sum {
      font-size: 12px;
      color: #797f87;
      padding-bottom: 14px;
      border-bottom: 1px solid #797f87;
    }
    .description {
      padding: 14px 12.5px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 24px;
      > div {
        gap: 12px;
        display: flex;
        align-items: center;
      }
      p {
        text-align: start;
      }
    }
    .code-applied {
      position: absolute;
      top: 0;
      height: 30px;
      width: 250px;
      background: var(--color-persianblue);
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 15px;
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    .membership-step-title::first-line {
      font-size: 18px;
    }
    .membership-step-title span {
      font-size: 24px;
      margin: 8px 0;
      display: inline-block;
      letter-spacing: 0.1em;
      font-weight: 700;
      color: var(--color-persianblue);
    }
    .membership-step-title span:last-child {
      font-size: 14px;
      margin: 0;
      line-height: 19px;
      letter-spacing: 0.1em;
      margin-bottom: 8px;
      color: white;
    }
    .promoCodeInputs {
      display: flex;
      gap: 8px;
      button {
        height: 42px;
        width: 120px;
      }
      .form-group {
        max-width: 200px;
      }
      label {
        display: none;
      }
      input {
        color: black;
        padding: 12px;
        font-size: 18px;
        width: 200px;
        height: 42px;
        background-color: white;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }
      .error {
        overflow-wrap: break-word;
      }
    }
    .membership-type-container {
      display: flex;
      border-radius: 5px;
      justify-content: end;
      margin-bottom: 15px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      button {
        color: white;
        background-color: transparent;
        padding: 13px 24px;
        border: white;
        font-size: 12px;
        border: 1px solid #5f5d5d;
      }

      .button:last-child .membership-type-button {
        border-top-right-radius: 4px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 4px;
      }
      .button:first-child .membership-type-button {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .membership-type-button {
        cursor: pointer;
      }
      .membership-type-button.active {
        background-color: var(--color-persianblue) !important;
      }
    }
  }
}
