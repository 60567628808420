.report-table-container-all {
  width: 100%;
  .table-container {
    width: 100%;
    overflow-x: scroll;
    scrollbar-color: var(--color-white);
    scrollbar-width: thin;
    border-bottom: var(--color-grey);
    table {
      th,
      td {
        white-space: nowrap;
        width: min-content;
        text-align: center;

        &:nth-child(1) {
          //Name
          padding-left: 18px !important;
          min-width: 266px;
          text-align: left !important;
        }
        &:nth-child(3),
        &:nth-child(4) {
          min-width: calc(156px - 0px);
          text-align: center;
          div {
            justify-content: center;
          }
        }
        &:nth-child(5) {
          //Email
          min-width: 300px;
        }

        &:nth-child(8) {
          text-align: center;
          div {
            justify-content: center;
          }
        }
        &:nth-child(10),
        &:nth-child(11),
        &:nth-child(12),
        &:nth-child(13),
        &:nth-child(15),
        &:nth-child(16),
        &:nth-child(17),
        &:nth-child(18),
        &:nth-child(19),
        &:nth-child(20) {
          min-width: 150px;
          div {
            white-space: normal;
          }
        }
        &:nth-child(14) {
          //Total Direct Debits
          min-width: 180px;
        }
      }
      thead {
        tr {
          &:hover {
            background: unset;
          }
        }
      }
      th {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      tfoot {
        border-top: 2px solid transparent;
        font-size: 12px;

        tr {
          &:hover {
            background: unset;
          }

          th {
            &:nth-child(3) {
              position: unset !important;
            }
          }
        }
      }
    }
  }
  .table-container::-webkit-scrollbar-thumb {
    background-color: var(--color-white);
    height: 0.25rem;
  }
  .table-container::-webkit-scrollbar {
    width: 5px;
    height: 0.5rem;
  }
  .total {
    width: 100%;
    padding-left: 80%;
    border-bottom: var(--color-grey);
  }
}
.no-scroll-bar {
  .table-container {
    scrollbar-width: none !important;
  }
}
.action-buttons {
  display: flex;
  align-items: flex-end;
  gap: 30px;
}
.member-containers.reports {
  .action-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
  }
  .totalCount {
    .loading {
      display: inline !important;
    }
  }
  .loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    margin: 10px 0px;
  }
  .member-info {
    align-items: flex-end;
    justify-content: space-between;
    .toggle {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-bottom: 0;
    }
    .actions-row {
      gap: 40px;
      .flex-row.actions {
        align-items: flex-end;
        gap: 20px;
      }
      .btn-group,
      .search-container {
        margin: 0;
        align-self: flex-end;
      }
    }
  }
}
table {
  tr:has(details) {
    position: relative;
  }
  tr:has(details)::after {
    content: "+";
    top: 50%;
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
  }
  tr:last-child:has(details)::after {
    content: "+";
    position: absolute;
    left: 0;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
  }
}
.report {
  .loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    margin: 10px 0px;
  }
}
