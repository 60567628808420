.add-info-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 14px;
  margin-top: 30px;

  .details-container {
    display: flex;
    flex-direction: row;
    gap: 0px 18px;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    h3 {
      text-transform: uppercase;
      padding-bottom: 5px;
    }

    .address-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 18px;
      grid-auto-flow: row;
      grid-template-areas:
        "address suburb city  "
        "state post country";

      .address {
        grid-area: address;
      }

      .state {
        grid-area: state;
      }

      .city {
        grid-area: city;
      }

      .country {
        grid-area: country;
      }

      .post {
        grid-area: post;
      }

      .suburb {
        grid-area: suburb;
      }
    }

    &.loc-card {
      width: 100%;
      height: max-content;
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;
      padding: 23px 22px 20px 16px;

      h3 {
        margin-bottom: 10px;
      }

      .toggle {
        margin-top: 10px;
      }

      textarea {
        margin-bottom: 10px;
      }
      .swipe-title,
      .error-title {
        color: var(--color-gray-100);
      }
    }

    .detail {
      display: flex;
      // flex: 1;
      flex-direction: column;

      &.loc {
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        flex: 1;

        div {
          flex: 1;
          p {
            padding-bottom: 2px;
            border-bottom: 1px solid var(--color-gray-100);
          }
        }
        // .form-group {
        //   flex: 2;
        // }
      }
      h5 {
        color: var(--color-gray-100);
        margin-bottom: 5px;
      }
      p {
        text-transform: capitalize;
        // padding-bottom: 5px;
      }
    }
  }
}
