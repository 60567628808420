.add-membership-page.edit {
  display: flex;
  flex-direction: column;
  height: fit-content;

  .edit-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: min-content;
    gap: 18px 35px;
    grid-auto-flow: row;
    grid-template-areas:
      "name . . . . ."
      "activation-title activation-title . . . ."
      "date-range date-range date-range status status status";

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "name . . . . "
        "activation-title activation-title . . . "
        "date-range date-range date-range . . "
        "status status . . . ";
    }
  }

  .name {
    grid-area: name;
    h6 {
      color: var(--color-gray-100);
      padding-bottom: 8px;
    }

    p {
      white-space: nowrap;
    }
  }

  .activation-title {
    grid-area: activation-title;
  }

  .date-range {
    display: flex;
    width: 100%;
    gap: 20px;
    grid-area: date-range;

    span {
      flex: 1;
      position: relative;
    }

    div {
      flex: 1;
    }
  }

  .status {
    display: flex;
    grid-area: status;
    width: fit-content;
    justify-content: flex-end;
    gap: 8px;
    position: relative;

    p,
    .toggle {
      position: absolute;
      top: 18px;
    }

    p {
      white-space: nowrap;
      left: 58px;
      align-self: center;
      padding-bottom: 6px;
      @media screen and (max-width: 900px) {
        position: unset;
      }
    }
    .toggle {
      left: 20px;
      align-self: center;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: 900px) {
        position: unset;
      }
    }
  }
}
