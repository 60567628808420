.add-class-container {
  .add-btn-container {
    margin-left: auto;
    align-self: flex-start;
    margin-bottom: 10px;
    display: flex;
    gap: 0px 12px;
  }

  .back-mediumgray {
    background-color: var(--color-gray-200);
  }
  .back-persianblue {
    background-color: var(--color-persianblue);
  }
}

.content-container.add-class-main,
.content-container.class-subdetails {
  width: 100%;
  height: max-content;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  display: flex;
  gap: 30px;
  flex-direction: column;

  .select-element {
    margin-top: 0;
    padding-bottom: 5px;
  }

  .inputs {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .first {
        display: flex;
        gap: 24px;
        justify-content: space-between;

        .detail {
          &:nth-child(1) {
            width: 50%;
            // max-width: 393px;
          }
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5) {
            width: 20%;
            // max-width: 150px;
          }
        }
      }

      .toggles {
        display: flex;
        gap: 0px 32px;
        width: min-content;
        justify-content: space-between;

        .detail {
          max-width: unset !important;
          width: max-content !important;
          align-self: flex-end;
        }
      }
    }
  }

  .right-container {
    display: flex;
    flex-direction: column;
    gap: 32px 18px;
    width: 100%;

    .subdetails {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      div {
        flex: 1;
      }
      .bookable-row {
        display: flex;
        flex-direction: row;
        gap: 32px;
        .detail {
          align-self: flex-end;
        }
      }
    }

    .selectors {
      display: flex;
      gap: 0px 20px;
      @media screen and (max-width: 1280px) {
        gap: 24px 20px;
      }

      .select-element {
        width: 100%;
      }
    }
  }
}

.content-container.class-subdetails {
  flex-direction: row;
}

.top-bar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 26px;

  .subdetails {
    display: flex;
    gap: 0px 200px;

    p {
      color: var(--color-gray-400);
    }
  }

  h3 {
    text-transform: uppercase;
  }
}
