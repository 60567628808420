.edit-instructor-elements {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title-bar {
    padding-bottom: 0;
  }
  .btn-group {
    margin-top: 0;
  }
  .instructor-personal-container {
    .personal-info-details {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 18px;
      @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .instructor-contact-container {
    .title-bar {
      padding-bottom: 0;
    }
    .instructor-contact-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 18px;
      @media screen and (max-width: 450px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .instructor-address-container {
    .instructor-address-details {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: fit-content;
      gap: 18px;
      grid-template-areas:
        "address address address address "
        "suburb state country post";

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "address address"
          "suburb state "
          "country post";
      }
      @media screen and (max-width: 450px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "address"
          "suburb"
          "state"
          "country"
          "post";
      }

      .address {
        grid-area: address;
      }
      .suburb {
        grid-area: suburb;
      }
      .state {
        grid-area: state;
      }
      .country {
        grid-area: country;
      }
      .post {
        grid-area: post;
      }
    }
  }

  .instructor-description-container {
    #instructor-description-form {
      flex-direction: column;
    }
  }

  .credential-total-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    .upload-container {
      display: flex;
      flex-direction: column;

      .upload-item {
        display: flex;
        justify-content: space-between;
        gap: 32px;
        width: 100%;

        button {
          align-self: center;
        }
        .selector {
          flex: 1;
          display: flex;
          select {
            option {
              width: 100%;
            }
          }
        }
      
      }
    }
  }

  .info-card.credential-view {
    display: flex;
    flex-direction: column;
    td,
    th {
      width: 40%;
      &:nth-child(3) {
        width: 20%;
        div {
          gap: 18px;
          justify-content: flex-end;
        }
      }
    }
  }
}
