.right-btn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .btn-disabled {
    &:disabled {
      background-color: var(--color-gray-100);
      cursor: not-allowed;
    }
  }
}
.btn-container {
  display: flex;
}
