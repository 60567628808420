.settlement-report-tab {
  .loading {
    min-height: 20px;
  }
  .downloadTd {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .disabled {
    color: #5f5c5c;
  }
}
