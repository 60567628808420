.membership-container.location-memberships {
  .member-changes-modal.location-memberships {
    align-items: center;
    .input-container {
      gap: 5px;
      align-items: center;
    }
  }
  .flex-row {
    gap: 8px;
    display: flex;
    flex-direction: row;
  }
}
