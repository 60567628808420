.entry-card-container {
  display: flex;
  gap: 0px 20px;
  border: 1px solid var(--color-gray-400);
  padding: 23px 21px 34px 16px;
  border-radius: 5px;
  width: 100%;
  min-height: 273px;

  .title-bar {
    background: red;
  }

  //Left Column content (actions)
  .swipe-actions {
    display: flex;
    flex-direction: column;
    gap: 23px;
    flex: 20%;

    h3 {
      text-transform: uppercase;
    }

    .frequency {
      h3,
      p {
        text-transform: capitalize;
      }

      h3 {
        padding-bottom: 4px;
        color: var(--color-gray-400);
      }

      p {
        padding-bottom: 4px;
        border-bottom: 1px solid var(--color-gray-400);
      }
    }

    div:last-child {
      margin-top: 8px;

      button {
        width: 10%;
        min-width: 126px;
      }
    }
  }

  .swipe-messages {
    display: flex;
    flex-direction: column;
    flex: 55%;
    gap: 9px;

    .message-text,
    .error-text {
      h3 {
        padding-bottom: 9px;
        color: var(--color-gray-400);
      }

      textarea {
        height: max-content;
        min-height: 102px;
        width: 100%;
        color: white;
        border: 1px solid var(--color-gray-400);
        border-radius: 5px;
        padding: 17px 18px 15px 15px;
        background: transparent;
        overflow: auto;
        outline: none;
        resize: none;

        &:active {
          overflow: auto;
        }
      }
    }

    .error-text {
      textarea {
        min-height: 50px;
        height: min-content;
        display: block;
        margin-top: auto;
        color: var(--color-gray-400);
      }
    }
  }
}

.access-comms-table {
  margin-bottom: 20px;

  .table {
    margin-top: 30px;

    table {
      border-collapse: collapse;
      width: 100%;

      th {
        background: transparent;
      }

      td {
        padding: 9px;
      }

      td,
      th {
        &:nth-child(1) {
          padding-top: 0;
          padding-bottom: 0;
          width: 20%;
          text-align: center;
          padding-left: 0;
        }

        &:nth-child(3) {
          width: 15%;
          text-transform: capitalize;
        }
      }
    }

    th {
      padding: 8px;
      color: var(--color-gray-100);
      text-align: left;
      border-bottom: 2px solid var(--color-gray-100);
      text-transform: uppercase;

      //header for class table date/time signature
      &.date-header {
        background: var(--color-gray-300);
        color: var(--color-white);
        text-transform: uppercase;
      }

      &:nth-child(2) {
        position: relative;
      }
    }

    .main-rows {
      .add-time-row {
        padding-left: 0;
      }

      .add-time-col {
        .button-td {
          gap: 6px;

          p {
            align-self: center;
          }
        }
      }

      .msg {
        width: 20%;

        .button {
          display: flex;
          justify-content: center;
        }

        p {
          text-align: center;
          text-wrap: wrap;
          padding-bottom: 10px;
        }
      }

      .time-table {
        max-width: 100%;
        padding-top: 0px;
        padding-bottom: 0px;

        tr {
          td {
            &.day-cell {
              width: 10%;
              padding-right: 45px;
            }

            &:nth-child(1) {
            }

            &:nth-child(2) {
              width: 20%;
              padding-right: 10px;
            }

            &:nth-child(3) {
              // width: 20%;
              white-space: nowrap;
            }
          }
        }

        tr:last-of-type {
          td {
            border-bottom: none;
          }
        }

        .day {
          width: 52px;
          height: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
        }

        .bg-blue {
          background-color: var(--color-persianblue);
          color: var(--color-white);
        }

        .bg-gray {
          background-color: var(--color-gray-200);
          color: var(--color-gray-400);
        }

        .time-period {
          td {
            padding-top: 4px;
            padding-left: 0px;
            padding-bottom: 4px;
            text-align: center;
            border-bottom: none;

            &:nth-child(1),
            &:nth-child(2) {
              min-width: 109px;
              max-width: 109px;
            }

            .time-period-value {
              padding: 2px 15px 2px 15px;
              border: 1px solid var(--color-gray-100);
              border-radius: 5px;
              min-width: 109px;
              max-width: 109px;
            }
          }
        }
      }

      .btn-col {
        padding-top: 0;
        width: 20%;
        text-align: center;
      }
    }

    .message-tr {
      td {
        form {
          div {
            label {
              text-align: left;
            }

            .swipe-frequency-container {
              display: flex;
              gap: 40px;

              .form-group,
              div {
                flex: 1;
              }

              div {
                width: fit-content;

                label {
                  text-transform: capitalize;
                  color: var(--color-gray-100);
                }
              }
            }
          }
        }
      }
    }

    td {
      color: var(--color-white);
      text-align: left;
      border-bottom: 1px solid var(--color-gray-100);
      // width: 25%;

      &.center {
        white-space: nowrap;

        &.icons {
          img {
            margin-right: 2px;
            width: 30px;
            height: 15px;
          }
        }
      }
    }

    td,
    th {
      padding-left: 20px;
    }

    .table-header {
      &:hover {
        background: none;
      }
    }

    tr:hover {
      background-color: var(--color-gray-300);
    }

    .right {
      width: 100%;
      // display: flex;
      column-gap: 10px;
      margin: 0;
      padding-right: 0;
      text-align: right;
      padding-left: 0.635rem;
      // justify-content: flex-end;
    }
  }
}

.save-btn {
  margin-bottom: 50px;
}

@media screen and (max-width: 1400px) {
  .time-table {
    table {
      tbody {
        tr {
          td {
            table {
              border-collapse: separate !important;
              border-spacing: 17px 0px;
            }

            .add-time-row {
              padding-left: 17px !important;
            }
          }
        }
      }
    }
  }
}
