.user-details-form {
  border: 1px solid #797f87;
  padding: 24px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  > p {
    color: white;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 10%;
    padding-bottom: 14px;
    border-bottom: 1px solid;
    margin-bottom: 14px;
  }
  .user-details-form-inputs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px;
    .flex-col .select-element-value {
      border-bottom: 1px solid var(--color-gray-100);
    }
  }
}
.button-navigate-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 24px;
}
.align-right {
  justify-content: right;
}
.form-2 {
  margin-top: 24px;
}
