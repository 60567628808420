.class-schedule-message-form {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  margin-bottom: 20px;

  .title-bar {
    span {
      font-size: 10px;
    }
  }

  .image-inputs-container {
    display: flex;
    width: 100%;
    gap: 32px 18px;

    .inputs-container-edit-class {
      display: flex;
      gap: 32px 24px;
      flex-direction: column;
      width: 100%;
    }
  }

  .location-selectors,
  .location-inputs {
    display: flex;
    justify-content: space-between;
    gap: 12px 26px;
    flex-wrap: wrap;

    .select-element,
    div {
      flex: 1;
    }
  }

  .location-note {
    p {
      padding-bottom: 10px;
      color: var(--color-gray-100);
    }
  }

  .bookable-row {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
}
.img-container {
  position: relative;
  // width: 100%;
  // height: 100%;
  // max-width: 214px;
  // min-height: 216px;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100%;
    min-height: 216px;
    object-fit: cover;
    border-radius: 5px;
    outline: none;
    border: none;
    background: var(--color-gray-300);
  }
  .btn {
    position: absolute;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: #3f3f3f;
    color: white;
    font-size: 12px;
    padding: 9px 12px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    white-space: nowrap;
    transition: all 0.2s linear;

    &:hover {
      background-color: var(--color-gray-300);
      transition: all 0.2s linear;
    }
  }
}
