.outer-container {
  display: flex;
  position: relative;
  z-index: 1000;
  flex-direction: column;
  height: 100%;
  width: 255px;
  padding: 14px 20px;
  background: #364252;
  // outline: 1px solid var(--color-gray-200);
  font-size: 1.2rem;
  margin-bottom: 4px;
  gap: 10px;
  max-height: 300px;
  overflow-y: scroll;
  .top-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 9px;
    justify-content: space-between;

    .icon {
      height: 16px;
    }
  }

  .notification-header {
    font-weight: 700;
    letter-spacing: 2px;
  }
  .make-read {
    text-align: end;
    cursor: pointer;
    text-decoration: underline;
  }
  .notifications-container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    .single-container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding-left: 5px;

      .title {
        text-transform: uppercase;
        font-weight: lighter;
        color: var(--color-white);
        margin-bottom: 5px;
      }

      .subtitle {
        font-weight: 100;
        color: rgba(255, 255, 255, 0.7);
      }

      .name {
        padding-top: 5px;
        text-transform: capitalize;
        font-weight: lighter;
        color: var(--color-white);
      }

      &.green-border {
        border-left: 4px solid var(--color-green);
      }
      &.red-border {
        border-left: 4px solid var(--color-mediumred);
      }
      &.blue-border {
        border-left: 4px solid var(--color-persianblue-dark);
      }
    }
    .empty-noti {
      color: var(--color-gray-100);
    }
  }
  .load-more {
    text-decoration: underline;
    color: var(--color-gray-100);
    cursor: pointer;
    display: flex;
    justify-content: end;
  }
}
