.send-sms-container {
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  height: fit-content;
  width: fit-content;
  min-width: 673px;

  h3 {
    line-height: 0.85em;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .member-details {
      display: flex;
      gap: 24px;
      div {
        flex: 1;
      }
    }
    .entry-btn-group {
      display: flex;
      gap: 14px;
    }
  }
  .note {
    margin-top: 10px;
    font-size: 14px;
  }
}

// Old styles. Keeping just in case

// display: flex;
// flex-direction: column;
// position: fixed;
// left: 60%;
// top: 50%;
// transform: translate(-50%, -50%);
// z-index: 200;
// max-width: 682px;
// height: max-content;
// max-height: 570px;
// background: var( --color-gray-300);
// border: 1px solid var( --color-gray-400);
// border-radius: 8px;
// padding: 21px 34px 43px 29px;

// .entry-info-container {
//   display: flex;
//   flex-direction: column;

//   .entry-title {
//     padding-bottom: 10px;
//     h3 {
//     }
//   }

//   .entry-info {
//     display: grid;
//     padding-bottom: 30px;
//     grid-auto-columns: 1fr;
//     grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
//     grid-template-rows: 1fr;
//     gap: 0px 20px;
//     grid-template-areas: "date date location location location";
//   }

//   .date {
//     grid-area: date;
//     //   border-bottom: 1px solid red;
//   }
//   .location {
//     grid-area: location;
//     //   border-bottom: 1px solid red;
//   }
// }

// .guest-info-container {
//   padding-bottom: 20px;
//   .guest-title {
//     padding-bottom: 4px;
//     h3 {
//       text-transform: uppercase;
//     }
//   }

//   .guest-details {
//     display: grid;
//     grid-auto-columns: 1fr;
//     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
//     grid-template-rows: 1fr;
//     gap: 0px 16px;
//     grid-template-areas: "fn fn ln ln ln mobile mobile";
//   }
//   .fn {
//     grid-area: fn;
//   }
//   .ln {
//     grid-area: ln;
//   }
//   .mobile {
//     grid-area: mobile;
//   }
// }

// .reason-container {
//   display: flex;
//   gap: 20px;
//   flex-direction: row;

//   .reason-message {
//     display: flex;
//     flex-direction: column;
//     flex: 70%;

//     textarea {
//       border-style: none;
//       overflow: auto;
//       outline: none;
//       resize: none;

//       &:active {
//         border-style: none;
//         border-color: red;
//         overflow: auto;
//       }
//     }

//     .reason-title {
//       text-transform: uppercase;
//       padding-bottom: 10px;
//       margin-left: 6px;
//       color: var( --color-gray-400);
//     }

//     .reason-box {
//       width: 100%;
//       height: 100%;
//       border-radius: 5px;
//       padding: 10px 8px;
//       background-color: transparent;
//       color: var(--color-white);
//       border: 1px solid var( --color-gray-400);
//     }
//   }

//   .entry-view {
//     display: flex;
//     flex-direction: column;
//     flex: 30%;

//     .reason-title {
//       text-transform: uppercase;
//       padding-bottom: 10px;
//       margin-left: 6px;
//       color: var( --color-gray-400);
//     }

//     img {
//       border: 1px solid var( --color-gray-400);
//       border-radius: 5px;
//       transition: all 0.2s linear;

//       &:hover {
//         transform: scale(2.2) translateX(-25%);
//         transition: all 0.2s linear;
//       }
//     }
//   }
// }
