.protected-route-container {
  .protected-route-content {
    display: flex;
    background: var(--color-gray-300);
    border-radius: 5px;
    gap: 24px;
    border: 1px solid var(--color-gray-100);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 80%;
    }
  }
}
