.membership-view-page {
  // display: flex;
  // flex-direction: column;
  // gap: 20px;
  margin-bottom: 50px;

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h6 {
      color: var(--color-gray-100);
      padding-bottom: 8px;
      text-transform: capitalize;
    }

    .detail-card {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      height: min-content;
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;

      .row-data {
        display: flex;
        gap: 80px;
        width: 100%;

        div {
          flex: 1;
        }
      }

      &.first {
        .desc-row {
          height: fit-content;
          width: 100%;

          h3 {
            padding-bottom: 10px;
            color: var(--color-gray-400);
          }

          textarea {
            border: 0px !important;
            min-height: 50px;
            height: fit-content;
            padding-left: 2px;
            padding: 0;
            max-height: fit-content;
          }
        }
      }

      &.sec {
        .details-grid {
          padding-bottom: 10px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: min-content;
          gap: 50px 80px;
          grid-auto-flow: row;
          // grid-template-areas:
          //   "freq delay length notice-period"
          //   "fee-1 fee-2 fee-3 type";

          @media screen and (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
          }

          p {
            text-transform: lowercase;
          }
        }

        // .freq {
        //   grid-area: freq;
        // }

        // .delay {
        //   grid-area: delay;
        // }

        // .length {
        //   grid-area: length;
        // }

        // .notice-period {
        //   grid-area: notice-period;
        // }

        // .type {
        //   grid-area: type;
        // }

        // .fee-1 {
        //   grid-area: fee-1;
        // }

        // .fee-2 {
        //   grid-area: fee-2;
        // }

        // .fee-3 {
        //   grid-area: fee-3;
        // }
      }

      &.third {
        gap: 24px;
        table {
          border-collapse: separate;
          border-spacing: 0 4px;
          td,
          th {
            text-align: left;
          }

          th {
            color: var(--color-gray-400);
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
