.maintenance {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  padding-top: 20px;
  padding-bottom: 50px;
}

.maintenance .maintenance__header {
  display: flex;
  justify-content: end;
}

.maintenance .maintenance__content {
  overflow-y: auto;
  .maintenance-options {
    background-color: #6d717a;
    position: absolute;
    width: 250px;
    right: 0px;
    border-radius: 2px;
  }
  max-height: 450px;
  .content {
    .delete {
      cursor: pointer;

      border: none;
      outline: none;

      color: white;
      background-color: transparent;
    }
  }
  .table {
    width: 100%;

    border-collapse: collapse;
    tr {
      border-top: 1px solid var(--color-gray-400);
      border-bottom: 1px solid var(--color-gray-400);
    }
    td {
      text-align: left;
      border: none;
    }
  }
}

.add-maintenance form {
  .date-range {
    display: flex;
    justify-content: space-between;
    column-gap: 60px;
    .date-control {
      display: flex;
      flex-direction: column;

      .label {
        margin-bottom: 5px;

        text-align: left;
      }

      .control {
        border: none;
        border-bottom: 1px solid var(--color-gray-100);

        border-radius: 0;
      }
    }
    .start-date {
      h6 {
        margin-bottom: 14px;
      }
    }
  }

  .overlapped-wrapper {
    width: 100%;

    .title {
      margin-bottom: 5px;

      text-align: left;
    }

    .overlapped-with {
      overflow-y: auto;

      max-height: 100px;

      .overlapped {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;

        margin-top: 4px;
        margin-bottom: 4px;

        padding: 4px;

        border-bottom: 1px solid var(--color-gray-100);

        .overlapped-date-range {
          display: flex;
          justify-content: flex-end;
          column-gap: 60px;

          width: 100%;

          padding-right: 10px;

          p {
            width: 30%;
            text-align: right;
          }
        }
      }
    }
  }
}

.loader-holder {
  display: flex;
  justify-content: center;

  height: 50vh;

  .loader {
    padding: 32px;
    width: fit-content;
    height: fit-content;
  }
}
