.voucher-container {
  .add-info-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding-bottom: 14px;
    margin-top: 30px;
    .voucher-details-container {
      display: flex;
      flex-direction: row;
      gap: 0px 18px;
      flex-direction: column;
      width: 100%;
      h3 {
        text-transform: uppercase;
        padding-bottom: 5px;
      }

      .address-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media screen and (max-width: 920px) {
          grid-template-columns: repeat(2, 49%);
          grid-template-rows: 1fr 1fr 1fr;
        }
        grid-template-rows: 1fr 1fr;
        gap: 18px;
        grid-auto-flow: row;
      }

      &.loc-card {
        width: 100%;
        height: max-content;
        border: 1px solid var(--color-gray-400);
        border-radius: 5px;
        padding: 23px 22px 20px 16px;

        h3 {
          margin-bottom: 10px;
        }

        .toggle {
          margin-top: 10px;
        }

        textarea {
          margin-bottom: 10px;
        }
        .swipe-title,
        .error-title {
          color: var(--color-gray-100);
        }
      }

      .detail {
        display: flex;
        // flex: 1;
        flex-direction: column;

        &.loc {
          flex-direction: row;
          justify-content: flex-start;
          gap: 20px;
          flex: 1;

          div {
            flex: 1;
            p {
              padding-bottom: 2px;
              border-bottom: 1px solid var(--color-gray-100);
            }
            @media screen and (max-width: 1100px) {
              flex: 48%;
            }
          }
          // .form-group {
          //   flex: 2;
          // }
        }
        h5 {
          color: var(--color-gray-100);
          margin-bottom: 5px;
        }
        p {
          text-transform: capitalize;
          // padding-bottom: 5px;
        }
      }
      .detail {
        gap: 6px;
        display: flex;
        flex: 25%;
        flex-direction: column;
        height: fit-content;

        &.full-width {
          width: 100%;
          flex: 100%;
          margin-right: calc(50% + 10px);
        }

        span {
          margin-right: 5px;
        }

        p {
          text-transform: uppercase;
        }
      }
    }
  }
  .loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    margin: 10px 0px;
  }

  .tabs-list {
    display: flex;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 12px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    overflow-x: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px var(--color-gray-300);
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--color-gray-300);
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--color-gray-300);
    }

    &.button-type {
      border-bottom: 0;

      .tab-head {
        border-radius: 5px;
        height: 40px;
        width: fit-content;
        padding: 0 20px;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background: var(--color-gray-300);
        }
      }

      .back-transparent {
        background: transparent;
        border: 1px solid var(--color-gray-100);
      }
    }

    .tab-head {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      width: 180px;
      height: 45px;
      color: var(--color-gray-100);
      letter-spacing: 0.1em;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid var(--color-gray-200);
      padding: 0 20px;
      text-align: center;
      transition: all 0.2s linear;
      &:last-child {
        margin-right: 0;
      }

      @media screen and (max-width: 1300px) {
        font-size: 10px !important;
      }

      // @media screen and (max-width: 1100px) {
      //   font-size: 8px !important;
      // }

      // @media screen and (max-width: 800px) {
      //   font-size: 5px !important;
      // }

      &:hover {
        border-color: var(--color-gray-100);
        background-color: var(--color-gray-200);
        cursor: pointer;
        transition: all 0.2s linear;
        color: var(--color-white);
      }
    }

    .back-mediumgray {
      background-color: var(--color-black);
    }

    .back-persianblue {
      border-color: var(--color-gray-100);
      color: var(--color-white);
      background-color: var(--color-gray-200);
    }
  }
  .tooltip {
    position: relative;
    display: inline-block;
  }

  .tooltip .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    width: 95%;
    height: 100%;
    position: absolute;
    z-index: 1;
    bottom: 0%;
    left: 48%;
    transform: translateX(-50%);
    opacity: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: opacity 0.3s;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  .voucher-table {
    .table {
      table {
        td,
        th {
          &:nth-child(2) {
            div {
              justify-content: left;
            }
          }
          &:nth-child(8) {
            text-align: center;
          }
          &:nth-child(9) {
            text-align: center;
          }
        }
        td {
          &:nth-child(9) {
            text-align: right;
          }
        }
      }
    }
  }
}
.voucher-price {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  padding-bottom: 0px !important;
  font-size: 13px;
}
.message-box {
  width: 100%;
  .label {
    text-align: start;
  }
}
.destination {
  position: relative;
  .label {
    display: flex;
    align-items: start;
    margin-bottom: 8px;
  }
  .search-icon {
    position: absolute;
    right: 0;
    top: -3px;
    background: transparent;
    color: var(--color-white-opaque);
  }
}
.member-type-voucher {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  label {
    display: flex;
    align-items: center;
  }
}
