.instructors-container {
  display: flex;
  flex-direction: column;
  .left-elements {
    @media screen and (max-width: 1100px) {
      flex-direction: column;
      gap: 24px;
    }
  }
  .instructors-table {
    height: 50vh;
    width: 100%;

    table {
      td,
      th {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 15%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 20%;
        }
      }
    }
  }
}
