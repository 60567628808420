.entry-log-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  form {
    align-self: flex-end;
  }

  table {
    thead {
      font-size: 12px;
      white-space: nowrap;
    }

    tbody {
      tr {
        height: max-content;

        td {
          &:nth-child(1),
          &:nth-child(5) {
            width: 15%;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 12%;
          }

          &:nth-child(6) {
            width: 24%;
          }

          &:nth-child(7) {
            width: 10%;
            text-align: center;
          }
        }
      }
    }
  }
}

// Access Logs Landing Page Styling below //

.access-logs-container {
  .log-type-button-container {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .log-card {
      width: 100%;
      height: max-content;
      padding: 32px 32px 28px 32px;
      border: 2px solid var(--gray-4);
      border-radius: 5px;
      &:hover {
        background: var(--gray-4);
        transition: all 0.2s linear;
      }
    }
  }
}

// Member Access Logs Styling Below //
.member-access-logs-container {
  .logs-filter-container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .first-row-settings {
      display: flex;
      gap: 18px;

      .option-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        label {
          margin-bottom: 5px;
        }

        input {
          background-color: transparent;
          border: 0;
          outline: 0;
          padding-bottom: 2px;
          font-family: "gotham";
          border-bottom: 1px solid var(--color-gray-100);
          color: var(--color-white);
          transition: all 0.2s linear;
        }
      }
    }

    .sec-row-settings {
      display: flex;
      gap: 94px;

      @media screen and (max-width: 1120px) {
        flex-direction: column;
      }

      .date-selectors {
        display: flex;
        gap: 12px;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        h3 {
          color: var(--color-gray-100);
        }
      }

      .checkboxes-container {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        gap: 10px;
        // justify-content: space-between;
        .failed {
          margin-left: 20px;
        }
        .checkbox {
          display: flex;
          flex-direction: column-reverse;
          align-items: center;
          gap: 8px;

          label {
            color: var(--color-gray-100);
          }
        }
      }
    }
  }

  .loader {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .member-access-logs-table {
    .table {
      margin-top: 18px;

      table {
        overflow: auto;

        th,
        td {
          &:nth-child(1) {
            width: 18%;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 15%;
            text-align: center;
          }

          &:nth-child(4),
          &:nth-child(6) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 5%;
            text-align: center;
          }

          &:nth-child(7) {
            width: 7%;
          }

          &:nth-child(9) {
            width: 10%;
            .location-cell {
              display: flex;
              flex-direction: column;
              span {
                white-space: nowrap;
              }
              .orange-text {
                color: orange;
              }
            }
          }
        }
      }
    }
  }
  .refresh-btn {
    margin-left: 16px;
  }
}
