.filter {
  display: flex;
  flex-direction: column;
  width: 110%;
  height: 100%;
  border: 1px solid var(--color-gray-200);
  border-radius: 3px;
  padding: 13px 0 23px 23px;
  background-color: var(--color-gray-300);

  div {
    flex: 1;
  }

  .first-row {
    margin-bottom: 20px;

    div {
      text-transform: capitalize;
    }
  }

  .mid-row {
    :first-child {
      text-transform: capitalize;
    }
  }

  .btn-group {
    margin-top: 20px;
    width: 50%;
    display: flex;
    justify-content: flex-start;

    .btn {
      width: 70px;
      padding: 4px 0px;
      margin-right: 10px;
    }
  }
}
