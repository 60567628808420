.welcome {
  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 5px;
  }
  .user-info {
    display: flex;
    .membership-info {
      margin: 0 5px;
      .title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .start-date {
        font-size: 14px;
        color: var(--color-gray-100);
      }
    }
  }
}
.view-btn {
  margin: 15px 0;
}
.step-container {
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid var(--color-gray-100);
  .step-name {
    h1 {
      margin-right: 10px;
      color: var(--color-persianblue);
    }
    p {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 2px;
    }
    display: flex;
    align-items: center;
  }
  .step-detail {
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  .step-2-detail {
    margin-top: 15px;
    p {
      font-size: 15px;
    }
    .logo {
      display: flex;
      justify-content: end;
      align-items: center;
      img {
        width: auto;
        cursor: pointer;
      }
    }
  }
}
