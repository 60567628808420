.interaction-container {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  transition: all 0.2s linear;
  flex: 30%;
  // max-width: 306px;
  // max-height: 231px;

  // &:hover {
  //   background: var(--color-gray-300);
  //   transition: all 0.2s linear;
  // }

  .int-title {
    display: flex;
    gap: 6px;
    flex-direction: row;
    text-transform: uppercase;
    .days {
      align-self: flex-end;
    }
  }
  .interactions-info {
    .interaction-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 2px;
      margin-bottom: 8px;
      border-bottom: 1px solid var(--color-gray-300);
    }

    .category {
      color: var(--color-gray-100);
      text-transform: uppercase;
      font-weight: 700;
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-container {
    padding-top: 16px;
    button {
      font-size: 10px;
      padding: 8px 18px;
    }
  }
}
