.block-popup {
  display: flex;
  flex-direction: column;
  // position: fixed;
  // left: 60%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  z-index: 200;
  // width: 518px;
  width: max-content;
  height: fit-content;
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-400);
  border-radius: 8px;

  .datepicker-container {
    input {
      border-radius: 0;
    }
  }

  p {
    letter-spacing: 0.1em;
  }

  .block-title {
    text-align: center;
  }

  .block-subtitle {
    text-align: center;
  }

  .block-options-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    p {
      color: var(--color-gray-100);
    }
    .charge-options-container {
      display: flex;
      gap: 18px;
      label {
        font-size: 12px;
      }
    }

    .row-two-container {
      display: flex;
      gap: 32px;
      justify-content: space-between;
      flex-direction: row;

      .date-select {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        span {
          width: 100%;
          input {
            border: none;
            width: 100%;
            padding-bottom: 2px;
            border-bottom: 1px solid var(--color-gray-100);
          }
          input[type="date"]::-webkit-datetime-edit-year-field {
            color: var(--color-white);
          }
        }
      }

      .drop-down {
        width: 100%;
        .select-element {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          div {
            width: 100%;
          }
        }
      }
    }
  }

  .block-reason-container {
    p {
      text-transform: none;
      opacity: 0.6;
      padding-bottom: 8px;
    }

    .text-box {
      padding: 15px;
      width: 100%;
      height: 125px;
      border-radius: 5px;
      border: 1px solid var(--color-gray-200);
      margin-bottom: 10px;
    }
  }

  .block-btn-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
  }
  .err-text {
    color: var(--color-mediumred);
    margin-bottom: 1rem;
  }
  .radio-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .row-two-container {
    display: flex;
    flex-direction: row;
  }
}

.datepicker-container .text-white {
  color: var(--color-white);
}
