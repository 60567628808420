//bank details in Location
.bank-details-container.sec-card.loc-card {
  .detail {
    display: flex;
    flex-direction: column;
    gap: 18px;
    div {
      width: 100%;
    }
  }
}

.details-first-row,
.details-second-row,
.details-third-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
