.contact-container {
  border: 2px solid var(--gray-4);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s linear;
  flex: 70%;
  height: auto;

  .abs-error {
    .form-group1 {
      position: relative;
      .error {
        margin-top: 2px;
        position: absolute;
        top: 36px;
      }
    }
  }

  .select-element {
    label {
      margin-bottom: 5px;
    }
  }

  // &:hover {
  //   background: var(--color-gray-300);
  //   transition: all 0.2s linear;
  // }

  .contact-info-container {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 20px 12px;
    grid-template-areas:
      "email email email email email email  email-sec email-sec email-sec email-sec email-sec email-sec"
      "mobile mobile mobile mobile work-phone work-phone work-phone  work-phone home-phone home-phone  home-phone home-phone"
      "address address address address address suburb suburb suburb state state post post";
    span {
      margin-left: auto;
    }
    .email {
      grid-area: email;
    }
    .email-sec {
      grid-area: email-sec;
    }
    .mobile {
      grid-area: mobile;
      .hidden-title {
        margin-bottom: 5px;
        color: var(--color-gray-100);
      }
      .hidden-value {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
        border-bottom: 1px solid var(--color-gray-100);
        margin-bottom: 5px;
      }
      .input-value-gray {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid var(--color-gray-100);
      }
      .input-value-persianblue {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid var(--color-gray-100);
      }
    }
    .work-phone {
      grid-area: work-phone;
      // .hidden-title {
      //   color: var( --color-gray-100);
      //   margin-top: 12px;
      //   margin-bottom: 8px;
      // }
      // .hidden-value {
      //   display: flex;
      //   justify-content: space-between;
      //   padding-bottom: 3px;
      //   border-bottom: 1px solid var( --color-gray-100);
      // }
      // .input-value-gray {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: flex-end;
      //   border-bottom: 1px solid var( --color-gray-100);
      // }
      // .input-value-persianblue {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: flex-end;
      //   border-bottom: 1px solid var(--color-persianblue);
      // }
    }
    .home-phone {
      grid-area: home-phone;
      // .hidden-title {
      //   color: var( --color-gray-100);
      //   margin-top: 12px;
      //   margin-bottom: 8px;
      // }
      // .hidden-value {
      //   display: flex;
      //   justify-content: space-between;
      //   padding-bottom: 3px;
      //   border-bottom: 1px solid var( --color-gray-100);
      // }
      // .input-value-gray {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: flex-end;
      //   border-bottom: 1px solid var( --color-gray-100);
      // }
      // .input-value-persianblue {
      //   display: flex;
      //   justify-content: space-between;
      //   align-items: flex-end;
      //   border-bottom: 1px solid var(--color-persianblue);
      // }
    }
    .address {
      grid-area: address;

      .hidden-title {
        margin-bottom: 5px;
        color: var(--color-gray-100);
      }
      .hidden-value {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0px;
        border-bottom: 1px solid var(--color-gray-100);
      }
      .input-value {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid var(--color-gray-100);
      }
    }
    .suburb {
      grid-area: suburb;
    }
    .state {
      grid-area: state;
    }
    .post {
      grid-area: post;
    }
  }
}
