.add-membership-to-member {
  position: relative;
  height: 100%;
  .main-membership-card {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-gray-400);
    border-radius: 5px;
    width: 100%;
    height: fit-content;
    .member-grid-2 {
      display: grid;
      margin-top: 18px;
      grid-template-columns: 1fr 1fr;
      @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, 49%);
        grid-template-rows: 1fr 1fr;
      }
      gap: 18px;
      grid-auto-flow: row;
    }
    .member-payment-display {
      .first-row {
        display: flex;
        width: 100%;
        gap: 24px;
        justify-content: space-between;

        h3 {
          padding-bottom: 5px;
          color: var(--color-gray-400);
          text-transform: capitalize;
        }
        p {
          height: 20px;
          color: var(--color-white);
          border-bottom: 1px solid var(--color-gray-400);
          padding-bottom: 3px;
        }

        div,
        span {
          flex: 1;
        }

        &.debit {
          padding-bottom: 0;
          .pay-type,
          .names,
          .number {
            width: 100%;
          }
          .pay-type {
            gap: 30px;
          }
        }

        &.credit {
          .pay-type,
          .names,
          .number {
            width: 100%;
          }

          .cvv {
            width: 100%;
            max-width: 60px;
          }
          .expiry {
            width: 100%;
            max-width: 80px;
            h3 {
              white-space: nowrap;
            }
          }
        }
      }
    }
    .radio-group {
      display: flex;
      flex-direction: row;
      gap: 30px;
      margin-bottom: 20px;
    }
    .variable-selectors {
      display: flex;
      gap: 200px;
      .membership-type,
      .payment-details {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .payment-details {
        .select-element {
          margin-top: 0;
          max-width: 200px;
          margin-bottom: 10px;
        }

        .payment-type {
          input {
            &:last-of-type {
            }
          }

          &.credit {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            gap: 10px 59px;
            grid-auto-flow: row;
            grid-template-areas:
              "type number "
              "name exp-cvv";

            .type {
              grid-area: type;
            }

            .number {
              grid-area: number;
              // max-width: 175px;
            }

            .name {
              grid-area: names;
            }

            .exp-cvv {
              display: flex;
              gap: 23px;
              grid-area: exp-cvv;

              input {
                // flex: 1;
                // max-width: 76px;
                width: 100%;
              }

              label {
                visibility: hidden;
              }
              input {
                text-align: center;
              }
            }
            .card-expiry {
              display: flex;
              gap: 10px;
            }
            .expiry-divider {
              font-size: 12px;
              height: 100%;
              display: flex;
              align-items: center;
            }
          }

          &.debit {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // grid-template-rows: 1fr 1fr;
            gap: 10px 59px;
            grid-auto-flow: row;
            grid-template-areas:
              "acc-name ."
              "acc-number bsb";

            div {
              max-width: 200px;
            }

            .acc-name {
              grid-area: acc-name;
            }
            .acc-number {
              grid-area: acc-number;
            }
            .bsb {
              grid-area: bsb;
              max-width: 76px;

              .error {
                white-space: nowrap;
              }
            }
          }
        }

        // .cvv { grid-area: cvv; }
      }

      .membership-type-box {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;

        .membership-type {
          width: 100%;
          .first-row {
            margin-top: 10px;
            width: 100%;
            gap: 40px;
            display: flex;
            justify-content: flex-start !important;
            flex-direction: row !important;
            align-items: flex-end;
            > div {
              flex: 1;
            }
            .form-group {
              > label {
                color: var(--color-white);
              }
              .start-date {
                border-color: var(--color-gray-400);
              }
            }
          }
          .membership-details {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .first-row {
              margin-top: 10px;
              width: 100%;
              gap: 40px;
              display: flex;
              justify-content: flex-start !important;
              flex-direction: row !important;
              align-items: flex-end;
              > div {
                flex: 1;
              }
              .form-group1 {
                > label {
                  color: var(--color-white);
                }
              }
            }

            .second-row {
              gap: 20px;
              display: flex;
              flex-direction: row;
            }

            label {
              color: var(--color-gray-100);
            }

            &.no-show {
              visibility: hidden;
              height: 0;
            }

            &.no-space {
              display: none;
            }

            .cost-price {
              max-width: 76px;
            }

            .name {
              max-width: 200px;
              min-width: 200px;
            }

            input {
              border-bottom: 1px solid var(--color-gray-400);
              // user-select: none;
              // pointer-events: none;
            }
          }

          .membership-select {
            margin-bottom: 10px;
            select {
              border-bottom: 1px solid var(--color-gray-100) !important;
            }
          }

          .select-element-value {
            outline: none;
            background: transparent;
            color: var(--color-white);
            border: none;
            // border-bottom: 1px solid var( --color-gray-400);
            padding-bottom: 0px;

            &.main {
              // max-width: 200px;
              border-bottom: 1px solid var(--color-gray-400);
            }

            option {
              background: var(--color-gray-400);
            }
          }
        }
      }
    }
  }
  .membership-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    > div {
      height: 100%;
      background-color: #1a2027;
      padding: 24px 54px;
      border: 1px solid #5f5d5d;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > .button {
        display: flex;
        height: 100%;
        margin-top: auto;
        justify-content: center;
        align-items: end;
        > button {
          width: 100%;
        }
        > button.active {
          background-color: var(--color-persianblue);
        }
      }
      > div:first-child {
        p {
          text-align: center;
        }
        > p:first-child {
          text-transform: uppercase;
          font-size: 25px;
        }
        > p:nth-child(2) {
          margin: 14px 0;
          font-size: 35px;
          > span {
            font-size: 15px;
          }
        }
        > .price-sum {
          font-size: 12px;
          color: #797f87;
          padding-bottom: 14px;
          border-bottom: 1px solid #797f87;
        }
        .description {
          padding: 14px 12.5px;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-bottom: 24px;
          > div {
            gap: 12px;
            display: flex;
            align-items: center;
          }
          p {
            text-align: start;
          }
        }
      }
    }
  }

  .button-navigate-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 24px;
  }
  .align-right {
    justify-content: right;
  }
  .add-membership-process {
    display: flex;
    .your-membership {
      margin: 0 0 0 20px;
      width: 376px;
      animation-duration: 300ms;
      transition: all;
      -moz-transition: all;
      -ms-transition: all;
      -o-transition: all;
      -webkit-transition: all;
      animation-name: moveIn;
      animation-fill-mode: forwards;
      position: relative;
    }
    .your-membership-mobile {
      width: 100%;
      height: 50px;
      position: absolute;
      cursor: default !important;
      bottom: 0;
      border-radius: 2px;
      background-color: var(--color-gray-300);
      border-top: 2px solid var(--color-persianblue);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .container {
        position: relative;
        width: 100%;
        height: 100%;
        .collapse-btn {
          position: absolute;
          cursor: pointer;
          top: 0;
          height: 50px;
          width: 100%;
        }
      }
      .title {
        cursor: pointer;
        padding: 2px;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 2px;
        color: var(--color-persianblue);
      }
    }
    .expanded {
      height: 95%;
    }
    .add-membership-container {
      width: 100%;
    }
  }
}
.block {
  display: block !important;
}

// Media Queries

@media screen and (max-width: 1300px) {
  .add-membership-to-member .main-membership-card .variable-selectors {
    gap: 60px;
  }
}

@media screen and (max-width: 1000px) {
  .add-membership-to-member .main-membership-card .variable-selectors {
    flex-direction: column;
    gap: 20px;
    .date-container {
      // margin-bottom: 18px;
      max-width: 200px;
    }
  }
}
.btn-pay-disabled {
  background: transparent;
  pointer-events: none;
  border: 1px solid var(--color-gray-100);
  color: var(--color-gray-200);
  cursor: default;
}
