.add-instructor-container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  .first-card {
    flex-direction: column;
    .instructor-personal-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 24px 18px;
      grid-template-areas:
        "title name surname"
        "dob gender .";

      @media screen and (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
          "title name"
          "surname dob "
          "gender .";
      }
      @media screen and (max-width: 400px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          "title"
          "name"
          "surname"
          "dob"
          "gender ";
      }
    }
    .instructor-contact-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content;
      gap: 24px 18px;

      @media screen and (max-width: 400px) {
        grid-template-columns: 1fr;
      }
    }
    .address-info-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: min-content;
      gap: 24px 18px;

      @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 950px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 400px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
