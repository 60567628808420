.health-check {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  .yesNOcontainer {
    display: flex;
    gap: 8px;
  }
}
.term-container {
  line-height: 30px;
  .term-header {
    color: var(--color-gray-100);
    font-weight: 700;
    letter-spacing: 2px;
    margin-top: 15px;
  }
  .term-content {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    p {
      width: 95%;
    }
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.relevant-box {
  margin: 15px 0 0 0;
  .note {
    color: var(--color-gray-100);
    font-size: 14px;
  }
}
.healthQuestion-section-title {
  margin-top: 20px;
}
.medical-declare-container {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-gray-100);
  position: relative;
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    cursor: pointer;
    margin: auto 0;
  }
  .tooltip:hover .tooltiptext,
  .tooltip:hover .triangle {
    visibility: visible;
  }
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    /* background-image: linear-gradient(180deg, rgba(36, 51, 213, 0.34), #2757FF); */
    background-color: #2757ff;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
  }
  .medical-declare.stringInput {
    .tooltip .tooltiptext {
      left: 100%;
      transform: translate(-100%, 0);
      -webkit-transform: translate(-100%, 0);
      -moz-transform: translate(-100%, 0);
      -ms-transform: translate(-100%, 0);
      -o-transform: translate(-100%, 0);
    }
  }
  .medical-declare {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding: 10px 0;
    > p {
      gap: 5px;
      display: flex;
    }
  }
}
.singup-loading {
  display: flex;
  width: 80vh;
  height: 60vh;
  align-items: center;
  justify-content: center;
}
.singup-loading-full-width {
  display: flex;
  width: 100vh;
  height: 60vh;
  align-items: center;
  justify-content: center;
}
