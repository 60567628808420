.search-bar {
  width: 360px;
  height: unset;
  .search-bar-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-white-opaque);
    padding-bottom: 2px;
    // max-width: 300px;
    margin-left: auto;
    position: relative;

    input {
      width: 100%;
      background-color: transparent;
      border: 0;
      outline: 0;
      color: var(--color-white-opaque);

      &::placeholder {
        text-transform: uppercase;
        font-family: "gotham", Arial, Helvetica, sans-serif;
        color: var(--color-white-opaque);
      }
    }
    .remove-icon {
      background: transparent;
      color: var(--color-white-opaque);
      border: 0;
      font-size: 2rem;
      position: absolute;
      right: 26px;
      top: -8px;
      cursor: pointer;
    }
    button {
      background: transparent;
      color: var(--color-white-opaque);
      border: 0;
      font-size: 2rem;
      position: absolute;
      right: 4px;
      top: -7px;
      cursor: pointer;
    }
  }
}
