.view-question-page {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  .view-card {
    .question-title-details {
      width: 100%;
      h3 {
        margin-bottom: 5px;
      }
      p {
        color: var(--color-gray-400);
        text-transform: capitalize;
        border-bottom: 1px solid var(--color-gray-400);
        padding-bottom: 2px;
      }

      .brand,
      .name,
      .price {
        width: 100%;
      }

      .brand {
        max-width: 253px;
      }
      .name {
        max-width: 403px;
      }
      .price {
        max-width: 253px;
      }
      .toggle {
        width: 100%;
        text-align: left;
      }
      .inputs {
        display: grid;
        grid-auto-flow: row;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 18px;
        justify-content: start;
        text-align: start;
        > div:first-child {
          grid-column: span 4 / span 4;
          padding: 0 0;
        }
        .desc-row {
          grid-column: span 4 / span 4;
          textarea {
            border: 0px;
          }
        }
        > div > p {
          border: 0px;
          color: white;
        }
      }
    }
  }
}
