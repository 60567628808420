.head-checkbox-container {
  display: flex;

  .px-1 {
    align-self: flex-end;
  }
}

.apply-membership-to-container {
  .membership-landing-content {
    .search-and-button-row {
      justify-content: space-between;
      align-items: flex-end;
    }
    .checkbox {
      align-self: flex-end;
      margin-bottom: 0;
      max-height: 13.8px;
    }

    .landing-table {
      margin-top: 0px;
      .table {
        .right-btn {
          button {
            text-transform: uppercase;
            border: none;
            margin-top: 10px;

            &:disabled {
              background: var(--color-gray-100);
            }
          }
        }

        .pagination {
          // margin-top: -50px;
        }
      }

      table {
        justify-content: left;

        th,
        td {
          &:nth-child(1) {
            padding-left: 15px;
            width: 10%;
            white-space: none;
          }
          &:nth-child(2) {
            // min-width: 150px;
            width: 8%;
            text-align: center;
          }

          &:nth-child(3) {
            // min-width: 400px;
            width: 45%;
          }

          // &:nth-child(4) {
          //   max-width: 150px;
          //   text-align: right;
          //   white-space: nowrap;
          //   // padding-right: 35%;
          // }

          // &:nth-child(5) {
          //   min-width: 400px;

          // }
        }
      }
    }
  }
  .header-voucher {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    .search-container {
      //search bars
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: self-end;
      justify-content: self-end;
      margin: 10px 0 0px 0;

      .norecords-text {
        top: 20px;
        position: absolute;
        color: red;
      }
      .voucher-code {
        display: inline-block;
        align-self: flex-end;
      }
    }
  }
}
