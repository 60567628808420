.report {
  width: 100%;

  .report-type-card {
    width: 100%;
    height: max-content;
    padding: 21px 40px 14px 42px;
    border: 2px solid var(--gray-4);
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: var(--gray-5);
    }

    .front-face-elements {
      display: flex;
      justify-content: space-between;

      .expand-button {
        max-width: 11px;
        max-height: 22px;
        height: max-content;
        width: max-content;
        align-self: center;

        svg {
          color: var(--color-white);
          transform: rotate(0deg);
          transition: transform 0.3s;
        }

        &.open svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .report-body {
    table {
      border-collapse: collapse;
      border-spacing: 50px;
      margin-bottom: 15px;

      tbody {
        tr {
          border-bottom: 1px solid var(--color-gray-400);

          td {
            padding: 8px;

            &:nth-child(1) {
              white-space: nowrap;
              padding-left: 42px;
              min-width: 200px;
              width: 25%;
            }

            &:nth-child(2) {
              width: 65%;
            }

            &:nth-child(3) {
              width: 5%;
            }
          }

          .description {
            color: var(--color-gray-100);
          }
        }
      }
    }
  }
}
