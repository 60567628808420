.sign-up {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100vw !important;
  .sign-up-container {
    width: 370px;
    border: 1px solid var(--color-gray-100);
    background: var(--color-gray-300);
    border-radius: 5px;

    .frm-group {
      gap: 24px;
    }
  }
  .join-now-container {
    display: flex;
    width: 370px;
    padding: 21px 33px 12px 35px;
    color: var(--color-gray-100);
    text-decoration: underline;
    p {
      margin-right: 20px;
    }
  }
}
