.edit-question-page {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  .edit-card {
    width: 100%;
    h3 {
      margin-bottom: 8px;
    }

    .brand,
    .name,
    .price {
      width: 100%;
    }

    .brand {
      max-width: 253px;
    }
    .name {
      max-width: 403px;
    }
    .price {
      max-width: 253px;
    }
    .toggle {
      width: 100%;
      text-align: left;
    }
    .inputs {
      display: grid;
      grid-auto-flow: row;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      justify-content: start;
      gap: 18px;
      text-align: start;
      > div:first-child {
        grid-column: span 4 / span 4;
        padding: 0 0;
      }
    }
  }
  .desc-row {
    .radioGroup {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    h3 {
      color: var(--color-gray-400);
      padding-bottom: 10px;
    }
    textarea {
      height: 193px;
    }
  }
}
