.healthQuestionnaire-container {
  .healthQuestionnaire-landing-content {
    .feature-row {
      justify-content: flex-end;
    }
    .landing-table {
      // margin-top: 36px;
      table {
        .question-text {
          text-transform: uppercase;
          max-width: 500px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        th {
          padding: 14px 18px;
        }

        tbody {
          td {
            padding: 14px 18px 14px 18px;

            &:nth-child(4) {
              text-align: center;
            }
          }
        }
        td,
        th {
          &:nth-child(1) {
            width: 8%;
            text-align: center;
          }
          &:nth-child(2) {
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 5%;
            > span {
              padding: 4px 4px;
              display: inline-block;
              width: 100%;
              border: 1px solid transparent;
              border-radius: 4px;
              -webkit-border-radius: 4px;
              -moz-border-radius: 4px;
              -ms-border-radius: 4px;
              -o-border-radius: 4px;
            }
            .active {
              color: white;
              background-color: var(--color-persianblue);
            }
            .inactive {
              color: white;
              background-color: var(--color-mediumred);
            }
          }
          &:nth-child(5) {
            width: 10%;
          }
        }
      }
    }
  }
}
