.debt-collection-container {
  padding-bottom: 40px;

  .tabs {
    .tab-content {
      .button-container {
        display: flex;
        column-gap: 10px;
        justify-content: end;
      }
      .search-container {
        margin-top: 25px;
      }
    }
  }
}
