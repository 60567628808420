// .radio-container {
//   label {
//     display: inline-block;
//     cursor: pointer;

//     input {
//       display: none;
//       &:checked + span:after {
//         opacity: 1;
//         transition: 300ms;
//       }
//     }

//     span {
//       position: relative;
//       display: flex;

//       &:before {
//         content: "";
//       }

//       &:before {
//         border: 1px solid var( --color-gray-400);
//         border-radius: 10px;
//         width: 17px;
//         height: 17px;
//         margin-right: 10px;
//         display: inline-block;
//         vertical-align: top;
//         transition: 300ms;
//         align-self: center;
//       }

//       &:after {
//         border-radius: 10px;
//         width: 10px;
//         height: 10px;
//         position: absolute;
//         align-self: center;
//         top: 4px;
//         left: 4px;
//         transition: 300ms;
//         opacity: 0;
//         background: var(--color-persianblue);
//         // background-image: url(../../assets/images/icons/tick_box.png);
//         content: "";
//       }
//     }
//   }
// }

.radio-container {
  display: flex;

  /* Customize the label (the container) */
  .container {
    display: inline-block;
    position: relative;
    height: 17px;
    width: 17px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:checked ~ .checkmark:after {
        display: block;
      }
    }

    //hover effect
    // &:hover input ~ .checkmark {
    //   background-color: var(--color-red);
    //   transition: all 0.2s linear;
    // }

    &:hover input[disabled] ~ .checkmark {
      background-color: transparent;
    }

    input[disabled] ~ .checkmark:after {
      background: var(--color-white-opaque);
      opacity: 0.6;
      top: 1px;
      left: 1px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
    }

    &:hover input[disabled] ~ .checkmark:after {
      background: var(--color-white-opaque);
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 17px;
      width: 17px;
      border-radius: 10px;
      background-color: transparent;
      border: 2px solid var(--color-white-opaque);

      &:after {
        content: "";
        position: absolute;
        // display: none;
      }
    }
  }

  p {
    margin-left: 10px;
  }

  /* Style the checkmark/indicator */
  .container {
    input:hover ~ .checkmark:after {
      top: 1px;
      left: 1px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: var(--gray-2);
      transition: var(--transition);
    }
    input:checked ~ .checkmark:after {
      top: 1px;
      left: 1px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: var(--color-persianblue);
      transition: var(--transition);
    }
  }
}

// input[disabled] ~ .checkmark {
//   border: 1px solid var( --color-gray-400);
//   background-color: var( --color-gray-400);
// }

// // Style the inner circle's appearance on disabled state for checked radio buttons
// input[disabled]:checked ~ .checkmark:after {
//   background: var(--color-white-opaque);
//   width: 7px; // reduce the width
//   height: 7px; // reduce the height
// }
