.outer-containers {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  width: 250px;
  max-height: 434px;
  // height: auto;
  outline: 1px solid var(--color-gray-300);
  position: absolute;
  bottom: 110%;
  right: 0;
  border-radius: 4px;
  padding: 15px 20px;
  padding-bottom: 0px;
  // padding: 23px 10px 13px 10px;
  background: var(--color-gray-300);

  .top-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;

    .greeting-content {
      padding-top: 10px;

      h2 {
        letter-spacing: 1px;
      }

      p {
        color: var(--color-gray-100);
        line-height: 18px;
      }
    }
  }

  .bot-container {
    display: flex;
    flex-direction: column;
    flex: 60%;
    margin-left: -20px;
    margin-right: -20px;
    background: white;
    padding: 10px;
    padding-bottom: 13px;
    border-radius: 5px;

    .faq-container {
      padding-top: 9px;
      // padding: 10px 11px;

      p {
        color: var(--color-black);
        text-transform: uppercase;
        font-weight: 400;
        // padding-bottom: 10px;
      }

      .faq-title {
        color: var(--color-gray-100);

        padding-bottom: 10px;
      }

      .link-container {
        display: flex;
        gap: 5px;
        flex-direction: column;
        .link {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          p {
            text-transform: none;
          }
          .icon {
            color: #000000;
            margin-top: 2px;
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    .first-container {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 3px;
      margin-top: -25px;
      width: 100%;
      height: 60%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
      padding: 10px 11px;
      .search-container {
        display: flex;
        flex-direction: column;
        // padding: 10px 11px;

        p {
          color: var(--color-black);
          padding-bottom: 5px;
          text-transform: uppercase;
          font-weight: bold;
        }

        svg {
          color: var(--color-black);
        }
        .search-bar {
          // padding: 0px 0px;
          width: 100%;
          height: 28px;
          .search-bar-container {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid var(--color-black);
            padding-bottom: 0rem;
            padding-left: 0rem;

            button {
              border-color: transparent;
            }

            input {
              width: 100%;
              background-color: transparent;
              border: 0;
              outline: 0;
              color: var(--color-black);

              &::placeholder {
                font-family: "gotham", Arial, Helvetica, sans-serif;
                color: var(--color-black);
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }

    .conversation-container {
      display: flex;
      flex-direction: column;
      position: relative;
      background: white;
      box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      width: 100%;
      height: 55%;
      max-height: 106px;
      margin-top: 14px;
      padding: 10px 11px 19px 11px;

      .conversation-title {
        color: var(--color-gray-100);
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding-bottom: 10px;
      }

      .conversation-mid-container {
        display: flex;
        flex-direction: row;
        color: var(--color-black);
        padding-bottom: 8px;

        .icon {
          height: 25px;
          width: 25px;
          margin-right: 10px;
        }

        .mid-text-container {
          p:nth-child(2) {
            font-weight: 900;
          }
        }
      }

      .btn {
        background: transparent;
        font-weight: bold;
        padding: 5px 10px;
      }
    }
  }
}
