// CSS for Edit membership 'Edit Activation Date Status' card
h3 {
  &.edit-title {
    margin-bottom: 10px;
  }
}
#editEditMembershipDescriptionForm {
  width: 100%;
}
.active-date-status {
  display: flex;
  flex-direction: column;
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    th {
      text-align: left;
      color: var(--color-gray-400);
    }
    .activation-dates {
      &:nth-child(1) {
        .cell-data {
          padding-top: 0;
        }
      }
      td {
        &:nth-child(1),
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 25%;
        }
      }
      .cell-data {
        padding-top: 20px;

        .toggle {
          padding-left: 4px;
        }

        td {
          width: 24%;
        }
      }

      .active-status-title {
        //active header is by default, invisible
        visibility: hidden;
      }

      &:nth-child(1) {
        //display only the first 'active' title
        .active-status-title {
          visibility: inherit;
        }
      }
    }
  }
}
