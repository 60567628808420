.bookings-page {
  display: flex;
  gap: 32px;
  flex-direction: column;

  .btn-row-bookings {
    display: flex;
    justify-content: space-between;
    gap: 10px 20px;
    .btn-group {
      align-self: flex-end;
      margin: 0;
      button {
        padding: 4px 10px;
        font-size: 8px;
        background: var(--color-gray-300);
      }
      .icon {
        width: 0.8rem;
      }
    }
  }

  table {
    th {
      border-top: 2px solid var(--color-gray-100);

      &:nth-child(5) {
        div {
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
        }
      }
    }
    td {
      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        width: 15%;
      }
      &:nth-child(3) {
        width: 20%;

        div {
          display: flex;
          justify-content: flex-end;
          gap: 40px;
        }
      }
      &:nth-child(4) {
        width: 20%;
        div {
          justify-content: flex-end;
        }
      }
      &:nth-child(5) {
        width: 10%;
        div {
          justify-content: flex-end;
        }
      }
    }

    div {
      display: flex;

      .attendChecked,
      .attendCrossed {
        display: flex;
        background: transparent;
        border: 1px solid var(--color-gray-400);
        height: 100%;
        width: 100%;
        padding: 8px 12px;
        max-width: 34px;
        max-height: 22px;
        transition: all 0.2s linear;

        svg {
          color: white;
          align-self: center;
          transform: scale(1.6);
        }
      }
      .attended {
        background: var(--color-darkgreen);
      }

      .not-attended {
        background: var(--color-mediumred);
      }
      .attendChecked {
        border-right: none;
        border-radius: 5px 0px 0px 5px;
        &:hover {
          background: var(--color-darkgreen);
          transition: all 0.2s linear;
        }
      }
      .attendCrossed {
        border-radius: 0px 5px 5px 0px;

        &:hover {
          background: var(--color-mediumred);
          transition: all 0.2s linear;
        }
      }
    }
  }

  .bookings-modal {
    display: flex;
    flex-direction: column;
    background: var(--color-gray-300);
    border: 1px solid var(--color-gray-100);
    border-radius: 5px;
    width: 526px;
    max-width: 526px;

    .booking-type-radio-btns {
      display: flex;
      gap: 25px;
      justify-content: center;
    }

    .add-btn-container {
      grid-column: 1 / 3;
      display: flex;
      justify-content: center;
    }

    .search-booking {
      width: 100%;

      label {
        color: var(--color-gray-100);
      }
      form {
        grid-template-rows: repeat(1, 1fr);
        p,
        .search-input {
          grid-column: 1/3;
        }
        .search-input {
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 0;
          width: 60%;
          .form-group {
            width: 100%;
          }
          p {
            padding-bottom: 10px;
          }

          input {
            width: 100%;
            background-color: transparent;
            border: 0;
            outline: 0;
            padding-bottom: 2px;
            font-family: "gotham";
            border-bottom: 1px solid var(--color-gray-400);
            color: var(--color-white);
            transition: all 0.2s linear;

            &:-webkit-autofill {
              -webkit-background-clip: text;
              -webkit-text-fill-color: var(--color-gray-400);
              &:-webkit-autofill::first-line {
                font-size: 10px;
              }
            }
          }
          button {
            position: relative;
            background: transparent;
            border: none;

            svg {
              position: absolute;
              right: 0px;
              top: -15px;
              color: var(--color-gray-100);
            }
          }
        }
      }
    }

    //Casual Addition Styling
    .add-casual-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }

    p {
      &.member-header {
        color: var(--color-gray-100);
      }
    }

    .btn-hold {
      display: flex;
      gap: 0px 12px;
      justify-content: center;
    }
  }
}
