.edit-amount {
  padding: 0;
  width: fit-content;

  .radio-div {
    display: flex;
    flex-direction: column;
    gap: 0px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    label {
      display: flex;
      input {
        margin-right: 10px;
      }
      p {
        color: var(--color-gray-100);
      }
    }
  }
  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .input-and-dropdown {
      width: 100%;
      display: flex;
      gap: 24px;

      .amount-container,
      .drop-down {
        display: flex;
        flex-direction: column;
        align-items: unset;
        label {
          text-align: left;
          color: var(--color-gray-100);
          padding-bottom: 4px;
        }
        input {
          background-color: transparent;
          border: 0;
          outline: 0;
          padding-bottom: 2px;
          font-family: "gotham";
          border-bottom: 1px solid var(--color-gray-100);
          color: var(--color-white);
          transition: all 0.2s linear;
        }
        .select-element {
          gap: 0;
        }
        .billing-credit {
          padding-top: 3px;
          text-align: start;
        }
      }
    }
    .desc-row {
      h3 {
        text-align: left;
        color: var(--color-gray-100);
        padding-bottom: 10px;
      }
    }
  }

  .btn-disabled {
    &:disabled {
      background-color: var(--color-gray-100);
      cursor: not-allowed;
    }
  }
}

.cancel-membership {
  .input-container {
    width: 100%;
    .amount-container {
      display: flex;
      gap: 24px;
      text-align: left;

      .flex-col,
      .form-group {
        width: 100%;
      }
    }

    .form-group {
      label {
        text-align: left;
      }
    }

    .desc-row {
      text-align: left;
    }
  }
}
