.table.interations {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.member-interation {
  .member-changes-modal {
    table {
      td {
        max-width: 300px;
        word-break: break-all;
      }
    }
  }
}
table {
  .center {
    text-align: left;
  }

  th {
    padding-left: 18px;
  }
  td {
    text-align: left;
    text-transform: uppercase;
  }
}
table.classBookings {
  &:nth-child(1) {
    width: 15%;
  }
  // Type Column
  &:nth-child(2) {
    width: 15%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  // Club Manager and Area Manager Columns
  &:nth-child(5) {
    width: 20%;
  }
  &:nth-child(8) {
    width: 10%;
    .location-cell {
      display: flex;
      flex-direction: column;
      span {
        white-space: nowrap;
      }
      .orange-text {
        color: orange;
      }
    }
  }
}
table.interations {
  &:nth-child(1) {
    width: 15%;
  }
  // Type Column
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 30%;
  }
  &:nth-child(4) {
    width: 20%;
  }
  // Club Manager and Area Manager Columns
  &:nth-child(5) {
    width: 30%;
  }
  &:nth-child(8) {
    width: 10%;
    .location-cell {
      display: flex;
      flex-direction: column;
      span {
        white-space: nowrap;
      }
      .orange-text {
        color: orange;
      }
    }
  }
}
.member-changes-modal {
  .valuesTable {
    font-size: 16px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 5px;
    > p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.btn-container {
  display: flex;
  justify-content: center;
  gap: 10px;

  button {
    margin-right: unset;
    margin-left: unset;
  }
}
.edit-msg-container {
  padding-bottom: 15px;
}
