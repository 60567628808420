.edit-oustanding-container {
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  height: fit-content;
  width: fit-content;
  min-width: 400px;

  h3 {
    line-height: 0.85em;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .member-details {
      display: flex;
      gap: 24px;
      div {
        flex: 1;
      }
    }
    .entry-btn-group {
      display: flex;
      gap: 14px;
    }
  }
}
