.actions-row.report-tables {
  .btn-group,
  .search-container {
    margin: 0;
    align-self: flex-end;
  }
}

.status-modal.staff-audit-report-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  label {
    text-align: left;
  }

  .date-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    input {
      background-color: transparent;
      border: 0;
      outline: 0;
      font-size: 12px;
      border-bottom: 1px solid var(--color-gray-100);
      color: var(--color-white);
      font-family: "gotham";
      transition: all 0.2s linear;
    }
  }

  .textarea-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
