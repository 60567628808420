.add-membership-page {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;

  .add-card {
    .membership-title-details {
      display: flex;
      width: 100%;
      gap: 45px;

      h3 {
        margin-bottom: 5px;
      }
      p {
        color: var(--color-gray-400);
        text-transform: capitalize;
        border-bottom: 1px solid var(--color-gray-400);
        padding-bottom: 2px;
      }

      .brand,
      .name,
      .price {
        width: 100%;
      }

      .brand {
        max-width: 253px;
      }
      .name {
        max-width: 403px;
      }
      .price {
        max-width: 253px;
      }
      .toggle {
        width: 100%;
        text-align: right;
      }
    }
    .membership-wrap {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .select-element {
        flex: 1;
      }
    }
  }

  .desc-row {
    h3 {
      color: var(--color-gray-400);
      padding-bottom: 10px;
    }
  }

  .membership-settings-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr 1fr;
    grid-template-rows: fit-content(100%);
    gap: 53px 125px;
    grid-auto-flow: row;
    grid-template-areas:
      "pay-freq pay-delay contract-length pay-type"
      "join-fee-1 join-fee-2 join-fee-3 website-live"
      "start-date end-date is-peak . .";

    div {
      height: fit-content;
    }
  }

  .pay-freq {
    grid-area: pay-freq;
  }

  .pay-delay {
    grid-area: pay-delay;
  }

  .contract-length {
    grid-area: contract-length;
  }

  .pay-type {
    grid-area: pay-type;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .add-card {
    margin-bottom: 20px;
    .membership-title-details {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 45px;
      @media screen and (max-width: 1450px) {
        flex-direction: column;
        gap: 24px;
      }

      .inputs {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        width: 100%;
        @media screen and (min-width: 1450px) {
          margin-bottom: 18px;
        }
        div {
          width: 100%;
          min-width: 216px;
          max-width: 250px;

          .select-element-value {
            font-size: 10px;
          }
        }
      }

      .toggle {
        flex: 25%;
        text-transform: uppercase;
        width: 100%;
        text-align: right;
        align-self: flex-end;
        @media screen and (max-width: 850px) {
          align-self: flex-start;
          text-align: left;
          padding-bottom: 20px;
        }
      }
    }
  }

  .desc-row {
    h3 {
      color: var(--color-gray-400);
      padding-bottom: 10px;
    }
  }

  .membership-settings-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // grid-template-rows: 1fr 1fr 1fr;
    grid-template-rows: fit-content(100%);
    gap: 15px 25px;
    grid-auto-flow: row;
    grid-template-areas:
      "pay-freq pay-delay contract-length notice-period"
      "join-fee-1 join-fee-2 join-fee-3 pay-type"
      "start-date end-date .  website-live";

    // @media screen and (max-width: 1600px) {
    //   grid-template-columns: 1fr 1fr 1fr;
    //   grid-template-rows: fit-content;
    //   grid-template-areas:
    //     "pay-freq pay-delay contract-length"
    //     "notice-period join-fee-1 join-fee-2 "
    //     " join-fee-3  start-date pay-type"
    //     " end-date  website-live .";
    // }

    @media screen and (max-width: 1550px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: fit-content;
      row-gap: 32px;
      grid-template-areas:
        "pay-freq pay-delay"
        "contract-length notice-period"
        " join-fee-1 join-fee-2 "
        " join-fee-3  start-date "
        " end-date  ."
        " pay-type website-live ";
    }

    @media screen and (max-width: 920px) {
      grid-template-columns: 1fr;
      grid-template-rows: fit-content;
      grid-template-areas:
        "pay-freq "
        "pay-delay"
        "contract-length"
        "notice-period"
        "join-fee-1"
        "join-fee-2"
        "join-fee-3"
        "start-date"
        "end-date"
        "pay-type"
        "website-live";
    }

    div {
      height: fit-content;
    }

    p {
      color: var(--color-gray-100);
      padding-bottom: 5px;
    }

    .toggle {
      label {
        white-space: nowrap;
      }
    }

    .input-select-title {
      display: flex;
      flex-direction: column;
      width: 100%;

      .input-select {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        align-items: flex-end;
        &:has(.error:not(:empty)) {
          // when both errors are triggered
          margin-bottom: 40px !important;
        }
        &:has(.flex-col .error:empty) {
          //targets margins once one of the errors is dealt with
          margin-bottom: 25px !important;
          .box-input-custom-error {
            .error {
              top: 30px;
            }
          }
        }
        @media screen and (max-width: 1550px) {
          &:has(.flex-col .error:empty) {
            margin: 0 !important;
          }
        }
        // &:has(.form-group .error:not(:empty)) {
        //specific to the notice period error
        //   margin-bottom: 35px;
        // }

        .box-input-custom-error {
          position: relative;

          .error {
            position: absolute;
            top: 48px;
            left: 38px;
            // white-space: nowrap;
            width: 220px;
          }
        }
        .flex-col {
          position: relative;

          .error {
            position: absolute;
            margin-top: 0;
            top: 20px;
          }
        }

        .select-element {
          min-width: 200px;
          width: 100%;
          label {
            margin-bottom: 2px;
          }
        }

        input {
          display: flex;
          justify-content: center;
          text-align: center;
          border: 1px solid var(--color-gray-400);
          border-radius: 2px;
          min-width: 28px;
          min-height: 28px;
          width: min-content;
          max-width: 28px;
          max-height: 28px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }
    }
  }

  .notice-period {
    grid-area: notice-period;

    .form-group {
      position: relative;

      .error {
        position: absolute;
        margin-top: 0;
        top: 35px;
        left: 38px;
        width: 200px;
      }
    }
  }

  .pay-freq {
    grid-area: pay-freq;
    display: flex;
  }

  .pay-delay {
    grid-area: pay-delay;
  }

  .contract-length {
    grid-area: contract-length;
  }

  .pay-type {
    grid-area: pay-type;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-right: auto;
  }

  .join-fee-1 {
    grid-area: join-fee-1;
  }

  .join-fee-2 {
    grid-area: join-fee-2;
  }

  .join-fee-3 {
    grid-area: join-fee-3;
  }

  .website-live {
    grid-area: website-live;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: auto;
  }

  .start-date {
    grid-area: start-date;
  }

  .end-date {
    grid-area: end-date;
  }
}

.right-btn {
  &.add-membership {
    margin-bottom: 50px;
  }
}
