.beat-loader {
  display: flex;
  align-items: center;
}
.fp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  padding: 6px 6px;
  border-radius: 2px;
  color: var(--color-white) !important;
  background-color: var(--color-persianblue-dark);
}
