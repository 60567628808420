.class {
  .class-container {
    position: relative;

    .class-info {
      margin-top: 3.2rem;
      .class-stats-cards {
        display: flex;
        gap: 10px;
      }
      .row2 {
        margin-top: 3.2rem;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1050px) {
          flex-direction: column;
          gap: 24px;
        }

        @media screen and (max-width: 900px) {
          .left-elements {
            flex-direction: column;
            gap: 24px;
            .search-container {
              width: 100%;
              form {
                width: 100%;
              }
              .search-bar {
                width: 100%;
              }
            }
          }
        }

        .search-bar {
          input {
            &::placeholder {
              text-transform: capitalize;
            }
          }
        }
      }
    }
    .class-filter {
      position: absolute;
      top: 35px;
      left: 20px;
      z-index: 10;
    }
    .inline {
      display: none;
      gap: 10px;
      position: absolute;
      right: 0px;
      z-index: 1;
    }
    .table {
      table {
        border-collapse: collapse;
      }

      th {
        border-bottom: 2px solid var(--color-gray-100);

        //header for class table date/time signature
        &.date-header {
          background: var(--color-gray-300);
          color: var(--color-white);
          text-transform: uppercase;
          font-weight: 700;
        }

        &:nth-child(2) {
          position: relative;
        }
      }
      td {
        border-bottom: 1px solid var(--color-gray-100);
        width: 25%;

        &:nth-child(1) {
          width: 15%;
        }

        &:nth-child(6) {
          text-align: center;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          width: 15%;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 10%;
        }
      }
      //Holds action icons
      .right {
        width: 100%;
        display: flex;
        margin: 0;
        gap: 48px;
        padding-right: 0;
        text-align: right;
        padding-left: 0.635rem;
        justify-content: flex-end;
        &:last-child {
          padding-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  // .class {
  //   .class-container {
  //   }
  // }
}

@media screen and (max-width: 1024px) {
  .class {
    .class-container {
      .class-info {
        margin-top: 1.8rem;
      }
    }
  }
}
