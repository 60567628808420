.bulk-sms-container {
  flex-direction: column;
  gap: 24px;
  .selections-container {
    display: flex;
    flex-flow: row wrap;
    gap: 24px;
    justify-content: space-between;

    #brand,
    #loc,
    #smsTo {
      flex: 1;
      input,
      select {
        width: 100%;
      }
    }
    .select-element-value {
      background-color: transparent;
      min-width: 200px;
      border: 0;
      outline: 0;
      border-bottom: 1px solid var(--color-gray-100);
      color: var(--color-white);
      font-family: "gotham";
      transition: all 0.2s linear;
      option {
        background-color: var(--color-gray-200);
        font-size: 1.2rem;
      }
    }
  }
}
