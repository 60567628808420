.generate-report-card {
  border: 1px solid var(--color-gray-100);
  width: 100%;
  height: 212px;
  border-radius: 5px;
  .generate-report-card-container {
    padding: 1.4rem;
    text-transform: uppercase;
    width: 100%;
    height: 160px;
    .top {
      .title {
        margin-bottom: 7px;
      }
      .date {
        color: var(--color-gray-100);
      }
      .right {
        display: flex;
        .title {
          margin: 12px 5px 0 0;
          color: var(--color-gray-100);
        }
      }
    }
  }
}
