.barcode-list {
  list-style-type: none;
  padding: 0;
  li {
    margin-top: 1rem;
  }
}
.summary {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: normal;
}
.summary-field-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-300);
  padding-bottom: 2px;
}
.category {
  color: var(--color-gray-100);
  text-transform: uppercase;
  font-weight: 700;
}
.uppercase {
  text-transform: uppercase;
}
