.cardlg {
  border: 1px solid var(--color-gray-400);
  width: 100%;
  height: 117px;
  border-radius: 5px;

  padding: 0.6rem 1.4rem;
  .cardlg-container {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .billing-info {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      margin-top: 5px;
      .heading {
        color: var(--color-gray-100);
        text-transform: uppercase;
      }
      .flex-r {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        gap: 1rem;
        .increase-color {
          color: var(--color-green);
        }
        .rate {
          padding-left: 0.5rem;
        }
        .decrease-color {
          color: var(--color-red);
        }
      }
      .flex-col {
        justify-content: space-between;
      }
    }
  }
}

@media screen and (max-width: 1530px) {
  .cardlg {
    .cardlg-container {
      .heading {
        letter-spacing: 0;
      }
    }
  }
}
