.note-container {
  display: flex;
  flex-direction: column;
  // position: fixed;
  // left: 60%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  z-index: 200;
  width: fit-content;
  height: fit-content;
  background: var(--color-gray-300);
  border: 1px solid var(--color-gray-400);
  border-radius: 8px;

  .note-header {
    text-align: center;
  }

  .log-details-container {
    display: flex;
    gap: 0px 50px;
    padding-bottom: 24px;

    .reminder {
      align-self: flex-end;
    }

    .date,
    .name {
      //   flex: 1;
      display: flex;
      flex-direction: column;
      text-transform: uppercase;

      p {
        font-weight: 100;
      }
      label {
        display: none;
      }

      .date-content,
      .name-content {
        text-transform: capitalize;
        border-bottom: 1px solid var(--color-gray-100);
      }

      .date-title,
      .name-title {
        padding-bottom: 7px;
        color: var(--color-gray-400);
      }
    }
  }

  .subject-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    p {
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;
      align-items: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding: 3px 10px;
    }
  }

  .note-content-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 28px;

    h3 {
      text-transform: uppercase;
      padding-bottom: 11px;
    }

    .note-content {
      border: 1px solid var(--color-gray-100);
      border-radius: 5px;
      height: 139px;
      padding: 17px 21px 16px 15px;

      textarea {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: none !important;
        background-color: transparent;
        resize: none;
        color: var(--color-white);

        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
}
