.rp-main-container {
  .tabs {
    .tabs-list {
    }
    .select-element {
      max-width: 221px;
      margin-left: auto;
      .select-element-value {
        text-transform: capitalize;
        // option {
        //   &:nth-child(2),
        //   &:nth-child(13),
        //   &:nth-child(21) {
        //     text-align: center;
        //     font-size: 14px;
        //   }
        // }
      }
    }
    .table.roles-and-permissions-table {
      margin-top: 18px;
      padding-bottom: 40px;
      table {
        td,
        th {
          text-align: center;

          &:nth-child(1) {
            width: 35%;
            text-align: left;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}
