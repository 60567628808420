.change-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .change-password-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: var(--color-gray-300);
    border: 1px solid var(--color-gray-100);
    border-radius: 5px;
    width: fit-content;
    height: fit-content;
    min-width: 370px;
    max-width: 370px;

    form {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 100%;

      input {
      }
    }

    img {
      width: 105px;
    }

    .save-btn {
      margin-bottom: 0;
      align-self: center;
    }
  }
}
