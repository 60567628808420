table > thead > tr > th:nth-child(1) .header-cell {
  justify-content: left;
}

.report-table-container-all {
  table th .header-cell {
    display: flex;
    cursor: pointer;
    justify-content: center;
  }
}
