.international-phone-input-container {
  .react-tel-input {
    .form-control {
      position: relative;
      width: 100%;
    }
    .flag-dropdown {
      position: absolute;
    }
    .form-control,
    .flag-dropdown {
      // width: unset;
      height: unset;
      border: none;
      border-bottom: 1px solid var(--color-gray-100);
      border-radius: 0;
      color: var(--color-white);
      background: transparent;
      line-height: 22px;
    }

    .flag-dropdown {
      border: none; //need this to be separate to stop border double-up

      &.open {
        background: transparent !important;
      }

      .selected-flag {
        background: transparent !important;
        padding-left: 0;
      }
      .country-list {
        background: var(--color-gray-300);
        transition: all 0.2s linear;

        li {
          &:hover {
            background: var(--color-gray-300);
            transition: all 0.2s linear;
          }
          &.country.highlight {
            background: var(--color-gray-300);
          }
          .dial-code {
            color: var(--color-white-opaque);
          }
          &.no-entries-message {
            color: var(--color-gray-100);
            font-size: 14px;
          }
        }

        .search {
          background: var(--color-gray-300);
          padding-left: 3px;

          input {
            background: transparent;
            border: none;
            border-bottom: 1px solid var(--color-gray-100);
            border-radius: 0;
            color: var(--color-white);
            font-size: 12px;
            padding-left: 0;
            padding-bottom: 2px;
            width: 90%;

            &::placeholder {
              font-size: 12px;
            }
            &.icon {
              background: red;
            }
          }
        }
      }
    }
  }
}
