.table {
  &.template-table {
    .table {
      td,
      th {
        text-align: center;
        &:nth-child(2) {
          text-align: left;
        }
      }

      td {
        &:nth-child(1) {
          width: 8%;
        }
        &:nth-child(2) {
          //class name column
          width: 20%;
        }
        &:nth-child(3),
        &:nth-child(4) {
          //type column
          width: 15%;
        }

        &:nth-child(5) {
          // buttons column
          width: 20%;
          padding-left: 0;
        }
      }
    }
  }
}

.class {
  .class-template-container,
  .template {
    .status-modal {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 554px;
      height: fit-content;
      background: var(--color-gray-300);
      border: 1px solid var(--color-gray-200);
      border-radius: 5px;
      padding: 32px;
      text-align: center;

      .btn-group {
        margin: 0;
        justify-content: center;
      }

      .className-span {
        display: flex;
        gap: 12px;
        justify-content: center;

        img {
          align-self: center;
          height: 15px;
          width: 20px;
        }
      }
    }
    .class-info {
      .row2 {
        h3 {
          text-transform: uppercase;
          width: 33%;
        }
        .search-bar-container {
          // margin-left: 30px;
        }

        .btn-group {
          // align-self: center;
          margin-top: auto;
          margin-bottom: 0;
          button {
            padding: 4px 10px;
            font-size: 8px;
            background: var(--color-gray-300);
          }
          .icon {
            width: 0.8rem;
          }
        }

        @media screen and (max-width: 1280px) {
          flex-direction: column;
          gap: 24px;
          .left-elements {
            flex-direction: column;
            gap: 24px;
            .search-container {
              width: 100%;
              form {
                width: 100%;
              }
              .search-bar {
                width: 100%;
              }
            }
          }
        }
      }
    }
    .right-btn {
      // margin-right: 10px
    }
  }
}
