.aba-container {
  display: flex;
  flex-direction: column;
  .modal {
    width: 500px;
    // height: 300px;
    background: var(--color-gray-300);
    border: 1px solid var(--color-gray-400);
    .option-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .generate-modal {
      width: 100%;
      align-items: center;
      // justify-content: space-evenly;
      gap: 10px;
      display: flex;
      flex-direction: column;
    }
    .radio-option {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .form-group {
        width: 100%;
      }
    }
    .btn-group {
      grid-column: span 2 / span 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .form-group {
    align-self: flex-start;
    // width: 15%;
  }

  .aba-table {
    .table {
      table {
        th,
        td {
          &:nth-child(2) {
            text-align: right;
          }
          &:nth-child(3) {
            text-align: right;
            padding-right: 20px;
            img {
              position: absolute;
              top: -25px;
              right: -35px;
            }
          }
        }

        td {
          align-items: center;
          justify-content: center;
          height: 80%;
        }
      }
    }
  }
}

.aba-norecords-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .btn {
    margin-left: auto;
    margin-right: auto;
  }
}
.rejection-btn {
  margin-top: 30px;
  display: flex;
  flex-flow: row-reverse;
}
