.card-information-container {
  .table {
    .table-container {
      table {
        th,
        td {
          width: 15%;
          &:nth-child(2),
          &:nth-child(3) {
            width: 12%;
          }
          &:nth-child(4) {
            text-align: center;
          }
          &:nth-child(5),
          &:nth-child(6) {
            width: 1%;
            text-align: right !important;
          }
        }

        th {
          &:nth-child(6) {
            padding-right: 4px;
          }
        }
      }
    }
  }
}

.add-card-container {
  .info-card.add-card {
    flex-direction: column;
    gap: 18px;
    form {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }
    .card-input-row {
      display: flex;
      gap: 24px;
      .form-group {
        flex: 1;
      }
    }
  }

  .btn-group {
    text-align: right;
    justify-content: flex-end;
  }
}

.damaged-card-container,
.edit-card-container {
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

@media screen and (max-width: 768px) {
  .card-input-row {
   flex-direction: column;
  }
}

