.forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  width: 100vw !important;
  // background-image: url("../../assets/images/login_BG.png");

  .logo {
    width: 370px;
    padding: 23px 29px;
  }

  .forgot-password-container {
    width: 370px;
    height: fit-content;
    border: 1px solid var(--color-gray-100);
    border-radius: 5px;
    background: var(--color-gray-300);

    .button {
      margin-top: 25px;
    }
  }
  .code {
    display: flex;
    width: 370px;
    padding: 21px 33px 12px 35px;
    color: var(--color-gray-100);
    text-decoration: underline;
    text-decoration: none;
  }

  .password,
  .confirmpassword {
    position: relative;

    .form-group1 {
      //must keep this form-group1 styling
      width: 100%;
      input {
        border-bottom: 1px solid var(--color-gray-100);
      }
    }
    span {
      position: absolute;
      top: 18px;
      right: 0px;
    }
  }
}
