.bank-refund-container {
  .feature-row {
    justify-content: flex-end;
    .right-elements {
      .search-bar {
        max-width: 200px;
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: end;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
