.report-table-container {
  width: 100%;
  //   overflow-x: auto;
  .table-container {
    width: 100%;
    overflow-x: scroll;
    scrollbar-color: var(--color-white);
    scrollbar-width: thin;
    border-bottom: var(--color-grey);
    table {
      width: 100%;
    }
  }
  .table-container::-webkit-scrollbar-thumb {
    background-color: var(--color-white);
    height: 0.25rem;
  }
  .table-container::-webkit-scrollbar {
    width: 5px;
    height: 0.5rem;
  }
  .total {
    width: 100%;
    padding-left: 80%;
    border-bottom: var(--color-grey);
  }
}