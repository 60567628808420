.modal-backdrop-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 339px);
  height: 100vh;
  max-height: 1080px;
  background: rgba(0, 0, 0, 0.15);
  z-index: 10;
  margin-left: 339px;
  max-width: calc(1600px - 339px);
  .modal {
    position: absolute;
    // left: 30%;
    // top: 25%;
    // width: 450px;
    // height: 257px;
    padding: 100px 100px;
    // max-width: 500px;
    display: flex;
    align-self: center;
    background: transparent;
    background: black;
    border: 1px solid var(--color-gray-100);
    border-radius: 10px;
    .content {
      margin-top: 10px;
      width: 450px;
      text-align: center;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 20px;
    }
    .spinner {
      margin: 0 auto;
      width: 50px;
      height: 50px;
      border: 5px solid var(--color-persianblue);
      border-top-color: #333;
      border-radius: 50%;
      animation: spin 1s linear infinite; /* sử dụng animation tên là 'spin' */
    }

    @keyframes spin {
      from {
        transform: rotate(0deg); /* Góc ban đầu 0 độ */
      }
      to {
        transform: rotate(360deg); /* Góc cuối cùng 360 độ */
      }
    }
    .tick {
      margin: 0 auto;
      width: 100px;
      height: 100px;
    }

    .tick-circle {
      stroke: var(--color-persianblue);
      stroke-width: 2;
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      fill: none;
      animation: drawCircle 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    .tick-check {
      stroke: var(--color-persianblue);
      stroke-width: 2;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      fill: none;
      animation: drawCheck 0.6s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
    }

    @keyframes drawCircle {
      100% {
        stroke-dashoffset: 0;
      }
    }

    @keyframes drawCheck {
      100% {
        stroke-dashoffset: 0;
      }
    }
    .header {
      font-size: 20px;
      margin-top: 30px;
      letter-spacing: 2px;
      text-align: center;
      color: var(--color-persianblue);
    }
  }
}
