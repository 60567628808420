.medicalSuspension-container {
  .feature-row {
    justify-content: flex-end;
    .right-elements {
      .search-bar{
        max-width: 200px;
      }
    }
  }
}
