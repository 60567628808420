.select-element {
  // margin-top: 10px;
  display: flex;
  flex-direction: column;
  background: transparent;

  label {
    color: var(--color-gray-100);
    margin-bottom: 5px;
    // font-family: "gotham";
    transition: all 0.2s linear;
  }
  select {
    option {
      &:first-child {
        display: none;
      }
    }
  }

  .select-element-value {
    width: 100%;
    background-color: transparent;
    border: 0;
    outline: 0;
    // padding-bottom: 2px;
    font-size: 12px;
    // padding-bottom: 2px;
    border-bottom: 1px solid var(--color-gray-100);
    color: var(--color-white);
    font-family: "gotham";
    transition: all 0.2s linear;
    option {
      background-color: var(--color-gray-200);
      font-size: 1.2rem;
    }
    &:disabled {
      opacity: 1 !important;
    }
  }
  label.blue {
    color: var(--color-gray-100);
    transition: all 0.2s linear;
    // margin-bottom: 10px;
  }
  .select-element-value.blue {
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 12px;
    // padding-bottom: 2px;
    font-family: "gotham";
    border-bottom: 1px solid var(--color-gray-100);
    color: var(--color-white);
    transition: all 0.2s linear;
  }
}
.searchableSearchInput {
  border: 0;
  outline: 0;
  border-bottom: 1px solid var(--color-gray-100);
  color: var(--color-white);
  transition: all 0.2s linear;
  &::placeholder {
    /* Styles for the placeholder text */
    color: white;
  }
  width: 100%;
}
