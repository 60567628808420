.edit-field {
  .edit-field-container {
    .form-group {
      display: flex;
      justify-content: space-between;
      background: red;

      .button-group {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 1rem;
      }
    }
  }
}
