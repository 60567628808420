.financialYearModal {
  form {
    // width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    .report-options-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .date-input-container {
        display: flex;
        width: fit-content;
        gap: 16px;

        input {
          border: 1px solid white;
          color: white;
        }
      }
    }

    .btnContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      margin: auto 0;
    }
  }
}
