.member-details-membership {
  table {
    th,
    td {
      &:nth-child(1) {
        width: 15%;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(9) {
        width: 5%;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(4),
      &:nth-child(5) {
        width: 5%;
      }
      &:nth-child(6),
      &:nth-child(7) {
        width: 10%;
        text-align: center;
      }
      &:nth-child(8) {
        width: 7%;
        text-align: center;
      }
      &:nth-child(10) {
        .action-container {
          button {
            &.disabled {
              background: transparent;
              color: var(--color-gray-200);
            }
          }
        }
      }
    }
    thead {
      tr {
        th {
          padding-left: 15px;
        }
      }
    }
  }
  .modal {
    select {
      padding-bottom: 2px;
    }
  }
}

.action-icons-memberships {
  display: flex;
  gap: 35px;
  justify-content: flex-start;
}
.table {
  .table-container {
    width: 100%;
    overflow-x: scroll;
    min-height: 500px;
    scrollbar-color: var(--color-white);
    scrollbar-width: thin;
    border-bottom: var(--color-grey);
    &::-webkit-scrollbar {
      display: inline-flex;
    }
  }
}
