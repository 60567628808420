.card {
  border: 2px solid var(--gray-4);
  width: 100%;
  height: 117px;
  border-radius: 5px;
  .card-container {
    padding: 1.4rem;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    height: 100%;
    .title {
      display: flex;
      justify-content: space-between;
      .value {
        text-transform: uppercase;
      }
      .updownicon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--gray-4);
        display: flex;
        align-items: center;
        justify-content: center;

        .small-icon {
          width: 12px;
          height: 12px;
        }
      }
    }
    .heading {
      color: var(--color-gray-100);
    }
    .value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .red_text {
        color: var(--color-red);
      }
    }
  }
  // &:hover {
  //   background-color: #141414;
  // }
}
