.form-group {
  // margin-top: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  // border: 1px solid blue;
  label {
    color: var(--color-gray-100);
    margin-bottom: 5px;
    transition: all 0.2s linear;
  }
  input::-webkit-calendar-picker-indicator,
  input[type="date"]::-webkit-calendar-picker-indicator,
  input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    display: block;
    top: 0;
    right: 0;
    background: transparent;
    position: absolute;
    transform: scale(20);
    z-index: 100;
    cursor: pointer;
  }

  .date-container {
    display: flex;
    height: min-content;
    position: relative;

    input {
      width: 100%;
    }
  }

  input {
    position: relative;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding-bottom: 2px;
    // font-family: "gotham"; DO NOT REAPPLY THIS!!!
    border-bottom: 1px solid var(--color-gray-100);
    color: var(--color-white);
    transition: all 0.2s linear;

    &::-webkit-datetime-edit-year-field {
      color: var(--color-white);
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: white;
    }
    &[type="date"],
    &[type="datetime-local"] {
      padding-bottom: 0px;

      &.blue {
        padding-bottom: 0px;
      }
    }

    &.blue {
      background-color: transparent;
      border: 0;
      outline: 0;
      padding-bottom: 2px;
      font-family: "gotham";
      border-bottom: 1px solid var(--color-gray-100);
      color: var(--color-white);
      transition: all 0.2s linear;
    }
  }

  svg {
    position: absolute;
    width: 15px;
    height: 15px;
    right: 0;

    &.small-box {
      bottom: calc(50% - 8px);
    }
    &.large-box {
      bottom: 2px;
    }
  }

  label.blue {
    // color: var(--color-persianblue);
    transition: all 0.2s linear;
  }
}

.form-group1 {
  width: 85%;
  // margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-self: start;
  label {
    color: var(--color-gray-100);
    margin-bottom: 5px;
    text-transform: capitalize;
    transition: all 0.2s linear;
  }
  input {
    background-color: transparent;
    border: 0;
    outline: 0;
    padding-bottom: 0px;
    font-family: "gotham";
    color: var(--color-white);
    border-bottom: 1px solid var(--color-gray-100);
    transition: all 0.2s linear;

    &:-webkit-autofill {
      -webkit-background-clip: text;
      -webkit-text-fill-color: white;
    }
  }
  label.blue {
    // color: var(--color-persianblue);
    transition: all 0.2s linear;
  }
  input.blue {
    border: 0;
    outline: 0;
    padding: 0;
    transition: all 0.2s linear;
  }
}
