.edit-form-group-container {
  width: 100%;
  border: 1px solid red;
  .edit-form--group {
    margin-top: 8px;
    .input-group {
      input {
        border: none;
        outline: 0;
        background-color: transparent;
        color: white;
        border-bottom: 1px solid var(--white);
        width: 100%;
      }
    }
  }
}
