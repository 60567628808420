.club-features-container {
  display: flex;
  flex-direction: column;
  min-height: 191px;
  width: 100%;
  border: 1px solid var(--color-gray-400);
  border-radius: 5px;
  gap: 5px;
  .title-bar {
    display: flex;
    justify-content: space-between;
    // flex-direction: column;

    h3 {
      text-transform: uppercase;
    }
    // .icon-container {
    //   display: flex;
    //   position: relative;
    //   gap: 0px 4px;

    .icon-container {
      color: var(--color-persianblue);
      display: flex;
      flex-direction: row;
      gap: 14px;
      transition: all 0.2s linear;

      &.edit:hover {
        color: var(--color-white);
        transition: 0.2s linear;
      }

      .sub-container {
        color: var(--color-persianblue);
        display: flex;
        flex-direction: row;
        gap: 4px;
        transition: all 0.2s linear;

        &.save {
          color: var(--color-white);
          transition: all 0.2s linear;

          &:hover {
            filter: brightness(85%);
          }
        }

        &.cancel {
          color: var(--color-red);
          transition: all 0.2s linear;

          &:hover {
            color: var(--color-mediumred);
          }
        }
      }
      // svg {
      //   position: absolute;
      //   top: -3px;
      //   right: 26px;
      //   align-self: flex-start;
      //   transform: scale(0.6);
      // }
    }
  }

  .club-features {
    display: flex;
    height: 100%;
    gap: 0px 20px;
    white-space: nowrap;

    .col-1 {
      flex: 1;
      gap: 14px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      line-height: 1.5;
    }

    @media screen and (max-width: 1100px) {
      flex-wrap: wrap;

      .col-1 {
        margin-bottom: 15px;
      }
    }
  }
}
