.your-membership-container {
  background-color: #1a2027;
  padding: 20px;
  width: 376px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 1.5px solid var(--color-gray-400);
  .title {
    font-size: 23px;
    font-family: gotham;
    padding-bottom: 14px;
    letter-spacing: 0.1rem;
    border-bottom: 1px solid #5f5d5d;
  }
  .location {
    border-bottom: 1px solid #5f5d5d;
    > div {
      padding-top: 14px;
      display: flex;
      justify-content: space-between;
    }
    .address {
      color: var(--color-gray-100);
      font-size: 14px;
      margin-top: 8px;
      padding-bottom: 16px;
    }
  }
  .price-details {
    padding: 14px 0;
    > div:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > p {
        font-size: 16px;
      }
      > span {
        color: var(--color-gray-100);
        font-size: 14px;
        text-decoration: underline;
      }
    }
    > div {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      letter-spacing: 0.1px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  .promo-code,
  .membership-start-date,
  .next-direct,
  .fortnightly {
    border: 0.5px solid var(--color-borders-and-lines);
    border-right: 0px;
    border-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    padding-bottom: 16px;
    > p:first-child {
      color: #bfbfbf;
      font-size: 14px;
    }
    > p:last-child {
      font-size: 14px;
      font-weight: 500;
    }
    > input {
      padding: 10px 12px;
      border: 1px solid #5f5d5d;
      border-radius: 5px;
      color: var(--color-persianblue);
      font-size: 14px;
      letter-spacing: 0.1px;
      font-weight: 500;
      background-color: transparent;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
  }
  .total {
    border: 0.5px solid var(--color-borders-and-lines);
    border-right: 0px;
    border-left: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 14px;
    > p:first-child {
      color: white;
      font-weight: 500;
      font-size: 18px;
    }
    > p:last-child {
      font-size: 18px;
      font-weight: 500;
      color: var(--color-persianblue);
    }
  }
  .membership-start-date {
    .date-input input {
      color: white;
      font-size: 14px;
      width: 171.5px;
      padding: 8px 17.5px;
    }
  }
}
@media only screen and (max-width: 1580px) {
  .your-membership-container {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    .title {
      text-align: center;
      border-bottom: none;
    }
  }
}
