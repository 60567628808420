.term-header {
  color: var(--color-gray-100);
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 15px;
}
.term-content {
  font-size: 15px;
  color: var(--color-gray-100);
  margin: 15px 0;
  line-height: 25px;
  span {
    color: var(--color-white);
    font-weight: 700;
  }
}
.checkbox-agree {
  font-size: 15px;
  color: var(--color-gray-100);
  display: flex;
  > div:first-child {
    margin-top: 2px;
    display: inline-block;
    margin-right: 5px;
  }
}
