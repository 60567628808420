.view-suspension-page {
  .view-card {
    .suspension-title-details {
      > p:first-child {
        padding: 18px;
      }
      .inputs {
        border: 1px solid var(--color-gray-400);
        padding: 18px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 18px;
        > div:first-child {
          grid-column: span 2 / span 2;
        }

        > div:nth-child(2) {
          height: 40px;
        }
        > div:nth-child(3) {
          height: 40px;
        }
        > div {
          h3 {
            padding-bottom: 5px;
          }
          p {
            padding-bottom: 5px;
            border-bottom: 1px solid var(--color-gray-400);
          }
        }
        > div:nth-child(4) {
          grid-column: span 2 / span 2;
          > .reason-box {
            min-height: 71px;
            border: 1px solid var(--color-gray-400);
            padding: 10px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }
        > div:nth-child(5) {
          grid-column: span 2 / span 2;

          .cert-box {
            border: 1px solid var(--color-gray-400);
            padding: 12px 20px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            .cert-list {
              margin-top: 11px;
              display: grid;
              grid-template-columns: repeat(4, minmax(0, 1fr));
              gap: 18px;
              > div:nth-child(2) {
                grid-column: span 3 / span 3;
              }
              > div:nth-child(4) {
                grid-column: span 2 / span 2;
              }
              > .button-td {
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
              svg:hover {
                border-radius: 1px;
                outline: 5px solid var(--color-persianblue);
                background: var(--color-persianblue);
                transition: all 0.2s linear;
                -webkit-transition: all 0.2s linear;
                -moz-transition: all 0.2s linear;
                -ms-transition: all 0.2s linear;
                -o-transition: all 0.2s linear;
              }
            }
          }
        }
      }
    }
  }
}
