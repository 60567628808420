.injection-view-page {
  margin-bottom: 50px;

  .cards-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h6 {
      color: var(--color-gray-100);
      padding-bottom: 8px;
      text-transform: capitalize;
    }

    .detail-card {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      height: min-content;
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;

      .row-data {
        display: flex;
        gap: 80px;
        width: 100%;

        div {
          flex: 1;
        }
      }
    }
  }
}
