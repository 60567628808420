.edit-item {
  .title,
  .body2 {
    margin-bottom: 1.2rem;
    color: var(--color-gray-100);
    font-size: 10px;
  }

  .edit-item-value {
    display: flex;
    justify-content: space-between;
    .body2 {
      text-decoration: underline;
    }
  }
}
