.header {
  .header-container {
    width: 100%;
    .top {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      .left {
        display: flex;
        // flex-direction: column;
        gap: 24px;

        span {
          cursor: pointer;
          height: fit-content;
          align-self: center;

          svg {
            height: 100%;
            width: 130%;
          }

          &.show {
            display: unset;
          }

          &.hide {
            display: none;
          }
        }
        div {
          line-height: 5.4rem;
        }
        .value {
          text-transform: uppercase;
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        position: relative;
        .icon {
          font-size: 2.3rem;
        }
        .profile-pic {
          margin-left: 12px;
          width: 50px;
          height: 50px;
          background-color: var(--gray-4);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .notification-popup {
          position: absolute;
          top: 40px;
          left: -16px;
        }
        .notification-icon {
          cursor: pointer;
          position: relative;
          .notification-dot {
            position: absolute;
            top: 0;
            right: 0;
            width: 8px;
            height: 8px;
            background-color: red;
            border-radius: 50%;
            .notification-count {
              position: absolute;
              top: -7px;
              right: -4px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: auto;
              padding: 0 2px;
              min-width: 16px;
              height: 16px;
              background-color: red;
              color: white;
              font-size: 9px;
              border-radius: 50%;
            }
          }
        }
        .search-container {
          margin: 0px 5px 0px 0px;
          .search-bar {
            width: 150px;
          }
        }
      }
    }

    .notification,
    .manual-entry-container {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .notification {
      align-self: center;
      padding-top: 5px;
      svg {
        width: 26px;
        height: 26px;
      }
    }

    .manual-entry-container {
      display: flex;
      justify-content: right;
      align-self: center;
      // padding-bottom: 5px;

      &:hover .entry-text {
        opacity: 1;
        transition: all 0.3s linear;
      }

      &:hover .entry-icon {
        opacity: 1;
        color: var(--color-persianblue);
        transition: all 0.3s linear;
      }

      .entry-text {
        opacity: 0;
        width: 50%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        color: var(--color-gray-400);
        transition: all 0.3s linear;
      }

      .entry-icon {
        transition: all 0.3s linear;

        &:hover {
          opacity: 1;
          color: var(--color-persianblue);
          transition: all 0.3s linear;
        }
      }
    }

    .notification-popup {
      position: fixed;
      left: 67.5%;
      right: 0;
      bottom: 0;
      top: 16%;
    }
    .info {
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .days {
        margin-right: 2.1rem;
        color: var(--color-persianblue);
        text-transform: uppercase;
      }
      .period {
        color: var(--color-gray-100);
      }
      .sort {
        display: flex;
        gap: 8px;
      }
      .icon {
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}

.site-links-container {
  .search-result-text {
    gap: 12px;

    h3 {
      font-weight: 700;
    }
  }
  .links-container {
    gap: 24px;

    .link-title {
      font-weight: 700;
    }
  }
}
