.dashboard-details {
  padding-bottom: 5rem;
  .featured-info {
    margin-top: 3.2rem;
    .card,
    .cardlg {
      border: 2px solid var(--gray-4);
    }

    .row1-infocards {
      .col5 {
        &.next {
          grid-column: 3/5;
          justify-content: center;
          &.has-error {
            height: 200px;
          }
        }
        grid-column: 1/3;
        // margin-top: 2.4rem;
        padding: 1.4rem;
        border: 2px solid var(--gray-4);
        border-radius: 5px;
        width: 100%;
        max-height: 247px;
        min-width: 375px;

        &.small {
          max-height: 212px;
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            .heading {
              text-transform: uppercase;
            }
            img {
              margin-left: 5px;
              width: 10px;
              height: 6px;
            }
          }
        }
        .member-info {
          display: flex;
          justify-content: center;
          align-items: center;
          &.has-error {
            height: 100%;
          }

          .total-member {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .total-club-visit {
              display: flex;
              align-items: center;
              flex-direction: column;
            }
            .value {
              margin-right: 20px;
            }
            .heading {
              bottom: 10px;
              // text-transform: uppercase;
              color: var(--color-gray-100);
            }
          }
        }
        .marketing-info {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          gap: 20px;
          width: 100%;
          height: 45%;
          .wrapper {
            width: 50%;
          }
          .heading {
            color: var(--color-gray-100);
            text-transform: uppercase;
          }
          .pie-chart-position {
            display: flex;
            width: 200px;
            height: 130px;
          }
        }
        .marketing-info:first-child {
          width: 100%;
          height: 120px;
        }
        .total {
          margin-top: -10px;
        }
        .gender-chart-container {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          height: 100%;
          &.has-error {
            justify-content: flex-start;

            div {
              height: fit-content;
              align-self: center;
            }
          }
        }
        .genderNumber {
          margin-top: 8px;
          display: flex;
          flex-direction: column;
          align-items: center;
          span:nth-child(1) {
            font-family: "Bebas Neue";
            font-size: 32px;
          }
          span:nth-child(2) {
            width: 47px;
            height: 7px;
          }
          span:nth-child(3) {
            font-family: "Gotham";
            font-size: 10px;
            line-height: 140%;
            text-transform: uppercase;
            color: #848484;
          }
        }
        .pie-chart-container {
          width: 200px;
          height: unset !important;
        }
        .male-bar {
          background-color: var(--color-persianblue);
        }
        .female-bar {
          background-color: #595fa5;
        }
        .other-bar {
          background-color: var(--color-gray-100);
        }
      }
      .col6 {
        grid-column: 1/5;
        // margin-top: 2.4rem;
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-width: 400px;
        .details {
          margin-bottom: 10px;
        }

        &.flex {
          flex-direction: row;
        }
      }
      .col8 {
        grid-column: 3/5;
        // margin-top: 2.4rem;
        padding: 1.4rem;
        border: 1px solid var(--color-gray-400);
        border-radius: 5px;
        max-height: 212px;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            .heading {
              text-transform: uppercase;
            }
            img {
              margin-left: 5px;
              width: 10px;
              height: 6px;
            }
          }
        }
        .marketing-info {
          display: grid;
          margin-top: 10px;
          grid-template-columns: auto auto auto;
          .heading {
            color: var(--color-gray-100);
            text-transform: uppercase;
          }
        }
      }
      @media screen and (max-width: 1125px) {
        grid-template-columns: 1fr;
        .col1,
        .col2,
        .col3,
        .col4 {
          grid-column: unset;
        }

        .col5,
        .col6,
        .col6.flex,
        .col5.next {
          grid-column: unset;
        }
      }
    }
  }
}
.dashboard-details-loading {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
