.voucher-grid-4 {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 49%);
    grid-template-rows: 1fr 1fr 1fr;
  }
  gap: 18px;
  grid-auto-flow: row;
}
.voucher-grid-3 {
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 49%);
    grid-template-rows: 1fr 1fr 1fr;
  }
  gap: 18px;
  grid-auto-flow: row;
}
.voucher-code-container {
  position: relative;
  .voucher-code {
  }
  img {
    position: absolute;
    right: 0;
    top: 16px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}
.notice-period {
  grid-area: notice-period;

  .form-group {
    position: relative;

    .error {
      position: absolute;
      margin-top: 0;
      top: 35px;
      left: 38px;
      width: 200px;
    }
  }
}
.input-select-title {
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-select {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
    align-items: flex-end;
    &:has(.error:not(:empty)) {
      // when both errors are triggered
      margin-bottom: 40px !important;
    }
    &:has(.flex-col .error:empty) {
      //targets margins once one of the errors is dealt with
      .box-input-custom-error {
        .error {
          top: 30px;
        }
      }
    }
    @media screen and (max-width: 1550px) {
      &:has(.flex-col .error:empty) {
        margin: 0 !important;
      }
    }
    // &:has(.form-group .error:not(:empty)) {
    //specific to the notice period error
    //   margin-bottom: 35px;
    // }

    .box-input-custom-error {
      position: relative;

      .error {
        position: absolute;
        top: 48px;
        left: 38px;
        // white-space: nowrap;
        width: 220px;
      }
    }
    .flex-col {
      position: relative;

      .error {
        position: absolute;
        margin-top: 0;
        top: 20px;
      }
    }

    .select-element {
      min-width: 200px;
      width: 100%;
      label {
        margin-bottom: 2px;
      }
    }

    input {
      font-size: 12px;
      display: flex;
      justify-content: center;
      text-align: center;
      border: 1px solid var(--color-gray-400);
      border-radius: 2px;
      min-width: 18px;
      min-height: 18px;
      width: min-content;
      max-width: 20px;
      max-height: 20px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}
.third-row {
  margin-top: 20px;
}

.fourth-row {
  margin: 18px 0px 10px 0px;
}
.add-info-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 14px;
  margin-top: 30px;
  .voucher-details-container {
    display: flex;
    flex-direction: row;
    gap: 0px 18px;
    flex-direction: column;
    width: 100%;
    h3 {
      text-transform: uppercase;
      padding-bottom: 5px;
    }

    .address-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, 49%);
        grid-template-rows: 1fr 1fr 1fr;
      }
      grid-template-rows: 1fr 1fr;
      gap: 18px;
      grid-auto-flow: row;
    }

    &.loc-card {
      width: 100%;
      height: max-content;
      border: 1px solid var(--color-gray-400);
      border-radius: 5px;
      padding: 23px 22px 20px 16px;

      h3 {
        margin-bottom: 10px;
      }

      .toggle {
        margin-top: 10px;
      }

      textarea {
        margin-bottom: 10px;
      }
      .swipe-title,
      .error-title {
        color: var(--color-gray-100);
      }
    }

    .detail {
      display: flex;
      // flex: 1;
      flex-direction: column;

      &.loc {
        flex-direction: row;
        justify-content: flex-start;
        gap: 20px;
        flex: 1;

        div {
          flex: 1;
          p {
            padding-bottom: 2px;
            border-bottom: 1px solid var(--color-gray-100);
          }
          @media screen and (max-width: 1100px) {
            flex: 48%;
          }
        }
        // .form-group {
        //   flex: 2;
        // }
      }
      h5 {
        color: var(--color-gray-100);
        margin-bottom: 5px;
      }
      p {
        text-transform: capitalize;
        // padding-bottom: 5px;
      }
    }
    .detail {
      gap: 6px;
      display: flex;
      flex: 25%;
      flex-direction: column;
      height: fit-content;

      &.full-width {
        width: 100%;
        flex: 100%;
        margin-right: calc(50% + 10px);
      }

      span {
        margin-right: 5px;
      }

      p {
        text-transform: uppercase;
      }
    }
  }
}

.text-white {
  color: var(--color-white);
}
.heading-and-icon {
  display: flex;
}

.membership-select {
  label {
    margin-bottom: 5px;
    color: var(--color-gray-100);

    svg {
      margin-left: 5px;
      color: var(--color-white);
    }
  }
  .location-value {
    text-transform: uppercase;
    font-size: 1.8rem;

    &.loc-select {
      display: flex;

      .select-element {
        margin-top: 0;
        align-self: center;
        background: transparent;
        outline: none;
        border: none;
        border-bottom: 1px solid var(--color-gray-100);
        text-transform: uppercase;
        width: 100%;

        &::placeholder {
          color: var(--color-white);
        }
      }
    }
  }
}
