.member-container.reports {
  .loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    margin: 10px 0px;
  }
}
.specificReport {
  .loading {
    display: flex !important;
    justify-content: center;
    align-items: center;
    min-height: 550px;
    margin: 10px 0px;
  }
}
